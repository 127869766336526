import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "./shared/navigation/navigation.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent {

    constructor(
        private router: Router,
        private navigationService: NavigationService
    ) {

    }

    public showNavBar() {        
        if (this.router.url.includes("login") || !this.navigationService.showNavBar) {
            return false;
        }
        return true;
    }

}

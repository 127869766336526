export class Constants {
	public static readonly ITEMS_PER_REQUEST = 1000;	
	public static readonly ADDRESS_SEARCH_FIELDS = [
		"address1",
		"address2",
	];
	public static readonly NAME_SEARCH_FIELDS = [
		"owner.firstName",
		"owner.lasttName",
		"visitors.firstName",
		"visitors.lastName",
		"visitors.companyName",
		"temporaryVisitors.firstName",
		"temporaryVisitors.lastName",
		"temporaryVisitors.companyName",
		"residents.firstName",
		"residents.lastName",
	];
	public static readonly NUMBER_SEARCH_FIELDS = [		
		"address1",
		"address2",
		"contact.phone1",
		"contact.phone2",
		"visitors.phone1",
		"visitors.phone2",
		"residents.phone1",
		"residents.phone2",
		"contacts",
	];
	public static readonly SIMPLE_SEARCH_FIELDS = [
		"address1",
		"address2",
		"contact.phone1",
		"contact.phone2",
		"owner.firstName",
		"owner.lasttName",
		"visitors.firstName",
		"visitors.lastName",
		"visitors.companyName",
		"visitors.phone1",
		"visitors.phone2",
		"temporaryVisitors.firstName",
		"temporaryVisitors.lastName",
		"temporaryVisitors.companyName",
		"temporaryVisitors.phone1",
		"temporaryVisitors.phone1",
		"residents.firstName",
		"residents.lastName",
		"residents.phone1",
		"residents.phone2",
		"contacts",
	];
	public static readonly AAMVA_DATA = [
		{ key: "documentId", start: "DAQ", end: null, count: 13 },
		{ key: "dob", start: "DBB", end: null, count: 8 },
		{ key: "firstName", start: "DAC", end: "DDF", count: null },
		{ key: "middleName", start: "DAD", end: "DDG", count: null },
		{ key: "lastName", start: "DCS", end: "DDE", count: null },
		{ key: "issueDate", start: "DBD", end: null, count: 8 },
		{ key: "expirationDate", start: "DBA", end: null, count: 8 },
		{ key: "address1", start: "DAG", end: null, count: 35 },
		{ key: "city", start: "DAI", end: null, count: 20 },
		{ key: "state", start: "DAJ", end: null, count: 2 },
		{ key: "zipCode", start: "DAK", end: null, count: 11 },
	];
	public static readonly DEFAULT_BAUD_RATE = 9600;
}

import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { UnitService } from "../unit.service";
import { Unit } from "../../models/Unit";

@Injectable()
export class ResidentGuard implements CanActivate {

    private approvedTypes: string[] = ["RESIDENT", "ADMIN", "SYSTEM_ADMIN"];

    constructor(
        private as: AuthService,
        private us: UnitService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let self = this;

        let unitId = route.paramMap.get("unitId");

        let promise = new Promise<any>((resolve, reject) => {
            self.as.checkLoginStatus().then(res => {
                if (res == false) {
                    self.router.navigate(["/login"]);
                } else {
                    if (self.approvedTypes.indexOf(self.as.loggedInUser.type) < 0) {
                        self.router.navigate(["./noaccess"]);
                        res = false;
                        resolve(res);
                    } else {
                        self.us.getUnitById(unitId).then((unit: Unit) => {
                            if (unit == null) {
                                res = false;
                            } else {
                                res = true;
                            }
                            resolve(res);
                        });
                    }
                }
            });
        });
        return promise;
    }
}
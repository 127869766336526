import { Injectable } from "@angular/core";
import { Notification } from "../models/Notification";
import { Subject } from "rxjs";

@Injectable()
export class NotificationService {
    notification: Notification;
    notificationChange: Subject<Notification> = new Subject<Notification>();

    constructor() {
        this.notification = { message: "", show: -1, color: "primary" };

        var self = this;
        this.notificationChange.subscribe((value: Notification) => {
            self.notification = value;
        });
    }

    showNotification(notification: Notification) {
        if (!notification.hasOwnProperty("color")) {
            notification.color = "primary";
        }

        notification.show = 1;
        this.notificationChange.next(notification);

        setTimeout(() => {
            notification.show = -1;
            this.notificationChange.next(notification);
        }, 3000);
    }

    showSimpleNotification(message:string, color?:string){
        if(!color){
            color="primary";
        }
        let not = new Notification();
        not.message = message;
        not.color = color;
        return this.showNotification(not);
    }

    hideNotification(){
        this.notification.show = -1;
        this.notificationChange.next(this.notification);
    }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { DataService } from "./data.service";
import { CommunityRequest } from "../models/request/CommunityRequest";
import { Community } from "../models/Community";
import { NotificationService } from "./notifications.service";
import { BackupService } from "./backup.service";
import { environment } from "src/environments/environment";
import { LicenseRequest } from "../models/request/LicenseRequest";
import { License } from "../models/License";

@Injectable()
export class CommunityService {

    public communities: Community[];
    public communitiesChanged: Subject<Community[]> = new Subject<Community[]>();

    public selectedCommunity: Community;
    public selectedCommunityChanged: Subject<Community> = new Subject<Community>();

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
        private backupService: BackupService
    ) {
        this.selectedCommunity = null;
        this.selectedCommunityChanged.subscribe((community: Community) => {
            this.selectedCommunity = community;
        });

        this.communities = [];
        this.communitiesChanged.subscribe((communities: Community[]) => {
            this.communities = communities;
        });
    }

    public modifyCommunityLicense(license: License) {
        return new Promise<License>((resolve, reject) => {
            this._modifyLicense(license).then(res => {
                let license: License = null;
                if (res.status == 0 && res.data !== false) {
                    license = res.data
                } else {
                    let mes = "Error modifying community license.";
                    if (res.data) {
                        mes = res.data;
                    }
                    this.notificationService.showSimpleNotification(mes, "danger");
                }
                resolve(license);
            });
        });
    }

    public getCommunityLicense(req: LicenseRequest) {
        return new Promise<License>((resolve, reject) => {
            let license = new License();
            this._getLicense(req).then(res => {
                if (res.status == 0 && res.data) {
                    license = res.data;
                }
                resolve(license);
            });
        });
    }

    public compareLastLogin(communities: Community[], sortDirection) {
        return communities.sort((a: Community, b: Community) => {

            let firstDate = null;
            if (a.lastLogin) {
                firstDate = new Date(a.lastLogin);
            }

            let secondDate = null;
            if (b.lastLogin) {
                secondDate = new Date(b.lastLogin);
            }

            if (firstDate == null && secondDate == null) {
                return 0;
            }

            if (firstDate == null && secondDate !== null) {
                return 1;
            }

            if (firstDate !== null && secondDate == null) {
                return -1;
            }

            if (firstDate !== null && secondDate !== null) {
                if (firstDate < secondDate) {
                    if (sortDirection == -1) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                if (firstDate > secondDate) {
                    if (sortDirection == -1) {
                        return 1
                    } else {
                        return -1;
                    }
                }
                return 0;
            }
        });

    }

    public getCommunityById(id: string) {
        let community = null;
        for (let c of this.communities) {
            if (c.id == id) {
                community = c;
                break;
            }
        }
        return community;
    }

    setCommunity(community: Community) {
        this.dataService.setCommunity(community);
        this.selectedCommunityChanged.next(community);
    }

    public getCommunityFromUrl(url: string) {

        let self = this;

        let req = new CommunityRequest();
        req.community = new Community();
        req.community.url = url;

        let promise = new Promise<any>((resolve, reject) => {
            if (navigator.onLine) {
                self.getCommunties(req).then((communities: Community[]) => {
                    let community: Community = null;
                    if (communities.length > 0) {
                        community = communities[0];
                    }
                    resolve(community);
                });
            } else {
                if (environment.isNative) {
                    this.getLocalCommunity().then((community: Community) => {
                        resolve(community);
                    })
                } else {
                    resolve(null);
                }
            }
        });
        return promise;

    }

    getCommunties(req: CommunityRequest) {

        let self = this;

        if (req == null) {
            req = new CommunityRequest();
        }

        let promise = new Promise<any>((resolve, reject) => {
            self.dataService.getCommunities(req).then(res => {
                let communities: Community[] = [];
                if (res.status == 0) {
                    communities = res.data.results;
                }
                self.communitiesChanged.next(communities);
                resolve(communities);
            });
        });
        return promise;
    }

    public removeCommunity(community: Community) {

    }

    public modifyCommunity(community: Community) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self._modifyCommunity(community).then(res => {
                let com: Community = null;
                if (res.status == 0 && res.data !== false) {
                    com = res.data;
                } else {
                    self.notificationService.showSimpleNotification(res.data, "danger");
                }
                resolve(com);
            });
        });
        return promise;
    }

    public uploadLogo(file: File, name: string) {

        let self = this;

        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", name);

        let promise = new Promise<any>((resolve, reject) => {
            self._uploadLogoRequest(formData).then(res => {
                let response: Community = null;
                if (res.status == 0 && res.data !== false) {
                    response = res.data;
                } else {
                    let mes = "Error uploading logo. " + res.data;
                    self.notificationService.showSimpleNotification(mes, "danger");
                }
                resolve(response);
            });
        });
        return promise;

    }

    public getLocalCommunity() {
        return new Promise<any>((resolve, reject) => {
            this.backupService.getFile("community.json").then(community => {
                resolve(community);
            });
        });
    }

    private _getLicense(req: LicenseRequest) {
        let params = { licenseRequest: JSON.stringify(req) };
        return this.dataService.issueCommand("GET", "community/license", params);
    }

    private _modifyLicense(license: License) {
        let params = { license: JSON.stringify(license) };
        return this.dataService.issueCommand("POST", "community/license", params);
    }

    private _modifyCommunity(community: Community) {
        let params = { community: JSON.stringify(community) };
        return this.dataService.issueCommand("POST", "community", params);
    }

    private _uploadLogoRequest(formData: FormData) {
        return this.dataService.issueCommandForFiles("community-upload-logo", formData);
    }

}

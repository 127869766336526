import { Injectable } from "@angular/core";
import { SpinnerComponent } from './spinner.component';

@Injectable()
export class SpinnerService {

    public spinnerMap: any = {};

    constructor() { }

    public showSpinner(key: string) {
        this.spinnerMap[key].show();
    }

    public hideSpinner(key: string) {
        this.spinnerMap[key].hide();
    }

    public setSpinner(key: string, spinner: SpinnerComponent) {
        this.spinnerMap[key] = spinner;
    }

    public getSpinner(key: string) {
        return this.spinnerMap[key];
    }
}
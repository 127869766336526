import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Memo } from '../models/Memo';
import { Unit } from '../models/Unit';
import { User } from '../models/User';
import { VisitationLog } from '../models/VisitationLog';
import { LogsService } from './logs.service';
import { MemoService } from './memo.service';
import { UnitService } from './unit.service';

@Injectable({
	providedIn: 'root'
})
export class CommunityImportService {

	private unitsQueue: Unit[];
	private errorQueue: Unit[];
	private onImportFinished: Subject<any>;

	constructor(
		private unitService: UnitService,
		private memoService: MemoService,
		private logsService: LogsService
	) {
		this.unitsQueue = [];
		this.errorQueue = [];
		this.onImportFinished = new Subject<any>();
	}

	public importUnits(units: Unit[]) {
		return new Promise<any>((resolve, reject) => {

			this.errorQueue = [];
			this.unitsQueue = [];

			for (let unit of units) {
				this.unitsQueue.push(unit);
			}

			this.importNextUnit();

			this.onImportFinished.subscribe(() => {
				resolve(this.errorQueue);
			});

		});
	}

	private importNextUnit() {

		if (this.unitsQueue.length == 0) {
			this.onImportFinished.next(true);
			return;
		}

		for (let unit of this.unitsQueue) {
			console.log(`Importing unit ${unit.address1}`);
			this.importUnit(unit);
			break;
		}
	}

	private importUnit(unit: Unit) {

		let memos = null;
		if (unit.memos) {
			memos = unit.memos;
			delete unit["memos"];
		}

		console.log("Memos", memos);

		let logs = null;
		if (unit["logs"]) {
			logs = unit["logs"];
			delete unit["logs"];
		}

		console.log("Logs", logs);

		this.unitService.modifyUnit(unit, true).then((resUnit: Unit) => {
			if (resUnit == null) {
				this.errorQueue.push(unit);
				this.finishedImportingUnit(unit);
			} else {
				this.finishedImportingUnit(unit);
				// this.addMemosForUnit(resUnit, memos).then(() => {
				// 	console.log(`Finished adding memos for unit ${unit.address1}`);
				// 	this.addLogsForUnit(resUnit, logs).then(() => {
				// 		console.log(`Finished adding logs for unit ${unit.address1}`);
				// 		this.finishedImportingUnit(unit);
				// 	});
				// });
			}
		});
	}

	private addLogsForUnit(unit: Unit, logs: VisitationLog[]) {
		return new Promise<any>((resolve, reject) => {
			for (let log of logs) {

				log.unit = new Unit();
				log.unit.id = unit.id;

				log.user = new User();
				log.user.firstName = "System";
				log.user.lastName = "Generated";
			}
			console.log(`Adding logs for unit ${unit.address1}`);
			this.logsService.addLogs(logs).then((resLogs: VisitationLog[]) => {
				resolve(true);
			});
		});
	}

	// private addLogsForUnit(unit: Unit, logs: VisitationLog[]) {
	// 	return new Promise<any>((resolve, reject) => {

	// 		let count = 0;

	// 		for (let log of logs) {

	// 			log.unit = new Unit();
	// 			log.unit.id = unit.id;

	// 			log.user = new User();
	// 			log.user.firstName = "System";
	// 			log.user.lastName = "Generated";

	// 			this.logsService.modifyVisitLog(log).then((res: VisitationLog) => {
	// 				count++;
	// 				if (res == null) {
	// 					console.log(`Error creating adding log to ${unit.id}`);
	// 				}
	// 			}).then(() => {
	// 				if (count >= logs.length) {
	// 					resolve(true);
	// 				}
	// 			});
	// 		}
	// 	});
	// }

	private addMemosForUnit(unit: Unit, memos: string[]) {
		return new Promise<any>((resolve, reject) => {

			if (memos == null || memos.length == 0) {
				resolve(true);
				return;
			}

			let memo = new Memo();
			memo.message = memos[0];

			memo.unit = new Unit();
			memo.unit.id = unit.id;

			memo.user = new User();
			memo.user.firstName = "System";
			memo.user.lastName = "Generated";

			this.memoService.modifyMemo(memo).then((res: Memo) => {
				if (res == null) {
					console.log(`Error creating memo for unit ${unit.id}.`);
				}
				resolve(true);
			});
		});
	}

	private finishedImportingUnit(unit: Unit) {
		console.log(`Finished importing unit ${unit.address1}.`);
		this.unitsQueue.splice(this.unitsQueue.indexOf(unit), 1);
		setTimeout(() => {
			this.importNextUnit();
		}, 500);
	}

	public setUnitsQueue(units: Unit[]) {
		let queue = [];
		for (let unit of units) {
			queue.push(unit);
		};
		this.unitsQueue = queue;
	}

}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailTemplateRequest } from '../../../models/request/EmailTemplateRequest';
import { User } from '../../../models/User';
import { EmailTemplatesService } from '../../../services/email-templates.service';
import { NotificationService } from '../../../services/notifications.service';
import { UnitService } from '../../../services/unit.service';
import { UserService } from '../../../services/user.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { BaseModal } from '../base/base-modal.component';

@Component({
	selector: 'app-user-credentials-modal',
	templateUrl: './user-credentials-modal.component.html'
})
export class UserCredentialsModal extends BaseModal implements OnInit {

	@ViewChild('template') modal: TemplateRef<any>;

	public newEmail: string;
	public sendEmail: boolean;
	public selectedTemplateId: string;
	private user: User;

	constructor(
		private bsModalService: BsModalService,
		private notificationService: NotificationService,
		private userService: UserService,
		private unitService: UnitService,
		private emailTemplateService: EmailTemplatesService,
		private spinnerService: SpinnerService
	) {
		super(bsModalService);
		this.newEmail = "";
		this.sendEmail = false;
		this.user = null;
		this.selectedTemplateId = null;
	}

	ngOnInit(): void {
		this.emailTemplateService.getEmailTemplates(new EmailTemplateRequest());
	}

	public show(user: User) {

		this.sendEmail = false;
		this.newEmail = "";
		this.user = user;
		if (user && user.email) {
			this.newEmail = user.email;
		}

		return new Promise<any>((resolve, reject) => {
			this.modalRef = this.bsModalService.show(this.modal, this.config);
			this.bsModalService.onHide.subscribe(() => {
				resolve(this.response);
			});
		});
	}

	public saveCredentials() {

		this.spinnerService.showSpinner("mainSpinner");

		let user = new User();

		if (this.user == null) {
			if (this.selectedUnit.contact) {
				user.firstName = this.selectedUnit.contact.firstName;
				user.lastName = this.selectedUnit.contact.lastName;
			}
			user.unitId = this.selectedUnit.id;
			user.type = "RESIDENT";

		} else {
			user.id = this.user.id
			user.firstName = this.user.firstName;
			user.lastName = this.user.lastName;
		}

		user.email = this.newEmail;

		this.userService.modifyUser(user, this.sendEmail, this.selectedTemplateId).then((user: User) => {

			this.spinnerService.hideSpinner("mainSpinner");

			if (user !== null) {
				if (this.user == null && this.selectedUnit.contact == null) {
					this.selectedUnit.contact = user;
				} else {
					this.selectedUnit.contact.email = user.email;
				}

				let mes = "User account has been created.";
				if (this.sendEmail) {
					mes += ` An activation email will be sent to ${this.newEmail}.`;
				}

				this.notificationService.showSimpleNotification(mes);
				this.closeDialog(true);

			}
		});

	}

	public get templates() {
		return this.emailTemplateService.templates;
	}

	public get selectedUnit() {
		return this.unitService.selectedUnit;
	}

}

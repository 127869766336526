import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AdminRouting } from "./admin-routing.module"
import { AdminComponent } from "./admin.component";
import { SharedModule } from "../../shared/shared.module";
import { AdminUnitsComponent } from "./units/units.component";
import { GuardsAdminComponent } from "./guards/guards.component";
import { PrintUnitsComponent } from './print-units/print-units.component';
import { PrintMissingContactsComponent } from './print-missing-contacts/print-missing-contacts.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdminRouting,
        SharedModule,
        DragDropModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    declarations: [
        AdminComponent,
        AdminUnitsComponent,
        GuardsAdminComponent,
        PrintUnitsComponent,
        PrintMissingContactsComponent        
    ],
    providers: [],
    exports: []
})
export class AdminModule { }

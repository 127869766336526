import { Component } from "@angular/core";

@Component({
    selector: "va-register",
    templateUrl: "./register.component.html"
})
export class UserRegistrationComponent {

    constructor() { }

}
import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { MemoRequest } from "../models/request/MemoRequest";
import { Memo } from "../models/Memo";
import { Unit } from "../models/Unit";
import { Constants } from "../utils/Constants";
import { BackupService } from "./backup.service";

@Injectable()
export class MemoService {

    constructor(
        private dataService: DataService,
        private backupService: BackupService
    ) {

    }

    public checkMemoQueue() {
        if (this.backupService.modifyMemoQueue.length == 0) {
            return;
        }

        for (let memo of this.backupService.modifyMemoQueue) {
            this.modifyMemo(memo);
        }

        this.backupService.modifyMemoQueue = [];
    }

    public getAllMemos() {
        let self = this;
        return new Promise<Memo[]>((resolve, reject) => {
            let req = new MemoRequest();
            req.fetchAll = true;
            self.searchMemos(req).then((memos: Memo[]) => {
                resolve(memos);
            });
        });
    }

    public getMemosForUnit(unit: Unit) {

        let self = this;

        let promise = new Promise<any>((resolve, reject) => {

            let req = new MemoRequest();
            req.start = 0;
            req.count = Constants.ITEMS_PER_REQUEST;
            req.andFields = { "unit.id": unit.id };
            req.andClauses = true;

            self.searchMemos(req).then((memos: Memo[]) => {
                resolve(memos);
            });
        });

        return promise;

    }

    public removeMemo(memo: Memo) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self._removeMemoRequest(memo).then(res => {
                if (res.status == 0 && res.data !== false) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
        return promise;
    }

    public searchMemos(req: MemoRequest) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            // self.dataService.getMemos(req)
            this._getMemos(req).then(res => {
                let memos: Memo[] = [];
                if (res.status == 0 && res.data !== false) {
                    memos = res.data;
                }
                resolve(memos);
            });
        });
        return promise;
    }

    public modifyMemo(memo: Memo) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            // self.dataService.modifyMemo(memo)
            this._modifyMemo(memo).then(res => {
                let tempMemo: Memo = null;
                if (res.status == 0 && res.data !== false) {
                    tempMemo = res.data;
                }
                resolve(tempMemo);
            });
        });
        return promise;
    }

    private _modifyMemo(memo: Memo) {
        if (navigator.onLine) {
            let params = { memo: JSON.stringify(memo) };
            return this.dataService.issueCommand("POST", "unit-memo", params);
        } else {
            return new Promise<any>((resolve, reject) => {

                this.backupService.modifyMemoQueue.push(memo);
                let memos = [...this.backupService.memos];
                memos.push(memo);
                this.backupService.setMemos(memos);

                resolve({
                    status: 0,
                    data: memo
                })
            });
        }
    }

    private _getMemos(req: MemoRequest) {
        if (navigator.onLine) {
            let params = { memoRequest: JSON.stringify(req) };
            return this.dataService.issueCommand("GET", "unit-memo", params);
        } else {
            return new Promise<any>((resolve, reject) => {
                this.backupService.getMemos().then((memos: Memo[]) => {
                    let unitId = req.andFields["unit.id"];
                    let results = memos.filter((memo: Memo) => {
                        return memo.unit.id == unitId;
                    });
                    resolve({
                        status: 0,
                        data: results
                    });
                });
            });
        }
    }

    private _removeMemoRequest(memo: Memo) {
        let params = { memo: JSON.stringify(memo) };
        return this.dataService.issueCommand("DELETE", "unit-memo", params);
    }

}

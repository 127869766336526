import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";

import { AppRoutingModule } from "./app-routing.module";
import { SystemAdminModule } from "./pages/system-admin/system-admin.module";
import { SharedModule } from "./shared/shared.module";

import { DataService } from "./services/data.service";
import { CommunityService } from "./services/community.service";
import { LoginComponent } from "./pages/login/login.component";
import { ResidentsModule } from "./pages/residents/residents.module";
import { UserService } from "./services/user.service";
import { UserRegistrationComponent } from "./pages/register/register.component";
import { NotificationService } from "./services/notifications.service";
import { UnitService } from "./services/unit.service";
import { AdminModule } from "./pages/admin/admin.module";
import { LogsService } from "./services/logs.service";
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./services/guards/auth-guard.service";
import { MemoService } from "./services/memo.service";
import { NoAccessComponent } from "./pages/noaccess/noaccess.component";
import { GuradsModule } from "./pages/guards/guards.module";
import { DialingService } from "./services/dialing.service";
import { BackupService } from "./services/backup.service";
import { UploadService } from "./services/upload.service";

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        UserRegistrationComponent,
        NoAccessComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SystemAdminModule,
        ResidentsModule,
        AdminModule,
        GuradsModule,
        SharedModule
    ],
    providers: [
        AuthGuard,
        DataService,
        CommunityService,
        UserService,
        NotificationService,
        UnitService,
        LogsService,
        AuthService,
        MemoService,
        DialingService,
        BackupService,
        UploadService        
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

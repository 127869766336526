import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth.service";

@Injectable()
export class GuardsGuard implements CanActivate {

    private approvedTypes: string[];

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
        this.approvedTypes = ["SYSTEM_ADMIN", "GUARD"];
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let self = this;

        let communityName = route.paramMap.get("communityName");

        let promise = new Promise<any>((resolve, reject) => {
            if(navigator.onLine){
                self.authService.checkLoginStatus().then(res => {
                    if (res == false) {
                        let url = "/" + communityName + "/login";
                        self.router.navigate([url]);
                    } else {
                        if (self.approvedTypes.indexOf(self.authService.loggedInUser.type) < 0) {
                            self.router.navigate(["./noaccess"]);
                            res = false;
                        }
                    }
                    resolve(res);
                });
            }else{
                let response = false;
                if(environment.isNative){
                    response = true;
                }
                resolve(response);
            }            
        });
        return promise;
    }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../../../../services/notifications.service';
import { CommunityImportModal } from '../../../../shared/modals/community-import-modal/community-import-modal.component';

@Component({
	selector: 'app-community-import',
	templateUrl: './community-import.component.html'
})
export class CommunityImportComponent implements OnInit {

	@ViewChild('importModal') importModal: CommunityImportModal;

	constructor(private notificationService: NotificationService) {

	}

	ngOnInit() {
	}

	public onFileSelected(event: any) {
		if (event.target.files.length == 0) {
			return;
		}

		let file = event.target.files.item(0);

		if (file.type !== "application/json") {
			this.notificationService.showSimpleNotification("Invalid file type! Must be in JSON format.");
			return;
		}

		let reader = new FileReader();
		reader.onload = (event: any) => {
			let units = null;
			try {
				units = JSON.parse(event.target.result);
			} catch (e) {
				console.log(`Error reading json ${e.message}`);
				this.notificationService.showSimpleNotification("Unable to read import file.");
				return;
			}

			this.importModal.show(units).then(res => {
				console.log(res);
			});
		};
		reader.readAsText(file);
	}

}

import { Component, ViewChild, OnInit } from "@angular/core";
import { CommunityService } from "../../../services/community.service";
import { User } from "../../../models/User";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UserService } from "../../../services/user.service";
import { Community } from "../../../models/Community";
import { UserRequest } from "../../../models/request/UserRequest";
import { UserModal } from "../../../shared/modals/user-modal/user-modal.component";
import { Utils } from "../../../utils/Utils";
import { ConfirmationModal } from "../../../shared/modals/confirmation-modal/confirmation-modal.component";
import { NotificationService } from "../../../services/notifications.service";

@Component({
    selector: "va-admin-users",
    templateUrl: "./admin-users.component.html"
})
export class AdminUsersAdminComponent implements OnInit {

    @ViewChild('userModal') userModal: UserModal;
    public users: User[] = [];
    public searchParam: string = "";
    public newGuard = null;
    public modalRef: BsModalRef;
    public communities: Community[] = [];
    public selectedCommunity: Community = null;
    public selectedCommunityId: string = "";

    constructor(
        private cs: CommunityService,
        private us: UserService,
        private ns: NotificationService
    ) {
        this.subscribeToCommunity();
    }

    ngOnInit() {
        this.getCommunities();
        if (this.cs.selectedCommunity != null) {
            this.loadCommunity(this.cs.selectedCommunity);
        }
    }

    private getCommunities() {
        let self = this;
        this.cs.getCommunties(null).then((communities: Community[]) => {
            self.communities = communities;
        });
    }

    private subscribeToCommunity() {
        let self = this;
        this.cs.selectedCommunityChanged.subscribe((community: Community) => {
            if (community !== null) {
                self.loadCommunity(community);
            }
        });
    }

    private loadCommunity(community: Community) {
        this.selectedCommunityId = community.id;
        this.searchUsers();
    }

    public communityChanged() {
        let community = this.cs.getCommunityById(this.selectedCommunityId);
        if (community !== null) {
            this.cs.setCommunity(community);
        }
    }

    public createAdminUser() {
        let self = this;
        this.newGuard.type = "ADMIN";
        this.us.modifyUser(this.newGuard).then((user: User) => {
            if (user !== null) {
                self.modalRef.hide();
                self.users.push(user);
            }
        });
    }

    public autoSearch(event: KeyboardEvent) {
        if (this.searchParam.length > 2) {
            this.searchUsers();
        } else if (this.searchParam.length == 0) {
            this.searchUsers();
        }
    }

    public searchUsers() {

        let self = this;

        if (!this.selectedCommunityId) {
            this.ns.showSimpleNotification("Please select a Community");
        }

        let req = new UserRequest();

        req.orFields = {
            firstName: [this.searchParam],
            lastName: [this.searchParam],
            email: [this.searchParam]
        }
        req.andFields = {
            type: ["ADMIN"]
        }
        req.ors = ["firstName", "lastName", "email"];
        req.ands = ["type"];
        req.andClauses = true;
        req.showLastLogin = true;

        this.us.searchUsers(req).then((users: User[]) => {
            self.users = users;
        })
    }

    public formatDate(date: string) {
        return Utils.formatDate(new Date(date), true);
    }

    public hideCreateDialog() {
        this.newGuard = new User();
        this.modalRef.hide();
    }

    public showCreateDialog(user?: User) {

        if (!this.selectedCommunityId) {
            this.ns.showSimpleNotification("Please select a Community");
            return;
        }

        this.userModal.show(user);
    }

    get community() {
        return this.cs.selectedCommunity;
    }

}
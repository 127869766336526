import { Component, ViewChild, OnInit } from "@angular/core";
import { User } from "../../models/User";
import { ValidationEntry } from "../../models/ValidationEntry";
import { AuthService } from "../../services/auth.service";
import { NotificationService } from "../../services/notifications.service";
import { SpinnerComponent } from "../../shared/spinner/spinner.component";
import { Router, ActivatedRoute } from "@angular/router";
import { Utils } from "../../utils/Utils";
import { DataService } from "../../services/data.service";
import { environment } from "src/environments/environment";
import { BackupService } from "src/app/services/backup.service";
import { CommunityService } from "src/app/services/community.service";
import { Community } from "src/app/models/Community";
import { ScannerService } from "src/app/services/scanner.service";

@Component({
    selector: "va-login",
    templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
    @ViewChild("spinner") spinner: SpinnerComponent;

    public user: User;
    public validationEntry: ValidationEntry;
    public confirmPassword: string;
    public isActivation: boolean;
    public checkedForActive: boolean;
    public rememberMe: boolean;
    public showPassword: boolean;

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService,
        private dataService: DataService,
        private backupService: BackupService,
        private communityService: CommunityService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.user = new User();
        this.validationEntry = null;
        this.confirmPassword = null;
        this.isActivation = false;
        this.checkedForActive = false;
        this.rememberMe = false;
        this.showPassword = false;
    }

    public ngOnInit() {
        let self = this;
        let email = Utils.getCookie("visitoraxis-email");
        if (email !== null) {
            this.user.email = atob(email);
            this.rememberMe = true;
        }

        let communityId = this.route.snapshot.paramMap.get("communityId");
        let validationCode = this.route.snapshot.paramMap.get("validationCode");
        let userId = this.route.snapshot.paramMap.get("userId");

        if (
            communityId !== null &&
            validationCode !== null &&
            userId !== null
        ) {
            this.validationEntry = new ValidationEntry();
            this.validationEntry.validationCode = validationCode;
            this.validationEntry.userId = userId;
            this.isActivation = true;
        }

        this.route.data.subscribe((data: any) => {
            if (data.hasOwnProperty("community") && data.community !== null) {
                self.dataService.setCommunity(data.community);
            }
        });

        if (environment.isNative) {
            this.backupService.checkWorkingDirectory().then((res) => {
                this.communityService
                    .getLocalCommunity()
                    .then((community: Community) => {
                        if (community !== null) {
                            this.dataService.setCommunity(community);
                        }
                    });
            });
        }
    }

    public getClientLogo() {
        let id = this.dataService.getCommunityId();
        if (id !== null) {
            return "./assets/client-logos/" + id + ".png";
        }
        return "";
    }

    public forgotPassword() {
        let self = this;

        if (!this.user.email) {
            this.notificationService.showSimpleNotification(
                "Email is required to reset password"
            );
            return;
        }

        let user = new User();
        user.email = this.user.email;

        this.authService.sendResetPasswordEmail(user).then((res) => {
            if (res == true) {
                self.notificationService.showSimpleNotification(
                    "Reset email has been sent"
                );
            }
        });
    }

    private checkIfUserIsActivated() {
        let self = this;

        this.spinner.show();

        let user = new User();
        user.email = this.user.email;

        this.authService.checkActiveUser(user).then((res) => {
            if (res !== null) {
                let active = null;
                if (res.hasOwnProperty("active")) {
                    active = res.active;
                }

                if (active == true) {
                    self.checkedForActive = true;
                } else if (active == false) {
                    if (res.hasOwnProperty("code")) {
                        self.validationEntry = new ValidationEntry();
                        self.validationEntry.validationCode = res.code;
                        self.validationEntry.userId = res.userId;
                        self.isActivation = true;
                    }
                }
            }
            self.spinner.hide();
        });
    }

    public login() {
        if (!this.user.email) {
            this.notificationService.showNotification({
                message: "Email is required",
            });
            return;
        }

        if (this.checkedForActive == false && this.user.password == null) {
            this.checkIfUserIsActivated();
            return;
        }

        this.spinner.show();

        let macAddress = null;
        if (environment.isNative) {
            let os = window["require"]("os");
            let networkInterfaces: any = os.networkInterfaces();

            for (let i in networkInterfaces) {
                let entry = networkInterfaces[i];
                let macAddresses = entry.filter((i: any) => {
                    return i.mac !== "00:00:00:00:00:00";
                });

                if (macAddresses.length > 0) {
                    macAddress = macAddresses[0].mac;
                    break;
                }
            }
        }        

        let user = new User();
        user.email = this.user.email;

        if (this.user.password) {
            user.password = btoa(this.user.password);
        }

        this.authenticate(user);
    }

    private authenticate(user: User, macAddress: string = null) {

        window.scroll(0, 0);

        this.authService.authenticate(user, macAddress).then((res) => {
            if (res !== null) {
                if (res.hasOwnProperty("code")) {
                    this.validationEntry = new ValidationEntry();
                    this.validationEntry.validationCode = res.code;
                    this.validationEntry.userId = res.userId;
                    this.isActivation = true;
                } else if (res == true) {
                    this.routeUser(this.authService.loggedInUser);
                }
            }
            this.spinner.hide();
        });
    }

    private routeUser(user: User) {
        if (this.rememberMe == true) {
            Utils.setCookie("visitoraxis-email", btoa(user.email), 60, "/");
        }

        let url = "";

        let com = this.dataService.getCommunity();

        if (user.type == "ADMIN") {
            url = "/" + com.url + "/admin";
        } else if (user.type == "SYSTEM_ADMIN") {
            url = "./system-admin";
        } else if (user.type == "GUARD") {
            url = "/" + com.url + "/guards";
        } else {
            url = "/" + com.url + "/residents/" + user.unitId;
        }

        if (environment.isNative) {
            url = "/" + com.url + "/guards";
        }

        this.router.navigate([url]);
    }

    public activate() {

        if (this.validationEntry.password !== this.confirmPassword) {
            this.notificationService.showNotification({
                message: "Passwords do not match",
            });
            return;
        }

        this.spinner.show();

        this.authService.activateAccount(this.validationEntry).then((res) => {
            if (res !== null) {
                this.routeUser(this.authService.loggedInUser);
                // if (res.hasOwnProperty("user")) {
                //     let user: User = res.user;
                //     this.routeUser(user);
                // }
            }
            this.spinner.hide();
        });
    }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SystemAdminComponent } from "./system-admin.component";
import { SystmeAdminGuard } from "../../services/guards/system-admin-guard.service";
import { CommunityComponent } from "./community/community.component";
import { CommunitiesAdminComponent } from "./communities/communities.component";

const mainRoutes: Routes = [
    {
        path: "system-admin",
        redirectTo: "system-admin/dashboard",
    },
    {
        path: "system-admin",
        component: SystemAdminComponent,
        canActivate: [SystmeAdminGuard],
        children: [
            {
                path: "dashboard",
                component: CommunitiesAdminComponent,
                canActivateChild: [SystmeAdminGuard]
            },
            {
                path: "community/:communityUrl",
                component: CommunityComponent,
                canActivateChild: [SystmeAdminGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(mainRoutes)],
    exports: [RouterModule],
    providers: [SystmeAdminGuard]
})
export class SystemAdminRouting { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailTemplate } from '../../../../models/EmailTemplate';
import { EmailTemplateRequest } from '../../../../models/request/EmailTemplateRequest';
import { EmailTemplatesService } from '../../../../services/email-templates.service';
import { EmailTemplateModal } from '../../../../shared/modals/email-template-modal/email-template-modal.component';

@Component({
    selector: 'app-email-templates',
    templateUrl: './email-templates.component.html'
})
export class EmailTemplatesComponent implements OnInit {

    @ViewChild("templateModal") templateModal: EmailTemplateModal;

    private _templates: EmailTemplate[];

    constructor(private emailTemplatesService: EmailTemplatesService) {
        this.templates = [];
    }

    ngOnInit() {
        let request = new EmailTemplateRequest();
        this.emailTemplatesService.getEmailTemplates(request).then((templates: EmailTemplate[]) => {
            this.templates = templates;
        })
    }

    public removeTemplate() {

    }

    public showCreateTemplate(template?: EmailTemplate) {        
        this.templateModal.show(template).then(response => {
            if (response !== null) {
                if (template && template.id !== null) {
                    this.templates[this.templates.indexOf(template)] = response;
                } else {
                    this.templates.push(response);
                }
            }
        })
    }

    public get templates(): EmailTemplate[] {
        return this._templates;
    }
    public set templates(value: EmailTemplate[]) {
        this._templates = value;
    }

}

import { Component, ViewChild, TemplateRef, Input } from "@angular/core";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NotificationService } from "../../../services/notifications.service";
import { Community } from "../../../models/Community";
import { CommunityService } from "../../../services/community.service";

@Component({
    selector: "community-modal",
    templateUrl: "./community-modal.component.html"
})
export class CommunityModal {

    @ViewChild('communityModal') communityModal: TemplateRef<any>;
    @Input('userType') userType: string;

    public newCommunity: Community = new Community();
    public modalRef: BsModalRef = null;
    private response: any = null;
    private onClose: Subject<Community> = new Subject<Community>();
    private config: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false
    }
    public confirmPassword: string = "";
    public changePassword: boolean = false;
    public editMode: boolean = false;
    public showPassword: boolean = false;

    constructor(
        private ms: BsModalService,
        private cs: CommunityService,
        private ns: NotificationService
    ) {
        let self = this;
        this.onClose.subscribe((result: any) => {
            self.response = result;
        });
    }

    public toggleChangePassword() {
        this.changePassword = !this.changePassword;
    }

    public togglePassword() {
        this.showPassword = !this.showPassword;
    }

    public show(community?: Community) {

        let self = this;

        if (community) {
            this.newCommunity = community;
            this.editMode = true;
        } else {
            this.newCommunity = new Community();
            this.editMode = false;
        }

        let promise = new Promise<any>((resolve, reject) => {
            self.modalRef = self.ms.show(self.communityModal, self.config);
            self.ms.onHide.subscribe(() => {
                resolve(self.response);
            });
        });

        return promise;

    }

    public reject() {
        this.onClose.next(null);
        this.modalRef.hide();
    }

    public modifyCommunity() {
        let self = this;
        this.cs.modifyCommunity(this.newCommunity).then((community: Community) => {
            self.onClose.next(community);
            self.modalRef.hide();
        });
    }

}
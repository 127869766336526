import { Component, Input, OnInit } from "@angular/core";
import { NavigationService } from "./navigation.service";
import { DataService } from "../../services/data.service";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { Community } from "../../models/Community";
import { Subject } from "rxjs";

@Component({
    selector: "va-nav-bar",
    templateUrl: "./navigation.component.html"
})
export class NavBarComponent implements OnInit {

    public showLogo: boolean = false;
    public clientLogo: string = "";
    private clientLogoChanged: Subject<string> = new Subject<string>();

    constructor(
        private navigationService: NavigationService,
        private dataService: DataService,
        private authService: AuthService,
        private router: Router
    ) {
        let self = this;
        this.clientLogoChanged.subscribe((clientLogo: string) => {
            self.clientLogo = clientLogo;
            self.showLogo = true;
            console.log("newLogoUrl", clientLogo);
        })
    }

    ngOnInit() {
        this.navigationService.checkConnection();
    }

    public logout() {
        let self = this;
        this.authService.logout().then(res => {
            if (res == true) {
                self.router.navigate(["./login"]);
            }
        });
    }

    public getUser() {
        let name = "";
        if (this.authService.loggedInUser !== null) {
            name = this.authService.loggedInUser.firstName + " " + this.authService.loggedInUser.lastName;
        }
        return name;
    }

    public errorLoadingLogo() {
        this.showLogo = false;
    }

    public imageLoaded() {
        this.showLogo = true;
    }

    public getClientLogo() {

        let community = this.dataService.community;

        let logoUrl = "";

        if (!community) {
            this.showLogo = false;
            return logoUrl;
        }

        if (community.hasOwnProperty("logoUrl") && community.logoUrl) {
            this.showLogo = true;
            // logoUrl = "./assets/client-logos/" + community.logoUrl;
            logoUrl = community.logoUrl;
        } else {
            this.showLogo = false;
            logoUrl = "";
        }

        return logoUrl;

    }

    public setClientLogo(community: Community) {

        let logoUrl = "";
        if (community.logoUrl) {
            logoUrl = "./assets/client-logos/" + community.logoUrl;
        } else {
            logoUrl = "";
        }

        this.clientLogoChanged.next(logoUrl);

    }

    public tabSelected(tab: number) {
        this.navigationService.setActiveTab(tab);
    }

    public get activeTab() {
        return this.navigationService.activeTab;
    }

    public get tabs() {
        return this.navigationService.tabs;
    }

    public get connection() {
        return this.navigationService.connection;
    }

    public get community() {
        return this.dataService.community;
    }

}
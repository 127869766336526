import { Component } from "@angular/core";
import { NotificationService } from "../../services/notifications.service";

@Component({
    selector: "notifications",
    templateUrl: "./notifications.component.html"
})
export class NotificationsComponent {
    constructor(private ns: NotificationService) {}

    get notification() {
        return this.ns.notification;
    }

    closeNotif() {
        this.notification.show = -1;
    }

    open() {
        this.notification.show = 1;
    }
}

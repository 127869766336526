import {
	Component,
	ViewChild,
	TemplateRef,
	Input,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import { VisitationLog } from "../../../models/VisitationLog";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { Subject, Subscription } from "rxjs";
import { LogsService } from "../../../services/logs.service";
import { Unit } from "../../../models/Unit";
import { User } from "../../../models/User";
import { AuthService } from "../../../services/auth.service";
import { NotificationService } from "../../../services/notifications.service";
import { ScannerService } from "src/app/services/scanner.service";
import { ScannedDocument } from "src/app/models/ScannedDocument";

@Component({
	selector: "log-modal",
	templateUrl: "./log-modal.component.html",
})
export class LogModal implements OnChanges {
	@ViewChild("addLog") modal: TemplateRef<any>;

	@Input() displayAsModal: boolean = true;
	@Input() unit: Unit = null;

	public modalRef: BsModalRef = null;
	public newLog: VisitationLog = new VisitationLog();
	public viewLog: boolean = false;
	private response: any = null;
	private scannerSubscription: Subscription;
	private onClose: Subject<VisitationLog> = new Subject<VisitationLog>();
	private config: ModalOptions = {
		ignoreBackdropClick: true,
		keyboard: false,
	};

	constructor(
		private logsService: LogsService,
		private modalService: BsModalService,
		private authService: AuthService,
		private notificationService: NotificationService,
		private scannerService: ScannerService
	) {
		this.onClose.subscribe((result: any) => {
			this.response = result;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["unit"] && this.unit) {
			if (this.unit.showDetails && !this.scannerSubscription) {
				this.scannerSubscription =
					this.scannerService.lastScannedDocument$.subscribe(
						(document) => {
							if (document) {
								this.setLogFromScannedDocument(document);
							}
						}
					);
			} else if (!this.unit.showDetails && this.scannerSubscription) {
				this.scannerSubscription.unsubscribe();
				this.scannerSubscription = null;
			}
		}
	}

	private setLogFromScannedDocument(document: ScannedDocument) {
		if (!document) {
			return;
		}

		this.newLog.firstName = document.firstName;
		this.newLog.lastName = document.lastName;
		this.newLog.scannedData = document.scannedData;
	}

	public showLogDialog(unit: Unit, log?: VisitationLog) {
		this.unit = unit;

		this.newLog = log ? log : new VisitationLog();
		this.viewLog = log ? true : false;

		return new Promise<any>((resolve, reject) => {
			this.modalRef = this.modalService.show(this.modal, this.config);
			this.modalService.onHide.subscribe(() => {
				resolve(this.response);
			});
		});
	}

	public addVisitorLog() {

		if (!this.shouldShowClear()) {
			this.notificationService.showSimpleNotification(
				"Please add at least one entry to continue."
			);
			return;
		}

		const log = this.prepareNewLogWithUserAndUnit();		

		this.logsService.modifyVisitLog(log).then((log: VisitationLog) => {
			this.onClose.next(log);
			if (this.displayAsModal) {
				this.modalRef.hide();
			} else {
				this.logsService.updateLog(log);
			}
			this.scannerService.updateLastScannedDocument(null);
			this.clearLog();
		});
	}

	private prepareNewLogWithUserAndUnit() {
		const log = Object.assign({}, this.newLog);

		log.unit = new Unit();
		log.unit.id = this.unit.id;

		log.user = new User();
		log.user.id = this.authService.loggedInUser.id;
		log.user.firstName = this.authService.loggedInUser.firstName;
		log.user.lastName = this.authService.loggedInUser.lastName;

		return log;
	}

	public hideLogDialog() {
		this.onClose.next(null);
		this.modalRef.hide();
		this.clearLog();
	}

	public clearLog() {
		this.newLog = new VisitationLog();
	}

	public shouldShowClear() {
		if (
			this.newLog.firstName ||
			this.newLog.lastName ||
			this.newLog.company ||
			this.newLog.tag
		) {
			return true;
		}
		return false;
	}
}

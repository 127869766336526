export class User {
    id: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;
    address1: string;
    address2: string;
    phone1: string;
    phone2: string;
    type: string;
    communityId: string;
    communityIds: string[];
    unitId: string;
    notAllowedIn: boolean;
    nonResidingOwner: boolean;
    expirationDate: string;
    courtesyCall: boolean;
    relationship: string;
    hours: string;
    primary: boolean;
    lastLogin: string;
}
import { Component, ViewChild, OnInit, Input } from "@angular/core";
import { User } from "../../models/User";
import { UserModal } from "../modals/user-modal/user-modal.component";
import { Community } from "../../models/Community";
import { CommunityService } from "../../services/community.service";
import { UserService } from "../../services/user.service";
import { NotificationService } from "../../services/notifications.service";
import { UserRequest } from "../../models/request/UserRequest";
import { Utils } from "../../utils/Utils";
import { ModalService } from "../../services/modal.service";

@Component({
    selector: "users-list",
    templateUrl: "./users.component.html"
})
export class UsersComponent implements OnInit {

    @Input('type') type: string;
    @ViewChild('userModal') userModal: UserModal;
    public users: User[] = [];
    public searchParam: string = "";
    public newGuard = null;
    public communities: Community[] = [];
    public selectedCommunityId: string = "";

    public lastRequest: UserRequest = null;
    public sortDirection: number = 1;
    public sortOption: string = "firstName";
    public sortingOptions: any[] = [
        {
            label: "First Name",
            key: "firstName"
        },
        {
            label: "Last Name",
            key: "lastName"
        },
        {
            label: "Email",
            key: "email"
        },
        // {
        //     label: "Last Login",
        //     key: "lastLogin"
        // }
    ];

    constructor(
        private cs: CommunityService,
        private us: UserService,
        private ns: NotificationService,
        private ms: ModalService
    ) {
        this.subscribeToCommunity();
    }

    ngOnInit() {
        this.getCommunities();
        if (this.cs.selectedCommunity != null) {
            this.loadCommunity(this.cs.selectedCommunity);
        }
    }

    private getCommunities() {
        let self = this;
        this.cs.getCommunties(null).then((communities: Community[]) => {
            self.communities = communities;
        });
    }

    private subscribeToCommunity() {
        let self = this;
        this.cs.selectedCommunityChanged.subscribe((community: Community) => {
            if (community !== null) {
                self.loadCommunity(community);
            }
        });
    }

    private loadCommunity(community: Community) {
        this.selectedCommunityId = community.id;
        this.searchUsers();
    }

    public communityChanged() {
        let community = this.cs.getCommunityById(this.selectedCommunityId);
        if (community !== null) {
            this.cs.setCommunity(community);
        }
    }

    public autoSearch(event: KeyboardEvent) {
        if (this.searchParam.length > 2) {
            this.searchUsers();
        } else if (this.searchParam.length == 0) {
            this.searchUsers();
        }
    }

    public searchUsers(req?: UserRequest) {

        let self = this;

        if (!this.selectedCommunityId) {
            this.ns.showSimpleNotification("Please select a Community");
        }

        if (!req) {
            req = new UserRequest();
        }

        req.orFields = {
            firstName: [this.searchParam],
            lastName: [this.searchParam],
            email: [this.searchParam]
        }
        req.andFields = {
            type: [this.type]
        }
        req.ors = ["firstName", "lastName", "email"];
        req.ands = ["type"];
        req.andClauses = true;
        req.showLastLogin = true;

        req.sorters = {};
        req.sorters[this.sortOption] = this.sortDirection;

        this.lastRequest = req;

        this.us.searchUsers(req).then((users: User[]) => {
            self.users = users;
            console.log(self.users);
        })
    }

    public removeUser(event: MouseEvent, user: User) {

        let self = this;

        event.stopPropagation();

        let mes = "Are you sure you want to remove " + user.firstName + " " + user.lastName + "?";

        let type = this.type.toLowerCase();
        type = type[0].toUpperCase() + type.slice(1);

        this.ms.showConfirmationModal("Remove " + type + " User", mes).then((res: boolean) => {
            if (res == true) {

                let tempUser = new User();
                tempUser.id = user.id;

                self.us.removeUser(tempUser).then(res => {
                    if (res == true) {
                        self.users.splice(self.users.indexOf(user), 1);
                    }
                });
            }
        });
    }

    public isSelectedSortOption(option: string) {
        return this.sortOption === option;
    }

    public setSortOption(option: string) {
        this.sortOption = option;
        this.searchUsers(this.lastRequest);
    }

    public toggleSortDirection() {
        this.sortDirection = this.sortDirection * -1;
        this.searchUsers(this.lastRequest);
    }

    public formatDate(date: string) {
        return Utils.formatDate(new Date(date), true);
    }

    public showCreateDialog(user?: User) {

        if (!this.selectedCommunityId) {
            this.ns.showSimpleNotification("Please select a Community");
            return;
        }

        this.userModal.show(user);
    }

    get community() {
        return this.cs.selectedCommunity;
    }

}
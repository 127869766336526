import { Constants } from "../utils/Constants";

export class ScannedDocument {
	documentId?: string;
	dob?: string;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	issueDate?: string;
	expirationDate?: string;
	address1?: string;
	city?: string;
	state?: string;
	zipCode?: string;
	scannedData?: string;

	constructor(scannedData: string) {
		this.scannedData = scannedData;
		this.extractData();
		if (!this.firstName && !this.lastName && scannedData.includes("DAA")) {
			this.handleOlderLicense();
		}
	}

	private extractData() {
		const dataToExtract = Constants.AAMVA_DATA;
		dataToExtract.reduce((accumulator, data) => {
			if (data.count !== null) {
				this[data.key] = this.extractStartAndCount(
					data.start,
					data.count
				);
			} else {
				this[data.key] = this.extractStartAndEnd(data.start, data.end);
			}
			return accumulator;
		}, {});
	}

	private handleOlderLicense() {
		const name = this.extractStartAndEnd("DAA", "DAG");
		const parts = name.split(",");
		this.lastName = parts[0];
		this.firstName = parts[1];
		if(parts[2]){
			this.middleName = parts[2];
		}
	}

	private extractStartAndCount(start: string, count: number) {
		const index = this.scannedData.indexOf(start) + start.length;
		if (index < 0) {
			return "";
		}
		let extractedData = this.scannedData.substring(index, index + count);
		const newlineIndex = extractedData.indexOf("\n");
		if (newlineIndex >= 0) {
			extractedData = extractedData.substring(0, newlineIndex);
		}
		return extractedData.trim();
	}

	private extractStartAndEnd(start: string, end: string) {
		const startIndex = this.scannedData.indexOf(start) + start.length;
		const endIndex = this.scannedData.indexOf(end);
		return startIndex >= 0 && endIndex >= 0
			? this.scannedData
					.substring(startIndex, endIndex)
					.replace(/\r?\n|\r/g, "")
			: "";
	}
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Community } from '../../../models/Community';
import { User } from '../../../models/User';
import { UserLocation } from '../../../models/UserLocation';
import { CommunityService } from '../../../services/community.service';
import { ModalService } from '../../../services/modal.service';
import { NotificationService } from '../../../services/notifications.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-community-user-modal',
	templateUrl: './community-user-modal.component.html'
})
export class CommunityUserModalComponent implements OnInit {

	@ViewChild('addUserToCommunityModal') modal: TemplateRef<any>;

	public modalRef: BsModalRef;
	private config: ModalOptions;
	private response: any;
	private onClose: Subject<any>;
	public user: User;
	public usersCommunities: Community[];
	public communityToAdd: Community;

	constructor(
		private bsModalService: BsModalService,
		private modalService: ModalService,
		private communityService: CommunityService,
		private userService: UserService,
		private notificationService: NotificationService
	) {
		this.modalRef = null;
		this.user = new User();
		this.response = null;
		this.usersCommunities = [];
		this.communityToAdd = null;

		this.onClose = new Subject<any>();
		this.onClose.subscribe((result: any) => {
			this.response = result;
		});

		this.config = {
			ignoreBackdropClick: true,
			keyboard: false
		};
	}

	ngOnInit() {

	}

	public addUserToCommunity() {

		if (this.communityToAdd == null) {
			this.notificationService.showSimpleNotification("Please select a community");
			return;
		}

		let userName = `${this.user.firstName} ${this.user.lastName}`;
		let mes = `Are you sure you want to add ${userName} to ${this.communityToAdd.name} community?`;

		this.modalService.showConfirmationModal("Add to Community", mes).then(response => {
			if (response == true) {
				this.userService.copyUser(this.user, this.communityToAdd).then(res => {
					if (res !== null) {
						this.user.communityIds = res.communityIds;
						this.notificationService.showSimpleNotification(`Added ${userName} to ${this.communityToAdd.name} community.`);
						this.reject();
					}
				});
			}
		});
	}

	public show(user: User) {
		return new Promise<any>((resolve, reject) => {

			this.user = user;

			this.communityService.getCommunties(null).then(() => {
				this.userService.getUserLocation(user).then((location: UserLocation) => {

					user.communityIds = [];

					let tempCommunities: Community[] = [];
					for (let l of location.locations) {
						let com = this.communityService.getCommunityById(l.communityId);
						if (com !== null) {
							tempCommunities.push(com);
							user.communityIds.push(l.communityId);
						}
					}

					this.usersCommunities = tempCommunities;

					this.modalRef = this.bsModalService.show(this.modal, this.config);
					this.bsModalService.onHide.subscribe(() => {
						resolve(this.response);
					});

				});
			});
		});
	}

	public reject() {
		this.onClose.next(null);
		this.modalRef.hide();
	}

	get communities() {
		return this.communityService.communities;
	}

}

import { Injectable } from "@angular/core";
import { Port } from "../models/Port";

@Injectable({
	providedIn: "root",
})
export class SerialPortService {
	constructor() {}

	public listPorts() {
		return new Promise<Port[]>((resolve, reject) => {
			const SerialPort = window["require"]("serialport");
			const { list } = SerialPort;
			list().then((ports: Port[]) => {
				resolve(ports);
			}, error => {
        reject(error);
      });
		});
	}
}

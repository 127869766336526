import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { SystemAdminComponent } from "../system-admin/system-admin.component";
import { CommunitiesAdminComponent } from "../system-admin/communities/communities.component";
import { SystemAdminRouting } from "./system-admin-routing.module";
import { GuardsSystemAdminComponent } from "./guards/guards.component";
import { ResidentsAdminComponent } from "./residents/residents.component";
import { VisitorsAdminComponent } from "./visitors/visitors.component";
import { AdminUsersAdminComponent } from "./admin-users/admin-users.component";
import { SharedModule } from "../../shared/shared.module";
import { UnitsAdminComponent } from "./communities/units-admin/units-admin.component";
import { UserAdminComponent } from './communities/user-admin/user-admin.component';
import { CommunityLicensesComponent } from './communities/community-licenses/community-licenses.component';
import { CommunityComponent } from './community/community.component';
import { EmailTemplatesComponent } from './community/email-templates/email-templates.component';
import { UploadLogoComponent } from './community/upload-logo/upload-logo.component';
import { CommunityImportComponent } from './community/community-import/community-import.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SystemAdminRouting,
        SharedModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        ModalModule.forRoot()
    ],
    declarations: [
        SystemAdminComponent,
        CommunitiesAdminComponent,
        GuardsSystemAdminComponent,
        ResidentsAdminComponent,
        VisitorsAdminComponent,
        UnitsAdminComponent,
        AdminUsersAdminComponent,
        UserAdminComponent,
        CommunityLicensesComponent,
        CommunityComponent,
        EmailTemplatesComponent,
        UploadLogoComponent,
        CommunityImportComponent
    ],
    providers: [],
    exports: []
})
export class SystemAdminModule { }

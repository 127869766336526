import { Injectable } from "@angular/core";
import { HttpRequest, HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import * as $ from "jquery";
import { environment } from "../../environments/environment";
import { CommunityRequest } from "../models/request/CommunityRequest";
import { Community } from "../models/Community";
import { User } from "../models/User";
import { UserRequest } from "../models/request/UserRequest";
import { UnitRequest } from "../models/request/UnitRequest";
import { Unit } from "../models/Unit";
import { VisitationLogRequest } from "../models/request/VisitationLogRequest";
import { VisitationLog } from "../models/VisitationLog";
import { MemoRequest } from "../models/request/MemoRequest";
import { Memo } from "../models/Memo";
import { Subject } from "rxjs";
import { EmailTemplateRequest } from "../models/request/EmailTemplateRequest";
import { EmailTemplate } from "../models/EmailTemplate";

@Injectable()
export class DataService {

    private serviceUrl: string = "/";
    private accessToken: string = null;
    private authenticating: boolean = false;
    private cookieName: string = "visitoraxis_access_token";
    public community: Community = null;
    public communityChanged: Subject<Community> = new Subject<Community>();

    constructor(
        private http: HttpClient
    ) {

        let self = this;

        this.serviceUrl = environment.serviceUrl;

        var token = this.getCookie(this.cookieName);
        if (token !== null) {
            this.accessToken = token;
        }

        this.communityChanged.subscribe((community: Community) => {
            if (community !== null) {
                self.community = community;
            }
        });

    }

    public setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
        this.setCookie(this.cookieName, accessToken, 1, "/");
    }

    public removeAccessToken() {
        this.accessToken = null;
        this.deleteCookie(this.cookieName);
    }

    public getTemplates(request: EmailTemplateRequest) {
        let params = { templateRequest: JSON.stringify(request) };
        return this.issueCommand("GET", "community/templates", params);
    }

    public modifyTemplate(emailTemplate: EmailTemplate) {
        let params = { emailTemplate: JSON.stringify(emailTemplate) };
        return this.issueCommand("POST", "community/templates", params);
    }

    public getMemos(req: MemoRequest) {
        let params = { memoRequest: JSON.stringify(req) };
        return this.issueCommand("GET", "unit-memo", params);
    }

    public modifyMemo(memo: Memo) {
        let params = { memo: JSON.stringify(memo) };
        return this.issueCommand("POST", "unit-memo", params);
    }

    public getVisitLogs(req: VisitationLogRequest) {
        let params = { visitationLogRequest: JSON.stringify(req) };
        return this.issueCommand("GET", "unit-visitors", params);
    }

    public modifyVisitationLog(log: VisitationLog) {
        let params = { visitationLog: JSON.stringify(log) };
        return this.issueCommand("POST", "unit-visitors", params);
    }

    public getUnits(req: UnitRequest) {
        let params = { unitRequest: JSON.stringify(req) };
        return this.issueCommand("GET", "unit", params);
    }

    public modifyUnit(unit: Unit) {
        let params = { unit: JSON.stringify(unit) };
        return this.issueCommand("POST", "unit", params);
    }

    public getUsers(req: UserRequest) {
        let params = { userRequest: JSON.stringify(req) };
        return this.issueCommand("GET", "user", params);
    }

    public modifyUser(user: User, sendEmail?: boolean, templateId?: string) {

        let params = { user: JSON.stringify(user) };

        if (sendEmail == true) {
            params['sendEmail'] = sendEmail;
        }

        if (templateId) {
            params['templateId'] = templateId;
        }

        return this.issueCommand("POST", "user", params);
    }

    public createCommunity(community: Community) {
        let params = { community: JSON.stringify(community) };
        return this.issueCommand("POST", "community", params);
    }

    public getCommunities(req: CommunityRequest) {
        let params = { communityRequest: JSON.stringify(req) };
        return this.issueCommand("GET", "community", params);
    }

    public setCommunity(community: Community) {
        this.communityChanged.next(community);
    }

    public getCommunity() {
        return this.community;
    }

    public getCommunityId() {
        if (this.community !== null) {
            return this.community.id;
        }
        return null;
    }

    public issueCommand(type: string, uri: string, params: any, dontWrapParams?: boolean): Promise<any> {

        let apiUrl = this.serviceUrl + uri;

        if (params == null) {
            params = {};
        }

        if (this.accessToken !== null && !this.authenticating) {
            params["access_token"] = this.accessToken;
        }

        if ((type == "GET" || type == "DELETE") && params !== null) {
            apiUrl += "?" + $.param(params);
        }

        let wrappedParams = $.param(params);
        if (dontWrapParams == true) {
            wrappedParams = params;
        }

        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            //let req = new HttpRequest(type, apiUrl, $.param(params), { headers: this.getHeaders() });
            let req = new HttpRequest(type, apiUrl, wrappedParams, { headers: this.getHeaders(dontWrapParams) });
            this.http.request(req).toPromise().then(event => {
                if (event instanceof HttpResponse) {
                    let response: any = event.body;
                    if (response.status == -1) {
                        //TODO: Unathorized Access
                        resolve(false);
                    } else {
                        resolve(response);
                    }
                } else {
                    resolve(false);
                }
            }).catch((error: any) => {
                // console.log(error);
                resolve(false);
            });
        });

        return promise;
    }

    public issueCommandForFiles(uri: String, formData: FormData): Promise<any> {

        let self = this;

        let apiUrl = this.serviceUrl + uri;

        let promise = new Promise<any>((resolve, reject) => {

            let options = {
                headers: this.getHeaders(true)
            };

            self.http.post(apiUrl, formData, options).toPromise().then((res: any) => {
                if (res.status == -1) {
                    //TODO: Unathorized Access
                    resolve(false);
                } else {
                    resolve(res);
                }
            }).catch(error => {
                console.log(error);
                resolve(false);
            });
        });
        return promise;
    }

    private getHeaders(isFormData: boolean = false) {
        let options = {};

        if (this.accessToken !== null) {
            options["accessToken"] = this.accessToken;
        } else if (this.authenticating) {
            this.authenticating = false;
        }

        if (this.community !== null && this.community.id) {
            options["communityId"] = this.community.id;
        }

        if (isFormData == false) {
            options["Content-Type"] = "application/x-www-form-urlencoded";
        }

        let headers = new HttpHeaders(options);

        return headers;
    }

    private deleteCookie(name: string) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;;";
    }

    private setCookie(name: string, value: string, expireDays: number, path: string = "") {
        let d: Date = new Date();
        d.setDate(d.getDate() + expireDays);
        let expires: string = `expires=${d.toUTCString()}`;
        let cpath: string = path ? `; path=${path}` : "";
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    private getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(";");
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, "");
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }

        return null;
    }
}

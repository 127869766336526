import { Component, Input, ViewChild, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { User } from "../../../models/User";
import { Unit } from "../../../models/Unit";
import { NotificationService } from "../../../services/notifications.service";
import { Utils } from "../../../utils/Utils";
import { UnitService } from "../../../services/unit.service";
import { Subject } from "rxjs";


@Component({
    selector: "unit-users-modal",
    templateUrl: "./unit-users-modal.component.html"
})
export class UnitUsersModal {

    @ViewChild('unitUsersModal') unitUsersModal: TemplateRef<any>;

    @Input() userType: string;

    private unit: Unit;
    public newUser: User;
    public editMode: boolean = false;
    public tempVisitorExpiration: Date = null;
    public temporaryVisitor: boolean = false;
    private onClose: Subject<Unit> = new Subject<Unit>();
    private modalRef: BsModalRef;
    private config: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false
    }

    public minDate: Date = new Date();
    public bsConfig: Partial<BsDatepickerConfig> = {
        containerClass: "theme-default",
        dateInputFormat: 'MM/DD/YYYY',
        selectFromOtherMonth: true,
        adaptivePosition: true,
        showWeekNumbers: false
    };

    constructor(
        private ms: BsModalService,
        private ns: NotificationService,
        private us: UnitService
    ) { }

    private validateUser() {

        let tempUser = new User();

        //Validate First Name
        if (!this.newUser.firstName && this.newUser.relationship !== "Company") {
            this.ns.showNotification({ message: "First Name is required" });
            return false;
        } else {
            tempUser.firstName = this.newUser.firstName;
        }

        //Validate Last Name
        if (!this.newUser.lastName && this.newUser.relationship !== "Company") {
            this.ns.showNotification({ message: "Last Name is required" });
            return false;
        } else {
            tempUser.lastName = this.newUser.lastName;
        }

        //Validate Company Name
        if (this.newUser.relationship == "Company" && !this.newUser.companyName) {
            this.ns.showNotification({ message: "Company Name is required" });
            return false;
        } else {
            tempUser.companyName = this.newUser.companyName;
        }

        //Validate Ownder
        if (this.userType == "Owner") {

            if (!this.newUser.phone1) {
                this.ns.showNotification({ message: "Phone number is required" });
                return false;
            }

            let str = Utils.escapeStr(this.newUser.phone1);
            if (str.length !== 10) {
                this.ns.showNotification({ message: "Invalid phone number" });
                return false;
            }
            tempUser.phone1 = str;
            //TODO: if user ceration succeeds then insert
            // this.unit.owner = tempUser;
        }

        if (this.newUser.relationship) {
            tempUser.relationship = this.newUser.relationship;
        }

        if (this.tempVisitorExpiration && this.userType == "Visitor") {
            tempUser.expirationDate = Utils.formatDate(this.tempVisitorExpiration);
        }

        if (this.newUser.courtesyCall == true) {
            tempUser.courtesyCall = true;
        }

        if (this.newUser.hours) {
            tempUser.hours = this.newUser.hours;
        }

        if(this.newUser.notAllowedIn){
            tempUser.notAllowedIn = this.newUser.notAllowedIn;
        }

        return tempUser;

    }

    public addUserToUnit() {
        let self = this;

        if (this.unit == null) {
            return;
        }

        let tempUser = this.validateUser();
        if (tempUser == false) {
            return;
        }

        let tempUnit: Unit = Utils.cloneObject(this.unit);

        if (this.userType == "Owner") {
            tempUnit.owner = tempUser;
        }

        //TODO: insert to unit if successfull
        if (this.newUser.primary == true) {
            tempUser.primary = true;
            tempUnit.contact = this.newUser;
        }

        //If we're modifying an exisiting user
        if (this.editMode == true) {
            this.editUser(tempUnit, tempUser);
        } else {
            this.insertUser(tempUnit, tempUser);
        }
    }

    private insertUser(unit: Unit, user: User) {

        if (this.userType == "Visitor") {
            if (this.temporaryVisitor == true) {
                unit.temporaryVisitors.push(user);
            } else {
                unit.visitors.push(user);
            }
        } else if (this.userType == "Resident") {
            if (this.newUser.primary == true) {
                unit.residents.unshift(user);
            } else {
                unit.residents.push(user);
            }

            if (!unit.contact && unit.residents.length > 0) {
                unit.contact = unit.residents[0];
            }
        }

        this.modifyUnit(unit);

    }

    private editUser(unit: Unit, user: User) {

        if (this.userType == "Resident") {
            let index = null;
            for (let i in unit.residents) {
                let res = unit.residents[i];
                if ((res.firstName == user.firstName) && (res.lastName == user.lastName)) {
                    index = i;
                    break;
                }
            }
            if (index !== null) {
                if (this.newUser.primary == true) {
                    unit.residents.splice(index, 1);
                    for (let u of unit.residents) {
                        u.primary = false;
                    }
                    unit.residents.unshift(user);
                } else {
                    unit.residents[index] = user;
                }
            }
        } else {
            if (this.temporaryVisitor == true) {
                let visIndex = null;
                for (let i in unit.temporaryVisitors) {
                    let temp = unit.temporaryVisitors[i];
                    if ((temp.firstName == user.firstName) && (temp.lastName == user.lastName)) {
                        visIndex = i;
                        break;
                    }
                }
                if (visIndex !== null) {
                    unit.temporaryVisitors[visIndex] = user;
                }
            }
        }

        this.modifyUnit(unit);

    }

    private modifyUnit(unit: Unit) {
        let self = this;
        this.us.modifyUnit(unit).then((unitResponse: Unit) => {
            self.hide(unitResponse);
        });
    }

    public show(unit: Unit, user?: User, isTemporary?: boolean) {

        let self = this;

        this.unit = unit;

        this.tempVisitorExpiration = new Date();

        if (isTemporary && isTemporary == true) {
            this.temporaryVisitor = true;
        } else {
            this.temporaryVisitor = false;
        }

        if (user) {
            this.newUser = user;
            this.editMode = true;
            if (user.phone1) {
                this.newUser.phone1 = Utils.formatPhones(this.newUser.phone1);
            }
            if (user.expirationDate) {
                let date = Utils.normalizeDate(user.expirationDate);
                this.tempVisitorExpiration = new Date(date);
            }
        } else {
            this.newUser = new User();
            this.editMode = false;
        }

        let promise = new Promise<any>((resolve, reject) => {
            self.modalRef = self.ms.show(self.unitUsersModal, self.config);
            self.onClose.subscribe((unit: Unit) => {
                resolve(unit);
            });
        });

        return promise;

    }

    public hide(unit: Unit) {
        this.onClose.next(unit);
        this.modalRef.hide();
    }

    public onCalShow(event: any) {
        const dayHoverHandler = event.dayHoverHandler;
        const hoverWrapper = ($event: any) => {
            const cell = $event;
            cell.isHovered = false;
            return dayHoverHandler($event);
        };
        event.dayHoverHandler = hoverWrapper;
    }

    public enforceFormat(event: KeyboardEvent) {
        Utils.enforceFormat(event)
    }

    public formatToPhone(event: KeyboardEvent, model: any, key: string) {

        if (Utils.isModifierKey(event)) {
            return;
        }

        let phone = model[key];

        if (!phone) {
            return "";
        }

        model[key] = Utils.formatPhones(phone);

    };

}
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CommunityResolver } from "../../services/resolver/community-resolver.service";
import { GuardsPortalComponent } from "./guards.component";
import { GuardsGuard } from "../../services/guards/guards-guard.service";
import { GuardResolverService } from "../../services/resolver/guard-resolver.service";

const mainRoutes: Routes = [
    {
        path: ":communityUrl/guards",
        component: GuardsPortalComponent,
        canActivate: [GuardsGuard],
        resolve: {
            // community: CommunityResolver,
            community: GuardResolverService
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(mainRoutes)],
    exports: [RouterModule],
    providers: [GuardsGuard, CommunityResolver]
})
export class GuardsRouting { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Community } from '../../../models/Community';
import { CommunityService } from '../../../services/community.service';

@Component({
	selector: 'app-community',
	templateUrl: './community.component.html'
})
export class CommunityComponent implements OnInit {

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private communityServive: CommunityService
	) {

	}

	ngOnInit() {
		let communityUrl = this.route.snapshot.paramMap.get("communityUrl");
		if (this.community == null && communityUrl !== null) {
			this.getCommunity(communityUrl);
		}
	}

	public backToResults() {
		this.router.navigate(["/system-admin/dashboard/"]);
	}

	private getCommunity(url: string) {
		this.communityServive.getCommunityFromUrl(url).then((community: Community) => {
			if (community !== null) {				
				this.communityServive.setCommunity(community);
			}
		});
	}

	public get community() {
		return this.communityServive.selectedCommunity;
	}

}

import { Injectable } from "@angular/core";
import { Unit } from "../models/Unit";
import { DataService } from "./data.service";
import { NotificationService } from "./notifications.service";
import { UnitRequest } from "../models/request/UnitRequest";
import { UserService } from "./user.service";
import { User } from "../models/User";
import { Subject } from "rxjs";
import { Utils } from "../utils/Utils";
import { BackupService } from "./backup.service";

@Injectable()
export class UnitService {

    public selectedUnit: Unit = null;
    public selectedUnitChanged: Subject<Unit> = new Subject<Unit>();

    private unitsQueue: Unit[];
    private errorQueue: Unit[];

    private onImportFinsihed: Subject<any> = new Subject<any>();

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
        private userService: UserService,
        private backupService: BackupService
    ) {
        let self = this;
        this.selectedUnitChanged.subscribe((unit: Unit) => {
            self.selectedUnit = unit;
        });
    }

    public importUnits(units: Unit[]) {
        return new Promise<any>((resolve, reject) => {

            this.errorQueue = [];
            this.unitsQueue = [];

            for (let unit of units) {
                this.unitsQueue.push(unit);
            }

            this.importNextUnit();

            this.onImportFinsihed.subscribe(() => {
                resolve(this.errorQueue);
            });

        });
    }

    private importNextUnit() {

        if (this.unitsQueue.length == 0) {
            this.onImportFinsihed.next(true);
            return;
        }

        for (let unit of this.unitsQueue) {
            this.modifyUnit(unit, true).then((res: Unit) => {
                if (res == null) {
                    this.errorQueue.push(unit);
                }
                this.finishedImportingUnit(unit);
            });
            break;
        }
    }

    private finishedImportingUnit(unit: Unit) {
        this.unitsQueue.splice(this.unitsQueue.indexOf(unit), 1);
        setTimeout(() => {
            this.importNextUnit();
        }, 250);
    }

    public setSelectedUnit(unit: Unit) {
        this.selectedUnitChanged.next(unit);
    }

    public getUnitById(id: string) {

        let self = this;

        let req = new UnitRequest();
        req.unit = new Unit();
        req.unit.id = id;

        let promise = new Promise((resolve, reject) => {
            self.searchUnits(req).then((units: Unit[]) => {
                let unit: Unit = null;
                if (units !== null) {
                    unit = units[0];
                }
                self.selectedUnitChanged.next(unit);
                resolve(unit);
            });
        });
        return promise;

    }

    public getUsersForUnit(unit: Unit) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {

            let userIds: string[] = [];

            self.userService.getUsersById(userIds).then((users: User[]) => {
                resolve(users);
            });
        });
        return promise;
    }

    public searchUnits(req: UnitRequest) {

        let self = this;

        let promise = new Promise<any>((resolve, reject) => {

            let units: Unit[] = [];

            if (req == null) {
                req = new UnitRequest();
            }

            // self.dataService.getUnits(req)
            self._getUnits(req).then(res => {

                if (res.status == 0 && res.data !== false) {

                    for (let u of res.data.results) {
                        let unit = new Unit();
                        unit.unpackArray(u);
                        units.push(unit);
                    }

                } else {
                    self.notificationService.showNotification({
                        message: "There was an error searching units. Contact administrator",
                        color: "danger"
                    });
                }
                resolve(units);
            });
        });
        return promise;

    }

    public modifyUnit(unit: Unit, suppressWarning?: boolean) {

        let self = this;

        //Normalize dates
        for (let v of unit.temporaryVisitors) {
            if (v.expirationDate) {
                v.expirationDate = Utils.normalizeDate(v.expirationDate);
            }
        }

        for (let v of unit.visitors) {
            if (v.expirationDate) {
                v.expirationDate = Utils.normalizeDate(v.expirationDate);
            }
        }

        for (let v of unit.residents) {
            if (v.expirationDate) {
                v.expirationDate = Utils.normalizeDate(v.expirationDate);
            }
        }

        let promise = new Promise<any>((resolve, reject) => {
            self.dataService.modifyUnit(unit).then(res => {
                let tempUnit: Unit = null;
                if (res.status == 0 && res.data !== false) {
                    tempUnit = res.data;
                } else {
                    if (suppressWarning !== true) {
                        self.notificationService.showSimpleNotification("There was an error modifying unit. Contact administrator", "danger");
                    }
                }
                resolve(tempUnit);
            });
        });
        return promise;
    }

    public removeUnit(unit: Unit) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {

            let tempUnit = new Unit();
            tempUnit.id = unit.id;

            self._removeUnitRequest(tempUnit).then(res => {
                let response: boolean = false;
                if (res.status == 0 && res.data !== false) {
                    response = true;
                } else {
                    response = false;
                    self.notificationService.showNotification({
                        message: "There was an error modifying unit. Contact administrator",
                        color: "danger"
                    });
                }
                resolve(response);
            });
        });
        return promise;
    }

    private _getUnits(req: UnitRequest) {
        if (navigator.onLine) {
            let params = { unitRequest: JSON.stringify(req) };
            return this.dataService.issueCommand("GET", "unit", params);
        } else {
            return new Promise<any>((resolve, reject) => {
                this.backupService.getUnits().then((units: Unit[]) => {

                    let searchTerm = "";
                    if (req.hasOwnProperty("ors")) {
                        searchTerm = req.orFields[req.ors[0]][0];
                    }

                    let results = Utils.searchArray(searchTerm, units);

                    resolve({
                        status: 0,
                        data: { results: results }
                    });
                });
            });
        }
    }

    private _modifyUnitRequest(unit: Unit) {
        let params = { unit: JSON.stringify(unit) };
        return this.dataService.issueCommand("POST", "unit", params);
    }

    private _removeUnitRequest(unit: Unit) {
        let params = { unit: JSON.stringify(unit) };
        return this.dataService.issueCommand("DELETE", "unit", params);
    }

}
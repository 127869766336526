import { Memo } from "./Memo";
import { User } from "./User";

export class Unit {
    id: string;
    streetAddress: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    owner: User;
    contact: User;
    residents: User[] = [];
    visitors: User[] = [];
    temporaryVisitors: User[] = [];
    contacts: string[] = [];
    message: string;
    memos: Memo[] = [];
    suspendRights: boolean = false;
    showDetails: boolean;

    public unpackArray(array: any){
        for(let key in array){
            this[key] = array[key];
        }
    }

}
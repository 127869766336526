import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { AuthGuard } from "../../services/guards/auth-guard.service";
import { CommunityResolver } from "../../services/resolver/community-resolver.service";
import { PrintUnitsComponent } from "./print-units/print-units.component";
import { PrintMissingContactsComponent } from "./print-missing-contacts/print-missing-contacts.component";

const mainRoutes: Routes = [
    {
        path: ":communityUrl/admin",
        component: AdminComponent,
        canActivate: [AuthGuard],
        resolve: {
            community: CommunityResolver
        }
    },
    {
        path: ":communityUrl/admin/print-units",
        component: PrintUnitsComponent,
        canActivate: [AuthGuard],
        resolve: {
            community: CommunityResolver
        }
    },
    {
        path: ":communityUrl/admin/print-missing-contacts",
        component: PrintMissingContactsComponent,
        canActivate: [AuthGuard],
        resolve: {
            community: CommunityResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(mainRoutes)],
    exports: [RouterModule],
    providers: [AuthGuard, CommunityResolver]
})
export class AdminRouting { }

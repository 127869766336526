import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailTemplateRequest } from '../../../models/request/EmailTemplateRequest';
import { EmailTemplatesService } from '../../../services/email-templates.service';
import { UserService } from '../../../services/user.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { BaseModal } from '../base/base-modal.component';

@Component({
	selector: 'app-batch-user-modal',
	templateUrl: './batch-user-modal.component.html'
})
export class BatchUserModalComponent extends BaseModal implements OnInit {

	@ViewChild('batchUserModal') modal: TemplateRef<any>;

	private processing: boolean;
	public batchResponse: any;
	public sendEmail: boolean;
	public selectedTemplateId: string;

	constructor(
		private bsModalService: BsModalService,
		private userService: UserService,
		private emailTemplateService: EmailTemplatesService,
		private spinnerService: SpinnerService
	) {
		super(bsModalService);
		this.processing = false;
		this.batchResponse = null;
		this.sendEmail = false;
		this.selectedTemplateId = null;
	}

	ngOnInit() {
		this.emailTemplateService.getEmailTemplates(new EmailTemplateRequest());
	}

	public batchCreateUsers() {

		if (this.processing) {
			return;
		}

		this.processing = true;
		this.spinnerService.showSpinner("mainSpinner");

		this.userService.batchCreateUser(this.sendEmail, this.selectedTemplateId).then((response: any) => {
			this.processing = false;
			this.batchResponse = response;
			this.spinnerService.hideSpinner("mainSpinner");
		});
	}

	public show() {
		this.batchResponse = null;
		this.sendEmail = false;
		return new Promise<any>((resolve, reject) => {
			this.modalRef = this.bsModalService.show(this.modal, this.config);
			this.bsModalService.onHide.subscribe(() => {
				resolve(this.response);
			});
		});
	}

	public get templates() {
		return this.emailTemplateService.templates;
	}

}

import { Component, ViewChild, TemplateRef } from "@angular/core";
import { Subject } from "rxjs";
import { Memo } from "../../../models/Memo";
import { ModalOptions, BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Unit } from "../../../models/Unit";
import { User } from "../../../models/User";
import { AuthService } from "../../../services/auth.service";
import { MemoService } from "../../../services/memo.service";

@Component({
    selector: "memo-modal",
    templateUrl: "./memo.component.html"
})
export class MemoModal {

    @ViewChild('memoModal') modal: TemplateRef<any>;
    public modalRef: BsModalRef = null;
    private response: any = null;
    private unit: Unit = null;
    private config: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false
    }

    constructor(
        private ms: BsModalService,
        private as: AuthService,
        private mms: MemoService
    ) { }

    public addMemoToUnit(message: string) {

        let self = this;

        let unit = new Unit;
        unit.id = this.unit.id;

        let user = new User();
        user.id = this.as.loggedInUser.id;
        user.firstName = this.as.loggedInUser.firstName;
        user.lastName = this.as.loggedInUser.lastName;

        let memo = new Memo();
        memo.message = message;
        memo.unit = unit;
        memo.user = user;

        this.mms.modifyMemo(memo).then((memo: Memo) => {
            self.response = memo;
            self.modalRef.hide();
        });

    }

    public show(unit: Unit) {

        this.unit = unit;

        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self.modalRef = self.ms.show(self.modal, self.config);
            self.ms.onHide.subscribe(() => {
                resolve(self.response);
            });
        });

        return promise;
    }

    public hide() {
        this.response = null;
        this.modalRef.hide();
    }

}
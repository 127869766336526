import { Component, Input, AfterViewInit } from "@angular/core";
import { SpinnerService } from "./spinner.service";

@Component({
    selector: "spinner",
    templateUrl: "./spinner.component.html"
})
export class SpinnerComponent implements AfterViewInit {

    @Input('key') key: string;

    public showSpinner: boolean = false;

    constructor(private spinnerService: SpinnerService) { }

    public ngAfterViewInit() {        
        this.spinnerService.setSpinner(this.key, this);
    }

    public show() {
        this.showSpinner = true;
    }

    public hide() {
        this.showSpinner = false;
    }

}
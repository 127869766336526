import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Community } from "../../../models/Community";
import { CommunityRequest } from "../../../models/request/CommunityRequest";
import { CommunityService } from "../../../services/community.service";
import { CommunityModal } from "../../../shared/modals/community-modal/community-modal.component";
import { ModalService } from "../../../services/modal.service";
import { NotificationService } from "../../../services/notifications.service";
import { SpinnerService } from "../../../shared/spinner/spinner.service";
import { SpinnerComponent } from "../../../shared/spinner/spinner.component";
import { Utils } from "../../../utils/Utils";
import { CommunityImportModal } from "../../../shared/modals/community-import-modal/community-import-modal.component";

@Component({
    selector: "communities-admin",
    templateUrl: "./communities.component.html"
})
export class CommunitiesAdminComponent implements OnInit {

    @ViewChild('comModal') comModal: CommunityModal;

    public communities: Community[] = [];
    public searchParam: string = "";

    public lastRequest: CommunityRequest = null;
    public sortDirection: number = 1;
    public sortOption: string = "name";
    public sortingOptions: any[] = [
        {
            label: "Name",
            key: "name"
        },
        {
            label: "Status",
            key: "status"
        },
        {
            label: "Last Login",
            key: "lastLogin"
        }
    ];

    private selectedFile: File = null;

    constructor(
        private communityService: CommunityService,
        private modalService: ModalService,
        private notificationService: NotificationService,
        private router: Router,
        private spinnerService: SpinnerService
    ) { }

    public ngOnInit() {
        this.searchCommunities();
    }

    public autoSearch(event: KeyboardEvent) {
        if (this.searchParam.length > 2) {
            this.searchCommunities();
        } else if (this.searchParam.length == 0) {
            this.searchCommunities();
        }
    }

    public formatDate(date: string) {
        return Utils.formatDate(new Date(date), true);
    }

    public isSelectedSortOption(option: string) {
        return this.sortOption === option;
    }

    public setSortOption(option: string) {

        if (this.sortOption == option) {
            this.toggleSortDirection();
            return;
        }

        this.sortOption = option;
        if (option == "lastLogin") {
            this.sortCommunitiesByLastLogin();
        } else {
            this.searchCommunities(this.lastRequest);
        }
    }

    public toggleSortDirection() {
        this.sortDirection = this.sortDirection * -1;
        if (this.sortOption == "lastLogin") {
            this.sortCommunitiesByLastLogin();
        } else {
            this.searchCommunities(this.lastRequest);
        }
    }

    public sortCommunitiesByLastLogin() {
        let communities = this.communityService.compareLastLogin(this.communities, this.sortDirection);
        this.communities = communities;
    }

    public isSelectedCommunity(community: Community) {
        if (this.communityService.selectedCommunity == null) {
            return false;
        }
        return community.id === this.communityService.selectedCommunity.id;
    }

    public onFileSelect(event: any) {
        if (event.target.files.length > 0) {
            this.selectedFile = event.target.files[0];
        }
    }

    public uploadLogo() {

        let self = this;

        if (this.selectedFile == null) {
            this.notificationService.showSimpleNotification("No file selected");
            return;
        }

        let community = this.communityService.selectedCommunity;
        if (!community) {
            this.notificationService.showSimpleNotification("Please select a community");
            return;
        }

        let spinner: SpinnerComponent = this.spinnerService.getSpinner("system-admin");
        spinner.show();

        let name = this.selectedFile.name;
        let ext = name.substring(name.lastIndexOf(".") + 1, name.length);

        let fileName = community.id + "." + ext;

        this.communityService.uploadLogo(this.selectedFile, fileName).then((com: Community) => {
            if (com !== null) {
                self.communityService.setCommunity(com);
                self.notificationService.showSimpleNotification("Logo has been uploaded successfully");
            }
            spinner.hide();
        });

    }

    public communitySelected(community: Community) {        
        this.communityService.setCommunity(community);
        this.router.navigate([`/system-admin/community/${community.url}/`]);
    }

    public visitLink(event: MouseEvent, community: Community) {

        event.stopPropagation();

        if (!community.url) {
            return;
        }

        let url = "/" + community.url + "/admin/";

        this.router.navigate([url]);
    }

    public updateStatus() {
        this.selectedCommunity.status = !this.selectedCommunity.status;
    }

    public modifyCommunity(community: Community) {
        let self = this;
        this.communityService.modifyCommunity(community).then(res => {
            if (res !== null) {
                self.notificationService.showSimpleNotification(community.name + " has been updated");
            }
        });
    }

    public showCommunityModal(community?: Community) {
        let self = this;
        this.comModal.show(community).then((responseCommunity: Community) => {
            if (responseCommunity && !community) {
                self.communities.unshift(responseCommunity);
            }
        })
    }

    public removeCommunity(event: MouseEvent, community: Community) {

        event.stopPropagation();

        let self = this;
        let mes = "Are you sure you want to remove " + community.name + "?";
        this.modalService.showConfirmationModal("Remove Community", mes).then(response => {
            if (response == true) {
                self.communityService.removeCommunity(community);
            }
        })
    }

    public backToResults() {
        this.communityService.setCommunity(null);
    }

    public searchCommunities(req?: CommunityRequest) {

        let self = this;

        if (!req) {
            req = new CommunityRequest();
            req.orFields = {
                name: [this.searchParam],
                address: [this.searchParam],
                phone: [this.searchParam]
            };
            req.ors = ["name", "address", "phone"];
            req.start = 0;
            req.count = 1000;
        }

        req.showLastLogin = true;

        if (this.sortOption !== "lastLogin") {
            req.sorters = {};
            req.sorters[this.sortOption] = this.sortDirection;
        }

        this.communityService.getCommunties(req).then((communities: Community[]) => {
            if (self.sortOption == "lastLogin") {
                communities = self.communityService.compareLastLogin(communities, self.sortDirection);
            }
            self.communities = communities;
        });
    }

    get selectedCommunity() {
        return this.communityService.selectedCommunity;
    }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { LogsService } from "src/app/services/logs.service";
import { MemoService } from "src/app/services/memo.service";
@Injectable()
export class NavigationService {

    public showNavBar: boolean;
    public showNavBarChanged: Subject<boolean> = new Subject<boolean>();

    public connection: boolean;
    public connectionChanged: Subject<boolean> = new Subject<boolean>();

    public tabs: any[] = [];
    private tabsChanged: Subject<any> = new Subject<any>();

    public activeTab: number = 0;
    public activeTabSubject: Subject<number> = new Subject<number>();

    constructor(
        private logsService: LogsService,
        private memoService: MemoService
    ) {
        this.showNavBar = true;
        this.showNavBarChanged.subscribe((showNavBar: boolean) => {
            this.showNavBar = showNavBar;
        });

        this.activeTabSubject.subscribe((activeTab: number) => {
            this.activeTab = activeTab;
        });

        this.tabsChanged.subscribe((tabs: any[]) => {
            this.tabs = tabs;
        });

        this.connection = true;
        this.connectionChanged.subscribe((connection: boolean) => {
            this.connection = connection;
        })
    }

    public setShowNavBar(showNavBar: boolean) {
        this.showNavBarChanged.next(showNavBar);
    }

    public setConnection(connection: boolean) {
        this.connectionChanged.next(connection);
    }

    public setTabs(tabs: any[]) {
        this.tabsChanged.next(tabs);
    }

    public setActiveTab(activeTab: number) {
        this.activeTabSubject.next(activeTab);
    }

    public checkConnection() {
        let self = this;
        setInterval(() => {
            if (self.connection == false && navigator.onLine) {
                this.logsService.checkLogQueue();
                this.memoService.checkMemoQueue();
            }
            self.setConnection(navigator.onLine);
        }, 3000);
    }

}
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Unit } from '../../../models/Unit';
import { CommunityImportService } from '../../../services/community-import.service';
import { NotificationService } from '../../../services/notifications.service';
import { UnitService } from '../../../services/unit.service';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
	selector: 'community-import-modal',
	templateUrl: './community-import-modal.component.html'
})
export class CommunityImportModal {

	@ViewChild('communityImportModal') modal: TemplateRef<any>;

	public units: Unit[] = [];

	public modalRef: BsModalRef = null;
	private config: ModalOptions = {
		ignoreBackdropClick: true,
		keyboard: false
	}

	private response: any = null;
	private onClose: Subject<any> = new Subject<any>();

	constructor(
		private modalService: BsModalService,
		private importService: CommunityImportService,
		private unitService: UnitService,
		private spinnerServce: SpinnerService,
		private notificationService: NotificationService
	) {
		this.onClose.subscribe((result: any) => {
			this.response = result;
		});
	}

	public processImport() {
		this.spinnerServce.showSpinner("system-admin");
		this.importService.importUnits(this.units).then((errorQueue: Unit[]) => {
			if (errorQueue.length > 0) {
				this.notificationService.showSimpleNotification(`${errorQueue.length} units failed to import.`, "danger");
			} else {
				this.notificationService.showSimpleNotification(`Imported ${this.units.length} units successfully.`);
			}

			this.spinnerServce.hideSpinner("system-admin");
		});
	}

	public show(units: Unit[]) {

		this.units = units;

		return new Promise<any>((resolve, reject) => {
			this.modalRef = this.modalService.show(this.modal, this.config);
			this.modalService.onHide.subscribe(() => {
				resolve(this.response);
			});
		});
	}

	public reject() {
		this.onClose.next(null);
		this.modalRef.hide();
	}

}

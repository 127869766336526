import { Injectable } from "@angular/core";
import { Community } from "../../models/Community";
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { CommunityService } from "../community.service";
import { DataService } from "../data.service";

@Injectable()
export class CommunityResolver implements Resolve<Community> {
    constructor(
        private communityService: CommunityService,
        private dataService: DataService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Community> {
        return new Promise<Community>((resolve, reject) => {
            let community: Community = null;

            let communityUrl = route.paramMap.get("communityUrl");
            let communityId = route.paramMap.get("communityId");

            if (communityUrl) {
                this.communityService
                    .getCommunityFromUrl(communityUrl)
                    .then((communityResponse: Community) => {
                        if (communityResponse !== null) {
                            community = communityResponse;
                            this.dataService.setCommunity(community);
                        }
                        resolve(community);
                    });
            } else if (communityId) {
                community = new Community();
                community.id = communityId;
                resolve(community);
            } else {
                resolve(community);
            }
        });
    }
}

import { Component, TemplateRef, OnInit } from "@angular/core";
import { User } from "../../../models/User";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { NotificationService } from "../../../services/notifications.service";
import { UserService } from "../../../services/user.service";
import { UnitService } from "../../../services/unit.service";
import { Unit } from "../../../models/Unit";
import { UserRequest } from "../../../models/request/UserRequest";
import { UnitRequest } from "../../../models/request/UnitRequest";

@Component({
    selector: "va-resident-visitors",
    templateUrl: "./visitors.component.html"
})
export class ResidentVisitorsComponent implements OnInit {

    public visitors: User[] = [];
    public selectedVisitor: User = null;
    public newVisitor: User = null;
    public modalRef: BsModalRef;

    constructor(
        private ns: NotificationService,
        private ms: BsModalService,
        private us: UserService,
        private uns: UnitService
    ) { }

    public ngOnInit() {

        let self = this;

        let req = new UnitRequest();
        req.orFields = {
            id: ["5e2286ff5c46097f0f57f8f8"]
        };
        req.ands = ["id"];

        this.uns.searchUnits(req).then((units: Unit[]) => {
            let unit = units[0];
            self.getVisitorsForUnit(unit);
        });
    }

    private getVisitorsForUnit(unit: Unit) {
        let self = this;
        this.uns.getUsersForUnit(unit).then((users: User[]) => {
            for (let user of users) {
                if (user.type == "VISITOR") {
                    self.visitors.push(user);
                }
            }
            // unit.visitorList = self.visitors;
            unit.visitors = self.visitors;
        });
    }

    public getVisitors() {
        let self = this;

        let req = new UserRequest();

        this.us.searchUsers(req).then((visitors: User[]) => {
            if (visitors !== null) {
                self.visitors = visitors;
            }
        });
    }

    public createVisitor() {
        let self = this;        

        this.newVisitor.type = "VISITOR";
        this.us.modifyUser(this.newVisitor).then((visitor: User) => {
            if (visitor !== null) {
                self.modalRef.hide();
                self.visitors.push(visitor);
            }
        });
    }

    public showCreateDialog(template: TemplateRef<any>, user: User) {

        if (user == null) {
            this.newVisitor = new User();
        } else {
            this.newVisitor = user;
            // this.inEditMode = true;
        }

        this.modalRef = this.ms.show(template);
    }

    public hideCreateDialog() {
        this.newVisitor = new User();
        this.modalRef.hide();
    }

}
import { Constants } from "../../utils/Constants";

export class DataRequest {
    start: number = 0;
    count: number = Constants.ITEMS_PER_REQUEST;
    sorters: any;
    operations: any;
    ands: string[];
    ors: string[];
    andFields: any;
    orFields: any;
    fields: any;
    dataType: any;
    andClauses: boolean = false;
    fetchAll: boolean = false;
}

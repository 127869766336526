import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from "../../shared/shared.module";
import { GuardsRouting } from "./guards-routing.modules";
import { GuardsPortalComponent } from "./guards.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        GuardsRouting,
        SharedModule,
        DragDropModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    declarations: [
        GuardsPortalComponent,
    ],
    providers: [],
    exports: []
})
export class GuradsModule { }

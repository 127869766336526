import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { User } from "../models/User";
import { NotificationService } from "./notifications.service";
import { ValidationEntry } from "../models/ValidationEntry";
import { Community } from "../models/Community";
import { BackupService } from "./backup.service";
import { Utils } from "../utils/Utils";

@Injectable()
export class AuthService {
    public loggedInUser: User;

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
        private backupService: BackupService
    ) {
        this.loggedInUser = null;
    }

    public logout() {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self._logoutRequest().then((res) => {
                if (res.status == 0 && res.data !== false) {
                    resolve(true);
                } else {
                    resolve(false);
                }
                self.dataService.removeAccessToken();
            });
        });
        return promise;
    }

    public checkLoginStatus() {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self._loginStatusRequest().then((res) => {
                if (res.status == 0 && res.data !== false) {
                    if (res.data.hasOwnProperty("user")) {
                        self.loggedInUser = res.data.user;
                    }

                    if (res.data.hasOwnProperty("communityId")) {
                        let com = new Community();
                        com.id = res.data.communityId;
                        self.dataService.setCommunity(com);
                    }

                    if (res.data.hasOwnProperty("community")) {
                        self.dataService.setCommunity(res.data.community);
                    }

                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
        return promise;
    }

    public checkActiveUser(user: User) {
        return new Promise<any>((resolve, reject) => {
            this.isUserActive(user).then(res => {
                let response: any = null;
                if (res.status == 0) {
                    response = res.data;
                } else {
                    let mes =
                        "Error checking email. Contact your administrator.";
                    if (res.hasOwnProperty("data")) {
                        mes = res.data;
                    }
                    this.notificationService.showNotification({ message: mes, color: "danger" })
                }
                resolve(response);
            });
        });
    }

    public activateAccount(entry: ValidationEntry) {
        let self = this;

        let promise = new Promise<any>((resolve, reject) => {
            let response = null;

            if (!entry.password) {
                self.notificationService.showNotification({
                    message: "Password is required.",
                    color: "danger",
                });
                resolve(response);
                return;
            }

            entry.password = btoa(entry.password);

            self.resetPassword(entry).then((res) => {
                if (res.status == 0 && res.data !== false) {
                    
                    response = res.data;

                    if (res.data.hasOwnProperty('user')) {
                        let user: User = res.data.user;
                        self.loggedInUser = user;
                    }

                    if (res.data.hasOwnProperty('accessToken')) {
                        self.dataService.setAccessToken(res.data.accessToken);
                    }

                } else {
                    let mes =
                        "Error reseting password. Please contact your administrator.";
                    if (res.hasOwnProperty("data") && res.data !== null) {
                        mes = res.data;
                    }
                    self.notificationService.showNotification({
                        message: mes,
                        color: "danger",
                    });
                }

                resolve(response);
            });
        });

        return promise;
    }

    public authenticate(user: User, macAddress?: string) {
        let self = this;

        let promise = new Promise<any>((resolve, reject) => {
            self.authUser(user, macAddress).then((res) => {
                let response = null;
                if (res.status == 0 && res.data !== false) {
                    response = res.data;

                    if (res.data.hasOwnProperty("user")) {
                        let user: User = res.data.user;
                        self.loggedInUser = user;
                    }

                    if (res.data.hasOwnProperty("accessToken")) {
                        self.dataService.setAccessToken(res.data.accessToken);
                    }

                    if (res.data.hasOwnProperty("community")) {
                        self.dataService.setCommunity(res.data.community);
                    }

                    resolve(true);
                } else {
                    let mes = "Error: Please contact your administrator!";
                    if (res.hasOwnProperty("data") && res.data !== null) {
                        mes = res.data;
                        if (mes == "Not active") {
                            let tempUser = new User();
                            tempUser.email = user.email;
                            self.checkActiveUser(tempUser).then((resp) => {
                                if (resp !== null) {
                                    response = resp;
                                }
                                resolve(response);
                            });
                        } else {
                            self.notificationService.showNotification({
                                message: mes,
                                color: "danger",
                            });
                            resolve(response);
                        }
                    } else {
                        self.notificationService.showNotification({
                            message: mes,
                            color: "danger",
                        });
                        resolve(response);
                    }
                }
            });
        });
        return promise;
    }

    public sendResetPasswordEmail(user: User) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self._resetPasswordRequest(user).then((res) => {
                let response = false;
                if (res.status == 0 && res.data !== false) {
                    response = true;
                }
                resolve(response);
            });
        });
        return promise;
    }

    private _logoutRequest() {
        return this.dataService.issueCommand("DELETE", "auth", null);
    }

    private _resetPasswordRequest(user: User) {
        let params = { user: JSON.stringify(user) };
        return this.dataService.issueCommand(
            "POST",
            "auth/reset-password",
            params
        );
    }

    private _loginStatusRequest() {
        return this.dataService.issueCommand("GET", "auth/status", null);
    }

    private isUserActive(user: User) {
        let params = { user: JSON.stringify(user) };
        return this.dataService.issueCommand("GET", "user-activation", params);
    }

    private resetPassword(entry: ValidationEntry) {
        let params = { validationEntry: JSON.stringify(entry) };
        return this.dataService.issueCommand("POST", "user-activation", params);
    }

    private authUser(user: User, macAddress?: string) {
        if (navigator.onLine) {
            let params = { user: JSON.stringify(user) };
            if (typeof macAddress !== "undefined") {
                params["macAddress"] = macAddress;
            }
            return this.dataService.issueCommand("POST", "auth", params);
        } else {
            return new Promise<any>((resolve, reject) => {
                this.backupService.getUsers().then((users: User[]) => {
                    let response = {
                        status: 0,
                        data: null
                    };

                    let filter = [...users].filter((u: User) => {
                        return u.email == user.email;
                    });

                    if (filter.length == 0) {
                        response.status = 1;
                        response.data = false;
                        resolve(response);
                        return;
                    } else {
                        let targetUser = filter[0];
                        Utils.matchPasswords(atob(user.password), targetUser.password).then(res => {
                            if (res == false) {
                                response.status = 1;
                                response.data = "Invalid password";
                            } else {
                                let clone = Object.assign({}, targetUser);
                                clone.password = null;
                                response.data = {
                                    user: clone,
                                };
                            }
                            resolve(response);
                        });
                    }
                });
            });
        }
    }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { License } from '../../../models/License';
import { Machine } from '../../../models/Machine';
import { CommunityService } from '../../../services/community.service';
import { NotificationService } from '../../../services/notifications.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { BaseModal } from "../base/base-modal.component";

@Component({
	selector: 'app-community-license-modal',
	templateUrl: './community-license-modal.component.html'
})
export class CommunityLicenseModal extends BaseModal implements OnInit {

	@ViewChild('addLicense') modal: TemplateRef<any>;
	private license: License;
	public machine: Machine;

	constructor(
		private bsModalService: BsModalService,
		private communityService: CommunityService,
		private notificationService: NotificationService,
		private spinnerService: SpinnerService
	) {
		super(bsModalService);
		this.machine = new Machine();
		this.license = new License();
	}

	ngOnInit() {

	}

	public addMachineToCommunity() {

		if (!this.machine.name) {
			this.notificationService.showSimpleNotification("Machine Name is required.");
			return;
		}

		if (!this.machine.macAddress) {
			this.notificationService.showSimpleNotification("Mac Address is required.");
			return;
		}

		this.spinnerService.showSpinner("mainSpinner");

		if (this.license.hasOwnProperty("machines")) {
			this.license.machines.push(this.machine);
		} else {
			this.license.machines = [this.machine];
		}

		this.license.communityId = this.communityService.selectedCommunity.id;

		this.communityService.modifyCommunityLicense(this.license).then((license: License) => {
			console.log(license);
			this.spinnerService.hideSpinner("mainSpinner");
			if (license !== null) {
				this.closeDialog(license);
			}
		});
	}

	public show(license?: License): Promise<any> {

		if (!license) {
			license = new License();
		}

		this.license = license;
		this.machine = new Machine();

		return new Promise<any>((resolve, reject) => {
			this.modalRef = this.bsModalService.show(this.modal, this.config);
			this.bsModalService.onHide.subscribe(() => {
				resolve(this.response);
			});
		});
	}
}

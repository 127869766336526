import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { User } from "../../models/User";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { NotificationService } from "../../services/notifications.service";
import { UnitService } from "../../services/unit.service";
import { VisitationLog } from "../../models/VisitationLog";
import { Memo } from "../../models/Memo";
import { Utils } from "../../utils/Utils";
import { LogsService } from "../../services/logs.service";
import { VisitationLogRequest } from "../../models/request/VisitationLogRequest";
import { Constants } from "../../utils/Constants";
import { ConfirmationModal } from "../../shared/modals/confirmation-modal/confirmation-modal.component";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker/public_api";
import { Unit } from "../../models/Unit";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";
import { MemoService } from "../../services/memo.service";
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";
import { UserRequest } from "../../models/request/UserRequest";
import { MemoRequest } from "../../models/request/MemoRequest";
import { UnitRequest } from "../../models/request/UnitRequest";
import { UnitUsersModal } from "../../shared/modals/unit-users-modal/unit-users-modal.component";

@Component({
    selector: "va-residents",
    templateUrl: "./resident.component.html"
})
export class ResidentsComponent implements OnInit {
    @ViewChild('confirmationModal') confirmationModal: ConfirmationModal;

    @ViewChild('createVisitorDialog') createUserDialog: UnitUsersModal;
    public modalRef: BsModalRef = null;

    // @ViewChild('createVisitorDialog') createUserDialog: TemplateRef<any>;
    // public modalRef: BsModalRef = null;

    @ViewChild('addLog') visitorLogDialog: TemplateRef<any>;
    public logRef: BsModalRef = null;

    @ViewChild('resetPassword') credentialsDialog: TemplateRef<any>;
    public credRef: BsModalRef = null;

    @ViewChild('addContact') contactDialog: TemplateRef<any>;
    public contactRef: BsModalRef = null;

    @ViewChild('addMemo') memoDialog: TemplateRef<any>;
    public memoRef: BsModalRef = null;

    @ViewChild('createUnitDialog') createUnitDialog: TemplateRef<any>;
    public unitRef: BsModalRef = null;

    public bsConfig: Partial<BsDatepickerConfig> = {
        containerClass: "theme-default",
        dateInputFormat: 'MM/DD/YYYY',
        selectFromOtherMonth: true,
        adaptivePosition: true,
        showWeekNumbers: false
    };

    public activeAccordion: number = null;
    public actionsMenu: boolean = false;
    public unitCreateIsMainContact: boolean = false;
    private unitEditMode: boolean = false;
    public newUnit: Unit = new Unit();
    public newLog: VisitationLog;
    public viewLog: boolean = false;
    public activeLogs: VisitationLog[] = [];
    public activeMemos: Memo[] = [];
    public newContact: any = { phone: null };
    public units: Unit[] = [];
    public searchParam: string = "";
    public userCredentials: User = null;
    public newUser: User;
    public newUnitResident: User = null;
    public newEmail: string = "";
    public userType: string = "Visitor";
    public tempVisitorExpiration: Date = null;
    public temporaryVisitor: boolean = false;
    public editMode: boolean = false;
    private simpleSearchFields: string[] = [
        "address1",
        "address2",
        "contact.phone1",
        "contact.phone2",
        "owner.firstName",
        "owner.lasttName",
        "visitors.firstName",
        "visitors.lastName",
        "visitors.companyName",
        "visitors.phone1",
        "visitors.phone2",
        "residents.firstName",
        "residents.lastName",
        "residents.phone1",
        "residents.phone2",
        "contacts"
    ];

    constructor(
        private as: AuthService,
        private us: UnitService,
        private usr: UserService,
        private ls: LogsService,
        private mms: MemoService,
        private ms: BsModalService,
        private ns: NotificationService,
    ) {

    }

    ngOnInit() {
        this.getLogsForUnit();
        this.removeExpiredVisitors();
    }

    private removeExpiredVisitors() {

        let visitors = [];

        if (this.unit.hasOwnProperty('temporaryVisitors')) {

            for (let visitor of this.unit.temporaryVisitors) {

                if (!visitor.expirationDate) {
                    visitors.push(visitor);
                    continue;
                }

                let expirationDate = new Date(Utils.normalizeDate(visitor.expirationDate));
                let tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() - 1);

                if (expirationDate > tomorrow) {
                    visitors.push(visitor);
                }
            }
        }

        this.unit.temporaryVisitors = visitors;
    }

    public dropContact(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.editUser();
    }

    public suspendUnitRights(unit: Unit) {
        unit.suspendRights = !unit.suspendRights;
        this.editUser();
    }

    public sendResetPassword(unit: Unit) {

        let self = this;

        if (!unit.hasOwnProperty('contact') || unit.contact.email == null) {
            this.ns.showSimpleNotification("No registered email address");
            return;
        }

        let user = new User();
        user.email = unit.contact.email;

        this.as.sendResetPasswordEmail(user).then(res => {
            self.ns.showSimpleNotification("Reset Password email has been sent to " + unit.contact.email);
        });

    }

    public checkAccess(type: String) {

        if (this.as.loggedInUser == null) {
            return false;
        }

        if (this.as.loggedInUser.type == "SYSTEMADMIN") {
            return true;
        }

        return this.as.loggedInUser.type === type;
    }

    private isNumericInput(event: KeyboardEvent) {
        let key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };

    private isModifierKey(event: KeyboardEvent) {
        let key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    public enforceFormat(event: KeyboardEvent) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    };

    public formatToPhone(event: KeyboardEvent, model: any, key: string) {

        if (this.isModifierKey(event)) {
            return;
        }

        let phone = model[key];

        if (!phone) {
            return "";
        }

        let input = phone.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        let areaCode = input.substring(0, 3);
        let middle = input.substring(3, 6);
        let last = input.substring(6, 10);

        if (input.length > 6) {
            model[key] = `(${areaCode}) ${middle} - ${last}`;
        } else if (input.length > 3) {
            model[key] = `(${areaCode}) ${middle}`;
        } else if (input.length > 0) {
            model[key] = `(${areaCode}`;
        }

    };

    public formatDate(date: string) {
        date = Utils.normalizeDate(date);
        return Utils.formatDate(new Date(date));
    }

    public formatTimestamp(date: string) {
        date = Utils.normalizeDate(date);
        return Utils.formatDate(new Date(date), true);
    }

    public formatOrder(order: number) {
        if (order == 1) {
            return "1st";
        } else if (order == 2) {
            return "2nd";
        } else if (order == 3) {
            return "3rd";
        } else {
            return order + "th";
        }
    }

    public formatPhones(phone: string) {

        if (!phone) {
            return "";
        }

        let input = phone.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        let areaCode = input.substring(0, 3);
        let middle = input.substring(3, 6);
        let last = input.substring(6, 10);

        return `(${areaCode}) ${middle} - ${last}`;

    }

    public createUnit() {
        let self = this;

        if (!this.newUnit.address1) {
            this.ns.showNotification({ message: "Address Line 1 is required" });
            return;
        }

        if (!this.newUnit.city) {
            this.ns.showNotification({ message: "City is required" });
            return;
        }


        if (!this.newUnit.state) {
            this.ns.showNotification({ message: "State is required" });
            return;
        }


        if (!this.newUnit.zipCode) {
            this.ns.showNotification({ message: "Zip Code is required" });
            return;
        }

        this.us.modifyUnit(this.newUnit).then((unit: Unit) => {
            if (unit !== null) {
                self.units.unshift(unit);
            }
        });
    }

    public showCreateResidentInUnit() {
        this.newUnitResident = new User();
    }

    public cancelAddResidents() {
        this.newUnitResident = null;
    }

    public addResidentToUnit() {

        if (!this.newUnitResident.firstName) {
            this.ns.showNotification({ message: "First Name is required" });
            return;
        }

        if (!this.newUnitResident.lastName) {
            this.ns.showNotification({ message: "Last Name is required" });
            return;
        }

        if (!this.newUnitResident.phone1) {
            this.ns.showNotification({ message: "Main Phone is required" });
            return;
        }

        if (this.unitCreateIsMainContact == true && !this.newUnitResident.email) {
            this.ns.showNotification({ message: "Email is required" });
            return;
        }

        if (this.unitEditMode) {
            this.newUnitResident = null;
            this.unitCreateIsMainContact = false;
            this.unitEditMode = false;
            return;
        }

        if (this.unitCreateIsMainContact == true) {
            this.newUnit.contact = this.newUnitResident;
            this.unitCreateIsMainContact = false;
        }

        if (!this.newUnit.hasOwnProperty('residents')) {
            this.newUnit.residents = [];
        }

        this.newUnit.residents.push(this.newUnitResident);

        this.newUnitResident = null;
    }

    public editResidentFromUnit(resident: User) {
        this.newUnitResident = resident;
        if (this.newUnit.contact && resident.email == this.newUnit.contact.email) {
            this.unitCreateIsMainContact = true;
        }
        this.unitEditMode = true;
    }

    public removeResidentFromUnit(resident: User) {
        this.newUnit.residents.splice(this.newUnit.residents.indexOf(resident), 1);
    }

    public showCreateUnitDialog() {
        this.unitRef = this.ms.show(this.createUnitDialog);
    }

    public hideDialog(ref: BsModalRef) {
        ref.hide();
        ref = null;
    }

    public addVisitorLog() {
        let self = this;

        let unit = new Unit;
        unit.id = this.unit.id;

        this.newLog.unit = unit;

        let user = new User();
        user.id = this.as.loggedInUser.id;
        user.firstName = this.as.loggedInUser.firstName;
        user.lastName = this.as.loggedInUser.lastName;

        this.newLog.user = user;

        this.ls.modifyVisitLog(this.newLog).then((log: VisitationLog) => {
            self.activeLogs.push(log);
            self.logRef.hide();
        });
    }

    public hideLogDialog() {
        this.logRef.hide();
    }

    public showCredentialsDialog() {

        let self = this;

        if (!this.unit.contact) {
            this.ns.showSimpleNotification("Please select the primary resident");
            return;
        }

        let req = new UserRequest();
        req.andFields = {
            unitId: this.unit.id
        };

        this.newEmail = null;

        this.usr.searchUsers(req).then((users: User[]) => {
            let user = null;
            if (users.length > 0) {
                user = users[0];
                if (user.email) {
                    self.newEmail = user.email;
                }
            }
            self.userCredentials = user;
            self.credRef = this.ms.show(this.credentialsDialog);
        });

    }

    public saveCredentials() {
        let self = this;

        let user = new User();

        if (this.userCredentials == null) {
            if (this.unit.contact) {
                user.firstName = this.unit.contact.firstName;
                user.lastName = this.unit.contact.lastName;
            }
            user.unitId = this.unit.id;
            user.type = "RESIDENT"
            user.email = this.newEmail;
        } else {
            user.id = this.userCredentials.id
            user.email = this.newEmail;
            user.firstName = this.userCredentials.firstName;
            user.lastName = this.userCredentials.lastName;
        }

        let unit = new Unit();
        unit.id = this.unit.id;

        this.usr.modifyUser(user).then((user: User) => {
            if (user !== null) {
                if (self.userCredentials == null && self.unit.contact == null) {
                    self.unit.contact = user;
                } else {
                    self.unit.contact.email = user.email;
                }
                
                self.as.loggedInUser.firstName = user.firstName;
                self.as.loggedInUser.lastName = user.lastName;

                self.credRef.hide();
                self.editUser();
            }
        });

    }

    public showLogDialog(log?: VisitationLog) {

        if (log) {
            this.newLog = log;
            this.viewLog = true;
        } else {
            this.newLog = new VisitationLog();
            this.viewLog = false;
        }

        this.logRef = this.ms.show(this.visitorLogDialog);
    }

    public removeLog(event: MouseEvent, log: VisitationLog) {

        event.stopPropagation();

        let self = this;

        if (this.unit == null) {
            return;
        }

        this.ls.removeLog(log).then(res => {
            if (res == true) {
                self.activeLogs.splice(self.activeLogs.indexOf(log), 1);
            }
        });

    }

    public removeMemo(memo: Memo) {

        let self = this;

        if (this.unit == null) {
            return;
        }

        this.mms.removeMemo(memo).then(res => {
            if (res == true) {
                self.activeMemos.splice(self.activeMemos.indexOf(memo), 1);
            }
        });

    }

    public addContactToUnit(contact: string) {
        let self = this;

        if (this.unit == null) {
            return;
        }

        let str = Utils.escapeStr(contact);
        if (str.length !== 10) {
            this.ns.showNotification({ message: "Contact must be a valid number" });
            return;
        }

        this.unit.contacts.push(str);
        this.editUser().then(res => {
            self.newContact.phone = null;
            self.contactRef.hide();
        });
    }

    public removeContact(contact: string) {
        if (this.unit == null) {
            return;
        }
        this.unit.contacts.splice(this.unit.contacts.indexOf(contact), 1);
        this.editUser();
    }

    public addMemoToUnit(message: string) {

        let self = this;

        if (this.unit == null) {
            return;
        }

        let unit = new Unit;
        unit.id = this.unit.id;

        let user = new User;
        user.id = this.as.loggedInUser.id;
        user.firstName = this.as.loggedInUser.firstName;
        user.lastName = this.as.loggedInUser.lastName;

        let memo = new Memo();
        memo.message = message;
        memo.unit = unit;
        memo.user = user;

        this.mms.modifyMemo(memo).then((memo: Memo) => {
            self.activeMemos.push(memo);
            self.memoRef.hide();
        });

    }

    public editUser() {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {

            for (let v of self.unit.temporaryVisitors) {
                if (v.expirationDate) {
                    v.expirationDate = Utils.normalizeDate(v.expirationDate);
                }
            }

            self.us.modifyUnit(self.unit).then((unit: Unit) => {
                if (unit !== null) {
                    if (self.modalRef !== null) {
                        self.modalRef.hide();
                    }
                    self.editMode = false;
                }
                resolve(true);
            });
        });
        return promise;
    }

    public removeUser(event: MouseEvent, user: User, type: string, isTemporary?: boolean) {

        let self = this;

        event.stopPropagation();

        if (this.unit == null) {
            return;
        }
        let mes = "Are you sure you want to remove " + user.firstName + " " + user.lastName + "?";
        this.confirmationModal.show("Remove " + type, mes).then(res => {
            if (res == true) {
                if (type == "Owner") {
                    delete self.unit.owner;
                } else {
                    let target = self.unit.residents;
                    if (type == "Visitor") {
                        target = self.unit.visitors;
                        if (isTemporary && isTemporary == true) {
                            target = self.unit.temporaryVisitors;
                        }
                    }
                    target.splice(target.indexOf(user), 1);

                    if (type == "Resident" && self.isUserPrimaryContact(user)) {
                        if (self.unit.residents.length > 0) {
                            self.unit.contact = self.unit.residents[0];
                        } else {
                            self.unit.contact = null;
                        }
                    }

                }

                self.editUser();
            }
        });
    }

    private isUserPrimaryContact(user: User) {

        if ((this.unit.contact.firstName == user.firstName) &&
            (this.unit.contact.lastName == user.lastName)) {
            return true;
        }

        return false;

    }

    public addUserToUnit() {
        let self = this;

        if (this.unit == null) {
            return;
        }

        let tempUser = new User();

        if (!this.newUser.firstName && this.newUser.relationship !== "Company") {
            this.ns.showNotification({ message: "First Name is required" });
            return;
        } else {
            tempUser.firstName = this.newUser.firstName;
        }

        if (!this.newUser.lastName && this.newUser.relationship !== "Company") {
            this.ns.showNotification({ message: "Last Name is required" });
            return;
        } else {
            tempUser.lastName = this.newUser.lastName;
        }

        if (this.newUser.relationship) {
            tempUser.relationship = this.newUser.relationship;
        }

        if (this.tempVisitorExpiration && this.userType == "Visitor") {
            tempUser.expirationDate = Utils.formatDate(this.tempVisitorExpiration);
        }

        if (this.newUser.relationship == "Company" && !this.newUser.companyName) {
            this.ns.showNotification({ message: "Company Name is required" });
            return;
        } else {
            tempUser.companyName = this.newUser.companyName;
        }

        if (this.userType == "Owner") {

            if (!this.newUser.phone1) {
                this.ns.showNotification({ message: "Phone number is required" });
                return;
            }

            let str = Utils.escapeStr(this.newUser.phone1);
            if (str.length !== 10) {
                this.ns.showNotification({ message: "Invalid phone number" });
                return;
            }
            tempUser.phone1 = str;
            this.unit.owner = tempUser;
        }

        if (this.newUser.primary == true) {
            tempUser.primary = true;
            this.unit.contact = this.newUser;
        }

        if (this.newUser.courtesyCall == true) {
            tempUser.courtesyCall = true;
        }

        if (this.editMode == true) {
            if (this.userType == "Resident") {
                let index = null;
                for (let i in this.unit.residents) {
                    let res = this.unit.residents[i];
                    if ((res.firstName == tempUser.firstName) && (res.lastName == tempUser.lastName)) {
                        index = i;
                        break;
                    }
                }
                if (index !== null) {
                    if (this.newUser.primary == true) {
                        this.unit.residents.splice(index, 1);
                        for (let u of this.unit.residents) {
                            u.primary = false;
                        }
                        this.unit.residents.unshift(tempUser);
                    } else {
                        this.unit.residents[index] = tempUser;
                    }
                }
            } else {
                if (this.temporaryVisitor == true) {
                    let visIndex = null;
                    for (let i in this.unit.temporaryVisitors) {
                        let temp = this.unit.temporaryVisitors[i];
                        if ((temp.firstName == tempUser.firstName) && (temp.lastName == tempUser.lastName)) {
                            visIndex = i;
                            break;
                        }
                    }
                    if (visIndex !== null) {
                        this.unit.temporaryVisitors[visIndex] = tempUser;
                    }
                }
            }

            this.editUser();
            return;
        }

        if (this.userType == "Visitor") {
            if (this.temporaryVisitor == true) {
                this.unit.temporaryVisitors.push(tempUser);
            } else {
                this.unit.visitors.push(tempUser);
            }
        } else if (this.userType == "Resident") {
            if (this.newUser.primary == true) {
                this.unit.residents.unshift(tempUser);
            } else {
                this.unit.residents.push(tempUser);
            }

            if (!this.unit.contact && this.unit.residents.length > 0) {
                this.unit.contact = this.unit.residents[0];
            }
        }

        for (let v of this.unit.temporaryVisitors) {
            if (v.expirationDate) {
                v.expirationDate = Utils.normalizeDate(v.expirationDate);
            }
        }

        this.us.modifyUnit(this.unit).then(res => {
            self.modalRef.hide();
        });
    }

    public onCalShow(event: any) {
        const dayHoverHandler = event.dayHoverHandler;
        const hoverWrapper = ($event: any) => {
            const cell = $event;
            cell.isHovered = false;
            return dayHoverHandler($event);
        };
        event.dayHoverHandler = hoverWrapper;
    }

    public showMemoDialog(event: MouseEvent) {
        event.stopPropagation();
        this.memoRef = this.ms.show(this.memoDialog);
    }

    public showContactDialog(event: MouseEvent) {
        event.stopPropagation();
        this.contactRef = this.ms.show(this.contactDialog);
    }

    public showCreateDialog(event: MouseEvent, userType: string, user: User, isTemporary?: boolean) {

        let self = this;

        event.stopPropagation();

        // this.tempVisitorExpiration = new Date();

        this.userType = userType;

        // if (isTemporary && isTemporary == true) {
        //     this.temporaryVisitor = true;
        // } else {
        //     this.temporaryVisitor = false;
        // }

        // if (user == null) {
        //     this.newUser = new User();
        //     this.editMode = false;
        // } else {
        //     this.newUser = user;
        //     if (user.phone1) {
        //         this.newUser.phone1 = this.formatPhones(this.newUser.phone1);
        //     }
        //     if (user.expirationDate) {
        //         let date = Utils.normalizeDate(user.expirationDate);
        //         this.tempVisitorExpiration = new Date(date);
        //     }
        //     this.editMode = true;
        // }
        // this.modalRef = this.ms.show(this.createUserDialog);

        this.createUserDialog.show(this.unit, user, isTemporary).then((unit: Unit) => {
            if (unit !== null) {                
                self.us.setSelectedUnit(unit);
            }
        });

    }

    public hideCreateDialog() {
        this.newUser = new User();
        this.modalRef.hide();
        this.editMode = false;
    }

    private getMemosForUnit(unit: Unit) {

        let self = this;

        let req = new MemoRequest();
        req.start = 0;
        req.count = Constants.ITEMS_PER_REQUEST;
        req.andFields = { "unit.id": unit.id };
        req.andClauses = true;

        this.mms.searchMemos(req).then((memos: Memo[]) => {
            self.activeMemos = memos;
        });
    }

    private getLogsForUnit() {

        let self = this;

        let req = new VisitationLogRequest();
        req.start = 0;
        req.count = Constants.ITEMS_PER_REQUEST;
        req.andFields = { "unit.id": this.unit.id };
        req.andClauses = true;

        this.ls.searchVisitLogs(req).then((logs: VisitationLog[]) => {
            self.activeLogs = logs;
        });
    }

    public toggleAccordion(key: number) {
        if (this.activeAccordion == key) {
            this.activeAccordion = null;
        } else {
            this.activeAccordion = key;
        }
    }

    public autoSearch(event: KeyboardEvent) {
        if (this.searchParam.length > 2) {
            this.searchUnits();
        } else if (this.searchParam.length == 0) {
            this.searchUnits();
        }
    }

    public searchUnits() {

        let self = this;

        let req = new UnitRequest();
        req.orFields = {};

        let searchTerm = Utils.escapeStr(this.searchParam);

        for (let field of this.simpleSearchFields) {
            req.orFields[field] = [searchTerm];
        }

        req.ors = this.simpleSearchFields;

        this.us.searchUnits(req).then((units: Unit[]) => {
            self.units = units;
        });
    }


    get unit() {
        return this.us.selectedUnit;
    }
}

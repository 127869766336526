import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UnitRequest } from '../../../../models/request/UnitRequest';
import { Unit } from '../../../../models/Unit';
import { User } from '../../../../models/User';
import { ModalService } from '../../../../services/modal.service';
import { UnitService } from '../../../../services/unit.service';
import { BatchUserModalComponent } from '../../../../shared/modals/batch-user-modal/batch-user-modal.component';
import { UnitModal } from '../../../../shared/modals/unit-modal/unit-modal.component';

@Component({
	selector: 'app-units-admin',
	templateUrl: './units-admin.component.html'
})
export class UnitsAdminComponent implements OnInit {

	@Input() isCard: boolean;

	@ViewChild('unitModal') unitModal: UnitModal;
	@ViewChild('batchUserModal') batchUserModal: BatchUserModalComponent;

	public units: Unit[];
	public showMore: boolean;

	constructor(
		private unitService: UnitService,
		private modalService: ModalService
	) {
		this.units = [];
		this.isCard = false;
		this.showMore = false;
	}

	ngOnInit() {
		this.loadUnits();
	}

	public loadUnits() {

		let req = new UnitRequest();
		req.fetchAll = true;

		this.unitService.searchUnits(req).then((units: Unit[]) => {
			this.units = units;
		});
	}

	public getContactName(unit: Unit) {

		let name = "N/A";

		let contact = unit.contact;
		if (!contact && unit.residents) {
			contact = unit.residents[0];
		}

		if (contact) {
			name = this.parseContactName(contact)
		}

		return name;
	}

	private parseContactName(contact: User) {

		let name = "";

		if (contact.firstName) {
			name = contact.firstName;
		}

		if (contact.lastName) {
			if (name) {
				name += " ";
			}
			name += contact.lastName;
		}

		return name;
	}

	public showCreatBatchUser() {
		this.batchUserModal.show().then(res => {

		});
	}

	public showCreateUnit(event: MouseEvent, unit?: Unit) {

		event.stopPropagation();

		this.unitModal.show(unit).then((response: Unit) => {
			if (response && !unit) {
				this.units.unshift(response);
			}
		});
	}

	public removeUnit(event: MouseEvent, unit: Unit) {

		event.stopPropagation();

		let mes = `Are you sure you want to remove ${unit.address1}?`;

		this.modalService.showConfirmationModal("Remove Unit", mes).then(res => {
			if (res == true) {
				this.unitService.removeUnit(unit).then(res => {
					if (res == true) {
						this.units.splice(this.units.indexOf(unit), 1);
					}
				});
			}
		});
	}

}

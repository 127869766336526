import { Component, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { License } from '../../../../models/License';
import { Machine } from '../../../../models/Machine';
import { LicenseRequest } from '../../../../models/request/LicenseRequest';
import { CommunityService } from '../../../../services/community.service';
import { ModalService } from '../../../../services/modal.service';
import { CommunityLicenseModal } from '../../../../shared/modals/community-license-modal/community-license-modal.component';

@Component({
	selector: 'app-community-licenses',
	templateUrl: './community-licenses.component.html'
})
export class CommunityLicensesComponent implements OnInit {

	@Input() isCard: boolean;

	@ViewChild("addLicense") addLicenseModal: CommunityLicenseModal;
	public license: License;

	constructor(
		private communityService: CommunityService,
		private modalService: ModalService
	) {
		this.license = new License();
		this.isCard = false;
	}

	ngOnInit() {
		this.getCommunityLicense();
	}

	private getCommunityLicense() {

		let req = new LicenseRequest();
		req.communityId = this.communityService.selectedCommunity.id;

		this.communityService.getCommunityLicense(req).then((license: License) => {			
			this.license = license;
		});
	}

	public addCommunityLicense() {
		this.addLicenseModal.show(this.license).then(res => {
			console.log(res);
		});
	}

	public removeMachine(machine: Machine) {

		let mes = `Are you sure you want to remove ${machine.name}?`;
		this.modalService.showConfirmationModal("Remove Machine", mes).then(response => {
			if (response == true) {

				this.license.machines.splice(this.license.machines.indexOf(machine), 1);
				this.communityService.modifyCommunityLicense(this.license).then((license: License) => {
					if (license !== null) {
						this.license = license;
					}
				});
			}
		});
	}

}

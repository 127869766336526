import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { UserRegistrationComponent } from "./pages/register/register.component";
import { NoAccessComponent } from "./pages/noaccess/noaccess.component";
import { CommunityResolver } from "./services/resolver/community-resolver.service";

const routes: Routes = [
    {
        path: "",
        redirectTo: "/login",
        pathMatch: "full"
    },
    {
        path: ":communityUrl/login",
        component: LoginComponent,
        resolve: {
            community: CommunityResolver
        }
    },
    {
        path: "login",
        component: LoginComponent
    },
    {
        path: "activate/:communityId/:validationCode/:userId",
        component: LoginComponent,
        resolve: {
            community: CommunityResolver
        }
    },
    {
        path: "register/:regId",
        component: UserRegistrationComponent
    },    
    {
        path: "noaccess",
        component: NoAccessComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [CommunityResolver]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
// import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../environments/environment';

declare var AWS: any;

@Injectable({
	providedIn: 'root'
})
export class UploadService {

	constructor() { }

	uploadFile(file: File, filename: string) {
		return new Promise<any>((resolve, reject) => {

			const bucket = new AWS.S3({
				accessKeyId: environment.awsAccessKeyId,
				secretAccessKey: environment.awsSecretKey,
				region: "us-east-2"
			});

			const params = {
				Bucket: "visitor-axis-logos-bucket",
				Key: filename,
				Body: file,
				ACL: "public-read",
				ContentType: file.type
			};

			bucket.upload(params, (err: Error, data: any) => {

				let location = null;

				if (err) {
					console.log(`There was an error uploading file: ${err}`);
					resolve(location);
					return;
				}

				if (data.Location) {
					location = data.Location;
				}

				resolve(location);
			});
		});
	}
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ResidentsComponent } from "./resident.component";
import { ResidentGuard } from "../../services/guards/resident-guard.service";
import { CommunityResolver } from "../../services/resolver/community-resolver.service";

const mainRoutes: Routes = [
    {
        path: ":communityUrl/residents/:unitId",
        component: ResidentsComponent,
        canActivate: [ResidentGuard],
        resolve: {
            community: CommunityResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(mainRoutes)],
    exports: [RouterModule],
    providers: [ResidentGuard, CommunityResolver]
})
export class ResidentsRouting { }

import { ScannedDocument } from "./ScannedDocument";
import { Unit } from "./Unit";
import { User } from "./User";

export class VisitationLog {
    id: string;
    unit: Unit;
    user: User;
    creationDate: string;
    firstName: string;
    lastName: string;
    company: string;
    tag: string;
    vehicleType: string;
    make: string;
    model: string;
    color: string;
    scannedData?: string;
}
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NotificationsComponent } from "./notifications/notifications.component";
import { NavBarComponent } from "./navigation/navigation.component";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NavigationService } from "./navigation/navigation.service";
import { SpinnerComponent } from "./spinner/spinner.component";
import { ConfirmationModal } from "./modals/confirmation-modal/confirmation-modal.component";
import { BaseModal } from "./modals/base/base-modal.component";
import { UserModal } from "./modals/user-modal/user-modal.component";
import { UsersComponent } from "./users/users.component";
import { ModalService } from "../services/modal.service";
import { CommunityModal } from "./modals/community-modal/community-modal.component";
import { UnitModal } from "./modals/unit-modal/unit-modal.component";
import { LogModal } from "./modals/log-modal/log-modal.component";
import { SpinnerService } from "./spinner/spinner.service";
import { UnitUsersModal } from "./modals/unit-users-modal/unit-users-modal.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { MemoModal } from "./modals/memo/memo.component";
import { CommunityImportModal } from './modals/community-import-modal/community-import-modal.component';
import { CommunityImportService } from "../services/community-import.service";
import { BatchUserModalComponent } from './modals/batch-user-modal/batch-user-modal.component';
import { CommunityUserModalComponent } from './modals/community-user-modal/community-user-modal.component';
import { CommunityLicenseModal } from './modals/community-license-modal/community-license-modal.component';
import { EmailTemplateModal } from './modals/email-template-modal/email-template-modal.component';
import { UserCredentialsModal } from './modals/user-credentials-modal/user-credentials-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot()
    ],
    declarations: [
        NotificationsComponent,
        NavBarComponent,
        SpinnerComponent,
        UsersComponent,
        BaseModal,
        ConfirmationModal,
        UserModal,
        CommunityModal,
        UnitModal,
        UnitUsersModal,
        LogModal,
        MemoModal,
        CommunityImportModal,
        BatchUserModalComponent,
        CommunityUserModalComponent,
        CommunityLicenseModal,
        EmailTemplateModal,
        UserCredentialsModal
    ],
    exports: [
        NotificationsComponent,
        NavBarComponent,
        SpinnerComponent,
        UsersComponent,
        BaseModal,
        ConfirmationModal,
        UserModal,
        CommunityModal,
        UnitModal,
        UnitUsersModal,
        LogModal,
        MemoModal,
        CommunityImportModal,
        BatchUserModalComponent,
        CommunityUserModalComponent,
        CommunityLicenseModal,
        EmailTemplateModal,
        UserCredentialsModal
    ],
    providers: [
        NavigationService,
        ModalService,
        SpinnerService,
        CommunityImportService
    ]
})
export class SharedModule { }

import { Component, OnInit, TemplateRef, ViewChild, HostListener } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { UnitService } from "../../../services/unit.service";
import { UnitRequest } from "../../../models/request/UnitRequest";
import { Unit } from "../../../models/Unit";
import { User } from "../../../models/User";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { VisitationLog } from "../../../models/VisitationLog";
import { LogsService } from "../../../services/logs.service";
import { VisitationLogRequest } from "../../../models/request/VisitationLogRequest";
import { NotificationService } from "../../../services/notifications.service";
import { AuthService } from "../../../services/auth.service";
import { Constants } from "../../../utils/Constants";
import { Utils } from "../../../utils/Utils"
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker/public_api";
import { MemoService } from "../../../services/memo.service";
import { MemoRequest } from "../../../models/request/MemoRequest";
import { Memo } from "../../../models/Memo";
import { UserService } from "../../../services/user.service";
import { UserRequest } from "../../../models/request/UserRequest";
import { ConfirmationModal } from "../../../shared/modals/confirmation-modal/confirmation-modal.component";
import { UnitUsersModal } from "../../../shared/modals/unit-users-modal/unit-users-modal.component";
import { LogModal } from "../../../shared/modals/log-modal/log-modal.component";
import { BatchUserModalComponent } from "../../../shared/modals/batch-user-modal/batch-user-modal.component";
import { UserCredentialsModal } from "../../../shared/modals/user-credentials-modal/user-credentials-modal.component";
import { SpinnerService } from "../../../shared/spinner/spinner.service";
import { UnitRequestBuilder } from "../../../utils/UnitRequestBuilder";

@Component({
    selector: "va-admin-units",
    templateUrl: "./units.component.html"
})
export class AdminUnitsComponent implements OnInit {

    @ViewChild('confirmationModal') confirmationModal: ConfirmationModal;
    @ViewChild('addLog') visitorLogDialog: LogModal;
    @ViewChild('batchUserModal') batchUserModal: BatchUserModalComponent;
    @ViewChild('credentialsDialog') credentialsDialog: UserCredentialsModal;

    @ViewChild('createVisitorDialog') createUserDialog: UnitUsersModal;
    public modalRef: BsModalRef = null;


    @ViewChild('addContact') contactDialog: TemplateRef<any>;
    public contactRef: BsModalRef = null;

    @ViewChild('addMemo') memoDialog: TemplateRef<any>;
    public memoRef: BsModalRef = null;

    public bsConfig: Partial<BsDatepickerConfig> = {
        containerClass: "theme-default",
        dateInputFormat: 'MM-DD-YYYY',
        selectFromOtherMonth: true
    };

    public actionsMenu: boolean = false;
    public unitCreateIsMainContact: boolean = false;
    private unitEditMode: boolean = false;
    public newUnit: Unit = new Unit();
    public newLog: VisitationLog;
    public viewLog: boolean = false;
    public activeLogs: VisitationLog[] = [];
    public activeMemos: Memo[] = [];
    public newContact: any = { phone: null };
    public units: Unit[] = [];
    public searchParam: string = "";
    public userCredentials: User = null;
    public newUser: User;
    public newUnitResident: User = null;
    public userType: string = "Visitor";
    public tempVisitorExpiration: Date = null;
    public temporaryVisitor: boolean = false;
    public editMode: boolean = false;
    private fetching: boolean = false;
    private autoSearchInterval: any;

    constructor(
        private bsModalService: BsModalService,
        private authService: AuthService,
        private unitService: UnitService,
        private userService: UserService,
        private logsService: LogsService,
        private memoService: MemoService,
        private notificastionService: NotificationService,
        private spinnerService: SpinnerService
    ) {

    }

    ngOnInit() {
        this.searchUnits();
    }

    @HostListener('document:keydown.escape', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.searchParam = "";
        this.searchUnits();
    }

    public dropContact(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.editUser();
    }

    public suspendUnitRights(unit: Unit) {
        unit.suspendRights = !unit.suspendRights;
        this.editUser();
    }

    public sendResetPassword(unit: Unit) {

        let self = this;

        if (!unit.hasOwnProperty('contact') || unit.contact.email == null) {
            this.notificastionService.showSimpleNotification("No registered email address");
            return;
        }

        let user = new User();
        user.email = unit.contact.email;

        this.authService.sendResetPasswordEmail(user).then(res => {
            self.notificastionService.showSimpleNotification("Reset Password email has been sent to " + unit.contact.email);
        });

    }

    public showCreatBatchUser() {
        this.batchUserModal.show().then(res => {
        });
    }

    public checkAccess(type: String) {

        if (this.authService.loggedInUser == null) {
            return false;
        }

        if (this.authService.loggedInUser.type == "SYSTEMADMIN") {
            return true;
        }

        return this.authService.loggedInUser.type === type;
    }

    private isNumericInput(event: KeyboardEvent) {
        let key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };

    private isModifierKey(event: KeyboardEvent) {
        let key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    public enforceFormat(event: KeyboardEvent) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    };

    public formatToPhone(event: KeyboardEvent, model: any, key: string) {

        if (this.isModifierKey(event)) {
            return;
        }

        let phone = model[key];

        if (!phone) {
            return "";
        }

        let input = phone.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        let areaCode = input.substring(0, 3);
        let middle = input.substring(3, 6);
        let last = input.substring(6, 10);

        if (input.length > 6) {
            model[key] = `(${areaCode}) ${middle} - ${last}`;
        } else if (input.length > 3) {
            model[key] = `(${areaCode}) ${middle}`;
        } else if (input.length > 0) {
            model[key] = `(${areaCode}`;
        }

    };

    public formatDate(date: string) {
        return Utils.formatDate(new Date(date));
    }

    public formatTimestamp(date: string) {
        return Utils.formatDate(new Date(date), true);
    }

    public formatOrder(order: number) {
        if (order == 1) {
            return "1st";
        } else if (order == 2) {
            return "2nd";
        } else if (order == 3) {
            return "3rd";
        } else {
            return order + "th";
        }
    }

    public formatPhones(phone: string) {
        return Utils.formatPhones(phone);
    }

    public createUnit() {
        let self = this;

        if (!this.newUnit.address1) {
            this.notificastionService.showNotification({ message: "Address Line 1 is required" });
            return;
        }

        if (!this.newUnit.city) {
            this.notificastionService.showNotification({ message: "City is required" });
            return;
        }


        if (!this.newUnit.state) {
            this.notificastionService.showNotification({ message: "State is required" });
            return;
        }


        if (!this.newUnit.zipCode) {
            this.notificastionService.showNotification({ message: "Zip Code is required" });
            return;
        }

        this.unitService.modifyUnit(this.newUnit).then((unit: Unit) => {
            if (unit !== null) {
                self.units.unshift(unit);
            }
        });
    }

    public showCreateResidentInUnit() {
        this.newUnitResident = new User();
    }

    public cancelAddResidents() {
        this.newUnitResident = null;
    }

    public addResidentToUnit() {

        if (!this.newUnitResident.firstName) {
            this.notificastionService.showNotification({ message: "First Name is required" });
            return;
        }

        if (!this.newUnitResident.lastName) {
            this.notificastionService.showNotification({ message: "Last Name is required" });
            return;
        }

        if (!this.newUnitResident.phone1) {
            this.notificastionService.showNotification({ message: "Main Phone is required" });
            return;
        }

        if (this.unitCreateIsMainContact == true && !this.newUnitResident.email) {
            this.notificastionService.showNotification({ message: "Email is required" });
            return;
        }

        if (this.unitEditMode) {
            this.newUnitResident = null;
            this.unitCreateIsMainContact = false;
            this.unitEditMode = false;
            return;
        }

        if (this.unitCreateIsMainContact == true) {
            this.newUnit.contact = this.newUnitResident;
            this.unitCreateIsMainContact = false;
        }

        if (!this.newUnit.hasOwnProperty('residents')) {
            this.newUnit.residents = [];
        }

        this.newUnit.residents.push(this.newUnitResident);

        this.newUnitResident = null;
    }

    public editResidentFromUnit(resident: User) {
        this.newUnitResident = resident;
        if (this.newUnit.contact && resident.email == this.newUnit.contact.email) {
            this.unitCreateIsMainContact = true;
        }
        this.unitEditMode = true;
    }

    public removeResidentFromUnit(resident: User) {
        this.newUnit.residents.splice(this.newUnit.residents.indexOf(resident), 1);
    }

    public hideDialog(ref: BsModalRef) {
        ref.hide();
        ref = null;
    }

    public showCredentialsDialog() {

        if (!this.selectedUnit.contact) {
            this.notificastionService.showSimpleNotification("Please select the primary resident");
            return;
        }

        this.spinnerService.showSpinner("mainSpinner");

        let req = new UserRequest();
        req.andFields = {
            unitId: this.selectedUnit.id
        };

        this.userService.searchUsers(req).then((users: User[]) => {

            let user = null;

            if (users.length > 0) {
                user = users[0];
            }

            this.spinnerService.hideSpinner("mainSpinner");

            this.credentialsDialog.show(user).then(res => {
                if (res == true) {
                    this.editUser();
                }
            });

        });

    }

    public showLogDialog(log?: VisitationLog) {

        let self = this;

        let tempLog = null;
        if (log) {
            tempLog = log;
        }

        this.visitorLogDialog.showLogDialog(this.selectedUnit, tempLog).then(res => {
            if (res !== null) {
                self.activeLogs.push(res);
            }
        });

    }

    public removeLog(event: MouseEvent, log: VisitationLog) {

        event.stopPropagation();

        let self = this;

        if (this.selectedUnit == null) {
            return;
        }

        this.logsService.removeLog(log).then(res => {
            if (res == true) {
                self.activeLogs.splice(self.activeLogs.indexOf(log), 1);
            }
        });

    }

    public removeMemo(memo: Memo) {

        let self = this;

        if (this.selectedUnit == null) {
            return;
        }

        this.memoService.removeMemo(memo).then(res => {
            if (res == true) {
                self.activeMemos.splice(self.activeMemos.indexOf(memo), 1);
            }
        });

    }

    public addContactToUnit(contact: string) {
        let self = this;

        if (this.selectedUnit == null) {
            return;
        }

        let str = Utils.escapeStr(contact);
        if (str.length !== 10) {
            this.notificastionService.showNotification({ message: "Contact must be a valid number" });
            return;
        }

        this.selectedUnit.contacts.push(str);
        this.editUser().then(res => {
            self.newContact.phone = null;
            self.contactRef.hide();
        });
    }

    public removeContact(contact: string) {
        if (this.selectedUnit == null) {
            return;
        }
        this.selectedUnit.contacts.splice(this.selectedUnit.contacts.indexOf(contact), 1);
        this.editUser();
    }

    public addMemoToUnit(message: string) {

        let self = this;

        if (this.selectedUnit == null) {
            return;
        }

        let unit = new Unit;
        unit.id = this.selectedUnit.id;

        let user = new User;
        user.id = this.authService.loggedInUser.id;
        user.firstName = this.authService.loggedInUser.firstName;
        user.lastName = this.authService.loggedInUser.lastName;

        let memo = new Memo();
        memo.message = message;
        memo.unit = unit;
        memo.user = user;

        this.memoService.modifyMemo(memo).then((memo: Memo) => {
            self.activeMemos.push(memo);
            self.memoRef.hide();
        });

    }

    public editUser() {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self.unitService.modifyUnit(this.selectedUnit).then((unit: Unit) => {
                if (unit !== null) {
                    if (self.modalRef !== null) {
                        self.modalRef.hide();
                    }
                    self.editMode = false;
                }
                resolve(true);
            });
        });
        return promise;
    }

    public removeUser(event: MouseEvent, user: User, type: string, isTemporary?: boolean) {

        let self = this;

        event.stopPropagation();

        if (this.selectedUnit == null) {
            return;
        }
        let mes = "Are you sure you want to remove " + user.firstName + " " + user.lastName + "?";
        this.confirmationModal.show("Remove " + type, mes).then(res => {
            if (res == true) {
                if (type == "Owner") {
                    delete self.selectedUnit.owner;
                } else {
                    let target = self.selectedUnit.residents;
                    if (type == "Visitor") {
                        target = self.selectedUnit.visitors;
                        if (isTemporary && isTemporary == true) {
                            target = self.selectedUnit.temporaryVisitors;
                        }
                    }
                    target.splice(target.indexOf(user), 1);

                    if (type == "Resident" && self.isUserPrimaryContact(user)) {
                        if (self.selectedUnit.residents.length > 0) {
                            self.selectedUnit.contact = self.selectedUnit.residents[0];
                        } else {
                            self.selectedUnit.contact = null;
                        }
                    }

                }

                self.editUser();
            }
        });
    }

    private isUserPrimaryContact(user: User) {

        if ((this.selectedUnit.contact.firstName == user.firstName) &&
            (this.selectedUnit.contact.lastName == user.lastName)) {
            return true;
        }

        return false;

    }

    public addUserToUnit() {
        let self = this;

        if (this.selectedUnit == null) {
            return;
        }

        let tempUser = new User();

        if (!this.newUser.firstName) {
            this.notificastionService.showNotification({ message: "First Name is required" });
            return;
        } else {
            tempUser.firstName = this.newUser.firstName;
        }

        if (!this.newUser.lastName) {
            this.notificastionService.showNotification({ message: "Last Name is required" });
            return;
        } else {
            tempUser.lastName = this.newUser.lastName;
        }

        if (this.newUser.relationship) {
            tempUser.relationship = this.newUser.relationship;
        }

        if (this.tempVisitorExpiration) {
            tempUser.expirationDate = Utils.formatDate(this.tempVisitorExpiration);
        }

        if (this.newUser.relationship == "Company" && !this.newUser.companyName) {
            this.notificastionService.showNotification({ message: "Company Name is required" });
            return;
        } else {
            tempUser.companyName = this.newUser.companyName;
        }

        if (this.userType == "Owner") {

            if (!this.newUser.phone1) {
                this.notificastionService.showNotification({ message: "Phone number is required" });
                return;
            }

            let str = Utils.escapeStr(this.newUser.phone1);
            if (str.length !== 10) {
                this.notificastionService.showNotification({ message: "Invalid phone number" });
                return;
            }
            tempUser.phone1 = str;
            this.selectedUnit.owner = tempUser;
        }

        if (this.newUser.primary == true) {
            tempUser.primary = true;
            this.selectedUnit.contact = this.newUser;
        }

        if (this.newUser.courtesyCall == true) {
            tempUser.courtesyCall = true;
        }

        if (this.editMode == true) {
            if (this.userType == "Resident") {
                let index = null;
                for (let i in this.selectedUnit.residents) {
                    let res = this.selectedUnit.residents[i];
                    if ((res.firstName == tempUser.firstName) && (res.lastName == tempUser.lastName)) {
                        index = i;
                        break;
                    }
                }
                if (index !== null) {
                    if (this.newUser.primary == true) {
                        this.selectedUnit.residents.splice(index, 1);
                        this.selectedUnit.residents.unshift(tempUser);
                    } else {
                        this.selectedUnit.residents[index] = tempUser;
                    }
                }
            } else {
                if (this.temporaryVisitor == true) {
                    let visIndex = null;
                    for (let i in this.selectedUnit.temporaryVisitors) {
                        let temp = this.selectedUnit.temporaryVisitors[i];
                        if ((temp.firstName == tempUser.firstName) && (temp.lastName == tempUser.lastName)) {
                            visIndex = i;
                            break;
                        }
                    }
                    if (visIndex !== null) {
                        this.selectedUnit.temporaryVisitors[visIndex] = tempUser;
                    }
                }
            }

            this.editUser();
            return;
        }

        if (this.userType == "Visitor") {
            if (this.temporaryVisitor == true) {
                this.selectedUnit.temporaryVisitors.push(tempUser);
            } else {
                this.selectedUnit.visitors.push(tempUser);
            }
        } else if (this.userType == "Resident") {
            if (this.newUser.primary == true) {
                this.selectedUnit.residents.unshift(tempUser);
            } else {
                this.selectedUnit.residents.push(tempUser);
            }

            if (!this.selectedUnit.contact && this.selectedUnit.residents.length > 0) {
                this.selectedUnit.contact = this.selectedUnit.residents[0];
            }
        }

        this.unitService.modifyUnit(this.selectedUnit).then(res => {
            self.modalRef.hide();
        });
    }

    public showMemoDialog(event: MouseEvent) {
        event.stopPropagation();
        this.memoRef = this.bsModalService.show(this.memoDialog);
    }

    public showContactDialog(event: MouseEvent) {
        event.stopPropagation();
        this.contactRef = this.bsModalService.show(this.contactDialog);
    }

    public showCreateDialog(event: MouseEvent, userType: string, user: User, isTemporary?: boolean) {

        let self = this;

        event.stopPropagation();

        this.userType = userType;

        this.createUserDialog.show(this.selectedUnit, user, isTemporary).then((unit: Unit) => {
            if (unit) {
                for (let i in unit) {
                    self.selectedUnit[i] = unit[i];
                }
            }
        });

        // if (isTemporary && isTemporary == true) {
        //     this.temporaryVisitor = true;
        // } else {
        //     this.temporaryVisitor = false;
        // }

        // if (user == null) {
        //     this.newUser = new User();
        //     this.editMode = false;
        // } else {
        //     this.newUser = user;
        //     if (user.phone1) {
        //         this.newUser.phone1 = this.formatPhones(this.newUser.phone1);
        //     }
        //     if (user.expirationDate) {
        //         this.tempVisitorExpiration = new Date(user.expirationDate);
        //     }
        //     this.editMode = true;
        // }
        // this.modalRef = this.ms.show(this.createUserDialog);
    }

    public hideCreateDialog() {
        this.newUser = new User();
        this.modalRef.hide();
        this.editMode = false;
    }

    public showUnitDetails(unit: Unit) {

        if (this.selectedUnit === unit) {
            return;
        }

        this.activeLogs = [];
        this.activeMemos = [];

        for (let unit of this.units) {
            unit.showDetails = false;
        }

        unit.showDetails = true;
        this.unitService.setSelectedUnit(unit);
        this.getLogsForUnit(unit);
        this.getMemosForUnit(unit);
    }

    private getMemosForUnit(unit: Unit) {

        let self = this;

        let req = new MemoRequest();
        req.start = 0;
        req.count = Constants.ITEMS_PER_REQUEST;
        req.andFields = { "unit.id": unit.id };
        req.andClauses = true;

        this.memoService.searchMemos(req).then((memos: Memo[]) => {
            self.activeMemos = memos;
        });
    }

    private getLogsForUnit(unit: Unit) {

        let self = this;

        let req = new VisitationLogRequest();
        req.start = 0;
        req.count = Constants.ITEMS_PER_REQUEST;
        req.andFields = { "unit.id": unit.id };
        req.andClauses = true;

        this.logsService.searchVisitLogs(req).then((logs: VisitationLog[]) => {
            self.activeLogs = logs;
        });
    }

    public hideUnitDetails(event: MouseEvent, unit: Unit) {
        event.stopPropagation();
        unit.showDetails = false;
        this.unitService.setSelectedUnit(null);
    }

    public autoSearch() {
        clearInterval(this.autoSearchInterval);
        this.autoSearchInterval = setTimeout(() => {
            const trimmedSearchParam = this.searchParam.trim().replace(/-/g, "");
			const limit = Utils.containsNumber(trimmedSearchParam) ? 3 : 2;
			if ((trimmedSearchParam.length > limit || trimmedSearchParam == "") && !this.fetching) {
				this.searchUnits();				
			}
        }, 1000);        
    }

    public searchUnits() {
        if (this.fetching) {
            return;
        }
        this.fetching = true;

        const requestBuilder = new UnitRequestBuilder(this.searchParam);
        const request = requestBuilder.prepareUnitRequest();

        this.unitService.searchUnits(request).then((units: Unit[]) => {
            this.units = units;
            this.fetching = false;
        });
    }

    public get selectedUnit() {
        return this.unitService.selectedUnit;
    }

}
import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { VisitationLogRequest } from "../models/request/VisitationLogRequest";
import { VisitationLog } from "../models/VisitationLog";
import { Unit } from "../models/Unit";
import { Constants } from "../utils/Constants";
import { Subject } from "rxjs";
import { BackupService } from "./backup.service";

@Injectable()
export class LogsService {

    public newLog: VisitationLog;
    public newLogChanged: Subject<VisitationLog> = new Subject<VisitationLog>();

    constructor(
        private dataService: DataService,
        private backupService: BackupService
    ) {
        this.newLog = null;
        this.newLogChanged.subscribe((log: VisitationLog) => {
            this.newLog = log;
        });
    }

    public checkLogQueue() {

        if (this.backupService.modifyLogQueue.length == 0) {
            return;
        }

        for (let log of this.backupService.modifyLogQueue) {
            this.modifyVisitLog(log);
        }

        this.backupService.modifyLogQueue = [];

    }

    public updateLog(log: VisitationLog) {
        this.newLogChanged.next(log);
    }

    public getLogsForUnit(unit: Unit) {

        let self = this;

        let promise = new Promise<any>((resolve, reject) => {

            let req = new VisitationLogRequest();
            req.start = 0;
            req.count = Constants.ITEMS_PER_REQUEST;
            req.andFields = { "unit.id": unit.id };
            req.andClauses = true;

            self.searchVisitLogs(req).then((logs: VisitationLog[]) => {
                resolve(logs);
            });

        });

        return promise;
    }

    public removeLog(log: VisitationLog) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self._removeLogRequest(log).then(res => {
                if (res.status == 0 && res.data !== false) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
        return promise;
    }

    public searchVisitLogs(req: VisitationLogRequest) {
        return new Promise<any>((resolve, reject) => {
            // self.dataService.getVisitLogs(req)
            this._getVisitLogs(req).then(res => {
                let logs: VisitationLog[] = [];
                if (res.status == 0 && res.data !== false) {
                    logs = res.data;
                }
                resolve(logs);
            });
        });
    }

    public addLogs(logs: VisitationLog[]) {
        return new Promise<VisitationLog[]>((resolve, reject) => {
            this._addLogs(logs).then((res: any) => {
                let responseLogs: VisitationLog[] = [];
                if (res.status == 0 && res.data !== false) {
                    responseLogs = res.data;
                }
                resolve(responseLogs);
            });
        });
    }

    public modifyVisitLog(log: VisitationLog) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            // self.dataService.modifyVisitationLog(log)
            this._modifyVisitationLog(log).then(res => {
                let tempLog: VisitationLog = null;
                if (res.status == 0 && res.data !== false) {
                    tempLog = res.data[0];
                }
                resolve(tempLog);
            });
        });
        return promise;
    }

    private _getVisitLogs(req: VisitationLogRequest) {
        if (navigator.onLine) {
            let params = { visitationLogRequest: JSON.stringify(req) };
            return this.dataService.issueCommand("GET", "unit-visitors", params);
        } else {
            return new Promise<any>((resolve, reject) => {
                this.backupService.getLogs().then((logs: VisitationLog[]) => {
                    let results = [];
                    let unitId = req.andFields["unit.id"];
                    for (let log of this.backupService.logs) {
                        if (log.unit.id == unitId) {
                            results.push(log);
                        }
                    }
                    resolve({
                        status: 0,
                        data: results
                    });
                });
            });
        }
    }

    private _addLogs(logs: VisitationLog[]) {
        return this.dataService.issueCommand("POST", "unit-visitors", logs, true);
    }

    private _modifyVisitationLog(log: VisitationLog) {
        if (navigator.onLine) {
            // let params = { visitationLog: JSON.stringify(log) };
            return this.dataService.issueCommand("POST", "unit-visitors", [log], true);
        } else {
            return new Promise<any>((resolve, reject) => {
                this.backupService.modifyLogQueue.push(log);
                let logs = [...this.backupService.logs];
                logs.push(log);
                this.backupService.setLogs(logs);

                resolve({
                    status: 0,
                    data: log
                });

            });
        }
    }

    private _removeLogRequest(log: VisitationLog) {
        let params = { log: JSON.stringify(log) };
        return this.dataService.issueCommand("DELETE", "unit-visitors", params);
    }

}
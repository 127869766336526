import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AuthService } from "../auth.service";

@Injectable()
export class SystmeAdminGuard implements CanActivate {

    private approvedTypes: string[] = ["SYSTEM_ADMIN"];

    constructor(private as: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let self = this;        

        let promise = new Promise<any>((resolve, reject) => {
            self.as.checkLoginStatus().then(res => {                
                if (res == false) {                    
                    self.router.navigate(["/login"]);
                } else {                    
                    if (self.approvedTypes.indexOf(self.as.loggedInUser.type) < 0) {
                        self.router.navigate(["./noaccess"]);
                        res = false;
                    }
                }
                resolve(res);
            });
        });
        return promise;
    }
}
import { DataRequest } from "../models/request/DataRequest";
import { UnitRequest } from "../models/request/UnitRequest";
import { Constants } from "./Constants";

export class UnitRequestBuilder {
    private searchParam: string;

    /**
     * @param {string} searchParam - The search parameter to be used in unit request preparation.
     */
    constructor(searchParam: string) {
        this.searchParam = searchParam.trim().replace(/-/g, "");
    }

    /**
     * Prepares a unit request using the provided search parameter in the constructor.
     * @returns {UnitRequest} - The prepared unit request.
     */
    public prepareUnitRequest(): UnitRequest {
        const request = new UnitRequest();
        request.clauses = this.getClausesForUnitRequest();
        request.sorters = { streetAddress: 1 };
        request.includeMemos = true;
        return request;
    }

    /**
     * Determines how to handle the search parameter based on its format (phone number, address, or other).
     * @returns {DataRequest[]} - An array of data requests for the unit request clauses.
     */
    private getClausesForUnitRequest(): DataRequest[] {
        if (this.searchParam === "") {
            return [this.createDataRequestFromSearchFields([this.searchParam], Constants.SIMPLE_SEARCH_FIELDS)];
        }

        if (this.isPhoneNumber()) {
            return [this.createDataRequestFromSearchFields([this.searchParam.replace(/\s/g, "")], Constants.NUMBER_SEARCH_FIELDS)];
        }

        if (this.isAddress()) {
            return [this.createDataRequestFromSearchFields([this.searchParam], Constants.ADDRESS_SEARCH_FIELDS)];
        }

        return this.searchParam.split(" ").map(param =>
            this.createDataRequestFromSearchFields([param], Constants.NAME_SEARCH_FIELDS)
        );
    }

    /**
     * Creates a new data request for the provided search parameters and fields.
     * @param {string[]} searchParams - The search parameters to be used in the data request.
     * @param {string[]} fields - The fields to be used in the data request.
     * @returns {DataRequest} - The created data request.
     */
    private createDataRequestFromSearchFields(searchParams: string[], fields: string[]): DataRequest {
        const dataRequest = new DataRequest();
        dataRequest.orFields = fields.reduce((accumulator, field) => {
            accumulator[field] = searchParams;
            return accumulator;
        }, {});
        dataRequest.ors = Object.keys(dataRequest.orFields);        
        return dataRequest;
    }

    /**
     * Checks if the search parameter is in address format (contains both letters and numbers).
     * @returns {boolean} - True if the search parameter is an address, false otherwise.
     */
    private isAddress(): boolean {
        return /\d/.test(this.searchParam) && /[a-zA-Z]/.test(this.searchParam);
    }

    /**
     * Checks if the search parameter is in phone number format.
     * @returns {boolean} - True if the search parameter is a phone number, false otherwise.
     */
    private isPhoneNumber(): boolean {
        return /^\d+\s*\d+\s*\d+$/.test(this.searchParam);
    }
}

import { Component, ViewChild, TemplateRef, Input } from "@angular/core";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { ModalContent } from "../../../models/ModalContent";

@Component({
    selector: "base-modal",
    templateUrl: "./base-modal.component.html"
})
export class BaseModal {

    @ViewChild('baseModal') baseModal: TemplateRef<any>;
    @Input('content') content: ModalContent;

    public modalRef: BsModalRef = null;
    public message: string = "";
    public response: any = false;
    public onClose: Subject<any> = new Subject<any>();
    public config: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false
    }

    constructor(private modalService: BsModalService) {
        this.onClose.subscribe((result: any) => {
            this.response = result;
        });
    }

    public show(props: any) {        
        let promise = new Promise<any>((resolve, reject) => {
            this.modalRef = this.modalService.show(this.baseModal, this.config);
            this.modalService.onHide.subscribe(() => {
                resolve(this.response);
            });
        });
        return promise;
    }

    public reject() {
        this.onClose.next(false);
        this.modalRef.hide();
    }

    public resolve() {
        this.onClose.next(true);
        this.modalRef.hide();
    }

    public closeDialog(response: any) {
		this.onClose.next(response);
		this.modalRef.hide();
	}

}
import { Component, ViewChild, TemplateRef, Input } from "@angular/core";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { User } from "../../../models/User";
import { Subject } from "rxjs";
import { UserService } from "../../../services/user.service";
import { NotificationService } from "../../../services/notifications.service";

@Component({
    selector: "user-modal",
    templateUrl: "./user-modal.component.html"
})
export class UserModal {

    @ViewChild('userModal') userModal: TemplateRef<any>;
    @Input('userType') userType: string;

    public newUser: User = new User();
    public modalRef: BsModalRef = null;
    private response: any = null;
    private onClose: Subject<boolean> = new Subject<boolean>();
    private config: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false
    }
    public confirmPassword: string = "";
    public changePassword: boolean = false;
    public editMode: boolean = false;
    public showPassword: boolean = false;

    constructor(private ms: BsModalService, private us: UserService, private ns: NotificationService) {
        let self = this;
        this.onClose.subscribe((result: any) => {
            self.response = result;
        });
    }

    public toggleChangePassword() {
        this.changePassword = !this.changePassword;
    }

    public togglePassword() {
        this.showPassword = !this.showPassword;
    }

    public show(user?: User) {

        let self = this;

        if (user) {
            this.newUser = user;
            this.editMode = true;
            this.changePassword = false;
        } else {
            this.newUser = new User();
            this.editMode = false;
            this.changePassword = true;
        }

        this.confirmPassword = "";

        let promise = new Promise<any>((resolve, reject) => {
            self.modalRef = self.ms.show(self.userModal, self.config);
            self.ms.onHide.subscribe(() => {
                resolve(self.response);
            });
        });

        return promise;

    }

    public reject() {
        this.onClose.next(false);
        this.modalRef.hide();
    }

    public modifyUser() {

        let self = this;

        if (this.newUser.password && this.newUser.password !== this.confirmPassword) {
            this.ns.showSimpleNotification("Passwords do not match");
            return;
        }

        this.newUser.type = this.userType;
        this.us.modifyUser(this.newUser).then((user: User) => {
            let res = null;
            if (user !== null) {
                res = user;
            }
            self.onClose.next(res);
            self.modalRef.hide();
        });

    }

}

export class Utils {

    public static matchPasswords(password: string, hashed: string) {
        return new Promise<any>((resolve, reject) => {
            let bcryprt = window['require']('bcryptjs');
            bcryprt.compare(password, hashed, (err, results) => {
                if (err !== null) {
                    console.log(err);
                    resolve(false);
                } else {
                    resolve(results);
                }
            });
        });
    }

    public static searchArray(searchTerm: string, array: any) {
        let results = [];
        for (let i in array) {
            let value = array[i];
            if (typeof (value) !== "string") {
                let test = Utils.searchArray(searchTerm, value);
                if (test.length > 0) {
                    results.push(array[i]);
                }
            } else if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
                results.push(array[i]);
            }
        }
        return results;
    }

    public static cloneObject(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    public static enforceFormat(event: KeyboardEvent) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    };

    public static isNumericInput(event: KeyboardEvent) {
        let key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };

    public static isModifierKey(event: KeyboardEvent) {
        let key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    public static normalizeDate(dateString: string, withTime?: boolean) {

        let date = dateString;

        let split = dateString.split(" ");
        let dateSplit = split[0].split("-");

        if (dateSplit.length > 1) {
            let month = dateSplit[1];
            if (parseInt(month) < 10) {
                month = "0" + parseInt(month);
            }

            let day = dateSplit[2];
            if (parseInt(day) < 10) {
                day = "0" + parseInt(day);
            }

            date = month + "/" + day + "/" + dateSplit[0];

            if (withTime && withTime == true) {
                date += + " " + split[1];
            }
        }

        return date;
    }

    public static formatPhones(phone: string) {

        if (!phone) {
            return "";
        }

        let input = phone.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        let areaCode = input.substring(0, 3);
        let middle = input.substring(3, 6);
        let last = input.substring(6, 10);

        return `(${areaCode}) ${middle} - ${last}`;

    }

    public static formatDate(date: Date, withTime?: boolean) {

        let month = (date.getMonth() + 1).toString();
        if (parseInt(month) < 10) {
            month = "0" + month;
        }

        let day = (date.getDate()).toString();
        if (parseInt(day) < 10) {
            day = "0" + day;
        }

        let year = date.getFullYear();

        let dateStr = month + "/" + day + "/" + year;

        if (withTime && withTime == true) {
            // let offset = date.getTimezoneOffset() / 60;
            // date.setHours(date.getHours() - offset);
            dateStr += " " + date.toLocaleTimeString();
        }

        return dateStr;
    }

    /**
     * Adds a cookie to the browser
     * @param name 
     * @param value 
     * @param expireDays 
     * @param path 
     */
    public static setCookie(name: string, value: string, expireDays: number, path: string = "") {
        let d: Date = new Date();
        d.setDate(d.getDate() + expireDays);
        let expires: string = `expires=${d.toUTCString()}`;
        let cpath: string = path ? `; path=${path}` : "";
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    /**
     * Delets a cookie by name
     * @param name 
     */
    public static deleteCookie(name: string) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;;";
    }

    /**
     * Get cookie by name
     * @param name 
     */
    public static getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(";");
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, "");
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }

        return null;
    }

    /**
     * Removes all special characters from a string
     * @param str 
     */
    public static escapeStr(str: string) {
        return str.replace(/[^a-zA-Z0-9]/g, "");
    }

    /**
     * Checks if the given search parameter string contains any numerical digit.
     *
     * @static
     * @param {string} searchParam - The search parameter string to check for numerical digits.
     * @returns {boolean} Returns `true` if the search parameter string contains at least one numerical digit, and `false` otherwise.
     */
    public static containsNumber(searchParam: string): boolean {
        return /\d/.test(searchParam);
    }

}

import { Injectable } from "@angular/core";
import { ConfirmationModal } from "../shared/modals/confirmation-modal/confirmation-modal.component";

@Injectable()
export class ModalService {

    public confirmationModal: ConfirmationModal;

    constructor() { }

    public setConfirmationModal(modal: ConfirmationModal) {
        this.confirmationModal = modal;
    }

    public showConfirmationModal(title: string, message: string) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self.confirmationModal.show(title, message).then((res: boolean) => {
                resolve(res);
            });
        })
        return promise;
    }

}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EmailTemplate } from '../models/EmailTemplate';
import { EmailTemplateRequest } from '../models/request/EmailTemplateRequest';
import { DataService } from './data.service';
import { NotificationService } from './notifications.service';

@Injectable({
	providedIn: 'root'
})
export class EmailTemplatesService {

	private _templates: EmailTemplate[];
	private templatesChanged: Subject<EmailTemplate[]>;

	constructor(
		private dataService: DataService,
		private notificationService: NotificationService
	) {
		this.templates = [];
		this.templatesChanged = new Subject<EmailTemplate[]>();
		this.templatesChanged.subscribe((templates: EmailTemplate[]) => {
			this.templates = templates;
		})
	}

	public modifyTemplate(template: EmailTemplate) {
		return new Promise<EmailTemplate>((resolve, reject) => {
			let modifiedTemplate = null;
			this.dataService.modifyTemplate(template).then(response => {
				if (response.status == 0) {
					modifiedTemplate = response.data;
				} else {
					this.notificationService.showSimpleNotification(`Error modifiying template: ${response.data}`, "danger");
				}
				resolve(modifiedTemplate);
			});
		});
	}

	public getEmailTemplates(request: EmailTemplateRequest) {
		return new Promise<EmailTemplate[]>((resolve, reject) => {
			let templates = [];
			this.dataService.getTemplates(request).then(response => {
				if (response.status == 0) {
					templates = response.data;
					this.templatesChanged.next(templates);
				}
				resolve(templates);
			});
		});
	}

	public get templates(): EmailTemplate[] {
		return this._templates;
	}
	public set templates(value: EmailTemplate[]) {
		this._templates = value;
	}

}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NotificationService } from "./notifications.service";

@Injectable()
export class DialingService {

    public incomingPhoneNumber: string;
    public incomingPhoneNumberChanged: Subject<string> = new Subject<string>();

    private portPath: string;
    private port: any;

    constructor(
        private notificationService: NotificationService
    ) {
        this.portPath = null;
        this.port = null;

        this.incomingPhoneNumber = null;
        this.incomingPhoneNumberChanged.subscribe((phoneNumber: string) => {
            this.incomingPhoneNumber = phoneNumber;
        });
    }

    public setIncomingPhoneNumber(phoneNumber: string) {
        this.incomingPhoneNumberChanged.next(phoneNumber);
    }

    private startPort() {

        let self = this;

        return new Promise<any>((resolve, reject) => {

            if (this.port !== null) {
                resolve(true);
                return;
            }

            this.getPort().then(path => {

                if (!path) {
                    this.notificationService.showSimpleNotification("No connection", "danger");
                    resolve(true);
                    return;
                }

                let SerialPort = window['require']('serialport');
                this.port = new SerialPort(path, {
                    baudRate: 9600
                });

                this.port.on("open", (data: any) => {
                    console.log(`Port opened: ${data}`);
                    resolve(true);
                }).on("data", (data: any) => {
                    console.log("Port data", data.toString());
                }).on('close', (data: any) => {
                    console.log("Port Closed: ", data);
                    self.port = null;
                }).on('error', (err: any) => {
                    console.log(`error: ${err.message}`);
                    resolve(false);
                });
            });
        });
    }

    public listenToLine() {

        this.startPort().then(res => {
            if (res == false) {
                return;
            }

            let ReadLine = window['require']("@serialport/parser-readline");

            let parser = this.port.pipe(new ReadLine({ delimiter: "\n" }));

            parser.on("data", (data: any) => {
                console.log("data", data);
                if (data.includes("NMBR")) {
                    let line = data.split(" ");
                    console.log("line", line);
                    // let phoneNumber = line[line.length - 1];
                    let phoneNumber = line.replace(/[^0-9.]/g, "");
                    console.log("phoneNumber", phoneNumber);
                    this.setIncomingPhoneNumber(phoneNumber);
                }
            });

            // this.port.write("AT+PCW=0\r", (err: any) => {
            //     if(err){
            //         console.log(`Error disabling call waiting: ${err.message}`);
            //     }else{
            //         console.log(`Call waiting has been disabled`);
            //     }
            // });

            this.port.write("AT+VCID=1\r", (err: any) => {
                if (err) {
                    console.log(`Error enabling caller id: ${err.message}`);
                }else{
                    console.log("Enabling caller id");
                }                
            });

        });
    }

    public dialNumber(number: string) {

        let self = this;

        return new Promise((resolve, reject) => {

            this.startPort().then(res => {
                if (res == false) {
                    resolve(false);
                    return;
                }

                this.port.write(`ATD${number}\r`);

                setTimeout(() => {
                    self.port.close();
                    resolve(true);
                }, 8000);

            });
        });
    }

    private getPort() {

        let self = this;

        let promise = new Promise<any>((resolve, reject) => {

            if (self.portPath !== null) {
                resolve(self.portPath);
            } else {
                let SerialPort = window['require']('serialport');
                SerialPort.list().then((ports: any) => {

                    let path = "";

                    if (!ports) {
                        return;
                    }

                    console.log("ports", ports);

                    for (let port of ports) {
                        if (port.manufacturer.toLowerCase().includes("conexant")) {
                            path = port.path;
                            break;
                        }
                    }

                    resolve(path);
                }, err => {
                    console.log("Port Error:", err);
                    resolve(false);
                });
            }
        });

        return promise;

    }

}
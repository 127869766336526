import {
	Component,
	ViewChild,
	HostListener,
	AfterViewInit,
} from "@angular/core";
import { UnitRequest } from "../../models/request/UnitRequest";
import { Utils } from "../../utils/Utils";
import { Constants } from "../../utils/Constants";
import { UnitService } from "../../services/unit.service";
import { Unit } from "../../models/Unit";
import { LogsService } from "../../services/logs.service";
import { VisitationLog } from "../../models/VisitationLog";
import { MemoService } from "../../services/memo.service";
import { Memo } from "../../models/Memo";
import { LogModal } from "../../shared/modals/log-modal/log-modal.component";
import { MemoModal } from "../../shared/modals/memo/memo.component";
import { UnitUsersModal } from "../../shared/modals/unit-users-modal/unit-users-modal.component";
import { User } from "../../models/User";
import { DialingService } from "../../services/dialing.service";
import { NotificationService } from "../../services/notifications.service";
import { NavigationService } from "src/app/shared/navigation/navigation.service";
import { environment } from "src/environments/environment";
import { ModalService } from "../../services/modal.service";
import { SpinnerService } from "../../shared/spinner/spinner.service";
import { ScannerService } from "src/app/services/scanner.service";
import { DataRequest } from "../../models/request/DataRequest";
import { UnitRequestBuilder } from "../../utils/UnitRequestBuilder";

@Component({
	selector: "guards-admin",
	templateUrl: "./guards.component.html",
})
export class GuardsPortalComponent implements AfterViewInit {
	@ViewChild("addLog") visitorLogDialog: LogModal;
	@ViewChild("memoModal") memoModal: MemoModal;
	@ViewChild("createVisitorDialog") createUserDialog: UnitUsersModal;

	public units: Unit[] = [];
	public selectedUnit: Unit = null;
	public searchParam: string = "";
	public activeLogs: VisitationLog[] = [];
	public activeMemos: Memo[] = [];
	public userType: string = "Visitor";
	public dialingContact: string = null;
	private autoSearchInterval: any;
	private fetching: boolean;

	private fKeys: string[] = [
		"F1",
		"F2",
		"F3",
		"F4",
		"F5",
		"F6",
		"F7",
		"F8",
		"F9",
		"F10",
		"F11",
		"F12",
	];

	constructor(
		private unitService: UnitService,
		private logsService: LogsService,
		private memoService: MemoService,
		private dialingService: DialingService,
		private scannerService: ScannerService,
		private notificationService: NotificationService,
		private navigationService: NavigationService,
		private modalService: ModalService,
		private spinnerService: SpinnerService
	) {
		this.fetching = false;
		let self = this;
		this.logsService.newLogChanged.subscribe((log: VisitationLog) => {
			if (log !== null) {
				self.activeLogs.unshift(log);
			}
		});
	}

	public ngAfterViewInit() {
		if (environment.isNative) {
			this.startListeningToLine();
			this.startScanner();
		}
		this.searchUnits();
	}

	private startListeningToLine() {
		this.dialingService.listenToLine();
		this.dialingService.incomingPhoneNumberChanged.subscribe(
			(phoneNumber: string) => {
				if (phoneNumber) {
					this.notificationService.showSimpleNotification(
						`Incoming Phone Call from: ${this.formatPhones(
							phoneNumber
						)}`
					);
					this.searchParam = phoneNumber;
					this.searchUnits(true);
				}
			}
		);
	}

	private startScanner() {
		this.scannerService.startScanner();
		this.scannerService.lastScannedDocument$.subscribe((document) => {
			if (document && this.selectedUnit == null) {
				this.searchParam = `${document.firstName} ${document.lastName}`;
				const requestBuilder = new UnitRequestBuilder(this.searchParam);
				const request = requestBuilder.prepareUnitRequest();
				this.searchUnits(false, request);
			}
		});
	}

	@HostListener("document:keydown", ["$event"]) handleKeyboardEvent(
		event: KeyboardEvent
	) {
		if (this.fKeys.indexOf(event.code) > -1) {
			this.showDialContact(event.code);
			return;
		}

		if (event.code == "Escape") {
			this.clearSearch();
		}
	}

	public clearSearch() {
		this.searchParam = "";
		this.searchUnits();
	}

	public showDialContact(target: string) {
		if (this.selectedUnit == null) {
			return;
		}

		let index = target.slice(-1);
		let contact = this.selectedUnit.contacts[parseInt(index) - 1];

		this.dialingContact = contact;

		this.notificationService.showSimpleNotification(
			`Dialing ${this.formatPhones(contact)}. Pickup the line.`
		);
		this.dialContact(contact);
	}

	private dialContact(number: string) {
		let self = this;
		this.dialingService.dialNumber(number).then((res) => {
			self.dialingContact = null;
		});
	}

	public hideUnitDetails(event: MouseEvent, unit: Unit) {
		event.stopPropagation();
		unit.showDetails = false;
		this.selectedUnit = null;
	}

	public showCreateDialog(
		event: MouseEvent,
		userType: string,
		user: User,
		isTemporary?: boolean
	) {
		let self = this;

		event.stopPropagation();

		this.userType = userType;

		this.createUserDialog
			.show(this.selectedUnit, user, isTemporary)
			.then((unit: Unit) => {
				if (unit) {
					for (let i in unit) {
						self.selectedUnit[i] = unit[i];
					}
				}
			});
	}

	public removeUser(
		event: MouseEvent,
		user: User,
		type: string,
		isTemporary?: boolean
	) {
		event.stopPropagation();

		if (this.selectedUnit == null) {
			return;
		}

		let mes = `Are you sure you want to remove ${user.firstName} ${user.lastName}?`;
		this.modalService
			.showConfirmationModal(`Remove ${type}`, mes)
			.then((res) => {
				if (res == true) {
					let target = this.selectedUnit.temporaryVisitors;
					target.splice(target.indexOf(user), 1);

					this.unitService.modifyUnit(this.selectedUnit);
				}
			});
	}

	private getLogsForUnit(unit: Unit) {
		let self = this;
		this.logsService.getLogsForUnit(unit).then((logs) => {
			self.activeLogs = logs;
		});
	}

	private getMemosForUnit(unit: Unit) {
		let self = this;
		this.memoService.getMemosForUnit(unit).then((memos) => {
			self.activeMemos = memos;
		});
	}

	public showUnitDetails(unit: Unit) {
		if (this.selectedUnit === unit) {
			return;
		}

		this.activeLogs = [];
		if (unit.memos) {
			this.activeMemos = unit.memos;
		} else {
			this.activeMemos = [];
		}

		for (let unit of this.units) {
			unit.showDetails = false;
		}

		unit.showDetails = true;
		this.selectedUnit = unit;
		this.removeExpiredVisitors();
		this.getLogsForUnit(unit);
		this.getMemosForUnit(unit);
	}

	private removeExpiredVisitors() {
		let visitors = [];

		if (this.selectedUnit.hasOwnProperty("temporaryVisitors")) {
			for (let visitor of this.selectedUnit.temporaryVisitors) {
				if (!visitor.expirationDate) {
					visitors.push(visitor);
					continue;
				}

				let expirationDate = new Date(
					Utils.normalizeDate(visitor.expirationDate)
				);
				let tomorrow = new Date();
				tomorrow.setDate(tomorrow.getDate() - 1);

				if (expirationDate > tomorrow) {
					visitors.push(visitor);
				}
			}
		}

		this.selectedUnit.temporaryVisitors = visitors;
	}

	public autoSearch() {
		clearTimeout(this.autoSearchInterval);
		this.autoSearchInterval = setTimeout(() => {
			const trimmedSearchParam = this.searchParam.trim().replace(/-/g, "");
			const limit = Utils.containsNumber(trimmedSearchParam) ? 3 : 2;
			if ((trimmedSearchParam.length > limit || trimmedSearchParam == "") && !this.fetching) {
				this.searchUnits();				
			}
		}, 1000);
	}	

	public searchUnits(openFirst?: boolean, request?: UnitRequest) {
		if (this.fetching) {
			return;
		}

		this.fetching = true;

		this.spinnerService.showSpinner("unitSpinner");

		if (!request) {
			const requestBuilder = new UnitRequestBuilder(this.searchParam);
			request = requestBuilder.prepareUnitRequest();
		}

		this.unitService.searchUnits(request).then((units: Unit[]) => {
			this.units = units;
			if (openFirst == true && units.length == 1) {
				this.showUnitDetails(units[0]);
			}
			this.fetching = false;
			this.spinnerService.hideSpinner("unitSpinner");
		});
	}

	public showLogDialog(log?: VisitationLog) {
		let tempLog = null;
		if (log) {
			tempLog = log;
		}

		this.visitorLogDialog
			.showLogDialog(this.selectedUnit, tempLog)
			.then((res) => {
				if (res !== null) {
					this.activeLogs.unshift(res);
				}
			});
	}

	public removeLog(event: MouseEvent, log: VisitationLog) {
		event.stopPropagation();

		let self = this;

		if (this.selectedUnit == null) {
			return;
		}

		this.logsService.removeLog(log).then((res) => {
			if (res == true) {
				self.activeLogs.splice(self.activeLogs.indexOf(log), 1);
			}
		});
	}

	public showMemoDialog() {
		let self = this;
		this.memoModal.show(this.selectedUnit).then((res) => {
			if (res !== null) {
				self.activeMemos.push(res);
			}
		});
	}

	public removeMemo(memo: Memo) {
		let self = this;

		if (this.selectedUnit == null) {
			return;
		}

		this.memoService.removeMemo(memo).then((res) => {
			if (res == true) {
				self.activeMemos.splice(self.activeMemos.indexOf(memo), 1);
			}
		});
	}

	public formatPhones(phone: string) {
		return Utils.formatPhones(phone);
	}

	public formatDate(date: string) {
		return Utils.formatDate(new Date(date));
	}

	public formatTimestamp(date: string) {
		return Utils.formatDate(new Date(date), true);
	}

	public get connection() {
		return this.navigationService.connection;
	}
}

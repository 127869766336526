import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailTemplate } from '../../../models/EmailTemplate';
import { EmailTemplatesService } from '../../../services/email-templates.service';
import { BaseModal } from '../base/base-modal.component';

@Component({
	selector: 'app-email-template-modal',
	templateUrl: './email-template-modal.component.html'
})
export class EmailTemplateModal extends BaseModal {

	@ViewChild('templateModal') modal: TemplateRef<any>;

	private _template: EmailTemplate;
	private _editMode: boolean;

	constructor(
		private bsModalService: BsModalService,
		private emailTemplatesService: EmailTemplatesService
	) {
		super(bsModalService);
		this.template = null;
		this.editMode = false;
	}

	public modifyTemplate() {
		this.emailTemplatesService.modifyTemplate(this.template).then((template: EmailTemplate) => {
			if (template == null) {
				return;
			}
			this.closeDialog(template);
		});
	}

	public show(template?: EmailTemplate): Promise<any> {
		return new Promise<any>((resolve, reject) => {

			if (template) {
				this.template = template;
				this.editMode = true;
			} else {
				this.template = new EmailTemplate();
				this.editMode = false;
			}

			this.modalRef = this.bsModalService.show(this.modal, this.config);
			this.bsModalService.onHide.subscribe(() => {
				resolve(this.response);
			});
		});
	}

	public get template(): EmailTemplate {
		return this._template;
	}
	public set template(value: EmailTemplate) {
		this._template = value;
	}

	public get editMode(): boolean {
		return this._editMode;
	}
	public set editMode(value: boolean) {
		this._editMode = value;
	}

}

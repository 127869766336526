import { Component, ViewChild, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
    selector: "confirmation-modal",
    templateUrl: "./confirmation-modal.component.html"
})
export class ConfirmationModal {

    @ViewChild('confirmationModal') confirmationModal: TemplateRef<any>;
    public modalRef: BsModalRef = null;
    public title: string = "";
    public message: string = "";
    private response: boolean = false;
    private onClose: Subject<boolean> = new Subject<boolean>();
    private config: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false
    }

    constructor(private ms: BsModalService) {
        let self = this;
        this.onClose.subscribe((result: boolean) => {
            self.response = result;
        });
    }

    public show(title: string, message: string) {

        let self = this;

        this.title = title;
        this.message = message;

        let promise = new Promise<any>((resolve, reject) => {
            self.modalRef = self.ms.show(self.confirmationModal, self.config);
            self.ms.onHide.subscribe(() => {
                resolve(self.response);
            });
        });
        return promise;
    }

    public reject() {
        this.onClose.next(false);
        this.modalRef.hide();
    }

    public resolve() {
        this.onClose.next(true);
        this.modalRef.hide();
    }

}
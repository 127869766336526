export class Community {
    id: string;
    name: string;
    address: string;
    phone: string;
    status: boolean;
    url: string;
    lastLogin: string;
    logoUrl: string;
}

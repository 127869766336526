import { Component, TemplateRef } from "@angular/core";
import { CommunityService } from "../../../services/community.service";
import { User } from "../../../models/User";
import { Community } from "../../../models/Community";
import { UserService } from "../../../services/user.service";
import { UserRequest } from "../../../models/request/UserRequest";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";

@Component({
    selector: "residents-admin",
    templateUrl: "./residents.component.html"
})
export class ResidentsAdminComponent {

    public users: User[] = [];
    public newUser = null;
    public inEditMode: boolean = false;
    public modalRef: BsModalRef;
    public searchParam: string = "";

    constructor(private cs: CommunityService, private us: UserService, private ms: BsModalService) {
        this.subscribeToCommunity();
    }

    private subscribeToCommunity() {
        let self = this;
        this.cs.selectedCommunityChanged.subscribe((community: Community) => {
            if (community !== null) {
                self.searchUsers();
            }
        });
    }

    public modifyUser() {
        let self = this;
        this.newUser.type = "RESIDENT";
        this.us.modifyUser(this.newUser).then((user: User) => {
            if (user !== null) {
                self.modalRef.hide();
                if(self.inEditMode == false){
                    self.users.push(user);
                }                             
            }    
            self.inEditMode = false;        
        });
    }

    public searchUsers() {
        let self = this;

        let req = new UserRequest();
        req.orFields = {
            firstName: [this.searchParam],
            lastName: [this.searchParam],
            address1: [this.searchParam],
            phone1: [this.searchParam]
        };
        req.andFields = {
            type: ["RESIDENT"]
        }
        req.ors = ["firstName", "lastName", "address1", "phone1"];
        req.ands = ["type"]
        req.andClauses = true;

        this.us.searchUsers(req).then((users: User[]) => {
            self.users = users;
        });
    }

    public hideCreateDialog() {
        this.newUser = new User();
        this.modalRef.hide();
    }

    public showCreateDialog(template: TemplateRef<any>, user: User) {

        if(user == null){
            this.newUser = new User();
        }else{
            this.newUser = user;
            this.inEditMode = true;
        }
        
        this.modalRef = this.ms.show(template);
    }

    get community() {
        return this.cs.selectedCommunity;
    }
}

import { Component, TemplateRef } from "@angular/core";
import { UserService } from "../../../services/user.service";
import { User } from "../../../models/User";
import { UserRequest } from "../../../models/request/UserRequest";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { CommunityService } from "../../../services/community.service";
import { Community } from "../../../models/Community";

@Component({
    selector: "guards-admin",
    templateUrl: "./guards.component.html"
})
export class GuardsSystemAdminComponent {

    public guards: User[] = [];
    public searchParam: string = "";
    public newGuard = null;
    public modalRef: BsModalRef;
    public type: string = "";
    public communities: Community[] = [];

    constructor(private cs: CommunityService, private us: UserService, private ms: BsModalService) {
        this.subscribeToCommunity();
    }

    public autoSearch(event: KeyboardEvent) {
        if (this.searchParam.length > 2) {
            this.searchUsers();
        } else if (this.searchParam.length == 0) {
            this.searchUsers();
        }
    }

    private subscribeToCommunity() {
        let self = this;
        this.cs.selectedCommunityChanged.subscribe((community: Community) => {
            if (community !== null) {
                self.searchUsers();
            }
        });
    }

    public createGuard(){
        let self = this;
        this.newGuard.type = "GUARD";
        this.us.modifyUser(this.newGuard).then((user: User) => {
            if (user !== null) {
                self.modalRef.hide();
                self.guards.push(user);
            }
        });
    }

    searchUsers() {
        let self = this;
        let req = new UserRequest();

        req.orFields = {
            firstName: [this.searchParam],
            lastName: [this.searchParam],
            email: [this.searchParam]
        }
        req.andFields = {
            type: ["GUARD"]
        }
        req.ors = ["firstName", "lastName", "email"];
        req.ands = ["type"];
        req.andClauses = true;

        this.us.searchUsers(req).then((users: User[]) => {
            self.guards = users;            
        })
    }

    public hideCreateDialog() {
        this.newGuard = new User();
        this.modalRef.hide();
    }

    public showCreateDialog(template: TemplateRef<any>) {
        this.newGuard = new User();
        this.modalRef = this.ms.show(template);
    }

    get community(){
        return this.cs.selectedCommunity;
    }

}

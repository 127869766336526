import { Injectable } from "@angular/core";
import { ScannedDocument } from "../models/ScannedDocument";
import { SerialPortService } from "./serial-port.service";
import { Port } from "../models/Port";
import { Constants } from "../utils/Constants";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ScannerService extends SerialPortService {
	private _scannedDocuments: ScannedDocument[];
	public scannedDocuments$: BehaviorSubject<ScannedDocument[]>;
	private _lastScannedDocument: ScannedDocument;
	public lastScannedDocument$: BehaviorSubject<ScannedDocument>;

	constructor() {
		super();
		this._scannedDocuments = [];
		this.scannedDocuments$ = new BehaviorSubject<ScannedDocument[]>([]);
		this._lastScannedDocument = null;
		this.lastScannedDocument$ = new BehaviorSubject<ScannedDocument>(null);
	}

	public startScanner() {
		this.getScannerPort()
			.then((scannerPort) => {
				scannerPort.on("data", (data: string) => {
					const scannedData = data.toString().trim();
					console.log("Scanned Data:" + scannedData);
					if (scannedData.includes("ANSI")) {
						const document = new ScannedDocument(scannedData);
						this.updateLastScannedDocument(document);
					} else {
						// Notify the last scan was unsuccessful
					}
				});
				scannerPort.on("error", (err: Error) => {
					// Handle errors when reading data from the scanner
				});
				scannerPort.on("disconnect", () => {
					// Handle the case when the scanner is disconnected during scanning
				});
			})
			.catch((error: Error) => {
				// Handle errors when connecting to the scanner
			});
	}

	private getScannerPort() {
		return new Promise<any>((resolve, reject) => {
			super.listPorts().then(
				(ports: Port[]) => {
					const port = ports.find(
						(p) =>
							p.manufacturer == "EG" ||
							p.pnpId.includes("Barcode_Scanner")
					);

					if (!port) {
						reject(new Error("No scanners found."));
						return;
					}

					const SerialPort = window["require"]("serialport");
					const scannerPort = new SerialPort(port.path, {
						baudRate: Constants.DEFAULT_BAUD_RATE,
					});
					resolve(scannerPort);
				},
				(error) => {
					reject(error.message);
				}
			);
		});
	}

	public updateLastScannedDocument(document: ScannedDocument) {
		this._lastScannedDocument = document;
		this.lastScannedDocument$.next(document);
		this.addScannedDocument(document);
	}

	private addScannedDocument(document: ScannedDocument) {
		this._scannedDocuments.unshift(document);
		this.scannedDocuments$.next(this._scannedDocuments);
	}

	public get scannedDocuments(): ScannedDocument[] {
		return this._scannedDocuments;
	}

	public get lastScannedDocument(): ScannedDocument {
		return this._lastScannedDocument;
	}
}

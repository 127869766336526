import { Component, TemplateRef, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../../../services/user.service";
import { User } from "../../../models/User";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { UserRequest } from "../../../models/request/UserRequest";
import { NotificationService } from "../../../services/notifications.service";
import { Utils } from "../../../utils/Utils";
import { ConfirmationModal } from "../../../shared/modals/confirmation-modal/confirmation-modal.component";

@Component({
    selector: "va-admin-guards",
    templateUrl: "./guards.component.html"
})
export class GuardsAdminComponent implements OnInit {

    @ViewChild('modal') confModal: ConfirmationModal;

    @ViewChild('createGuardDialog') createUserDialog: TemplateRef<any>;
    public modalRef: BsModalRef = null;

    public guards: User[] = [];
    public searchParam: string = "";
    public newGuard: User = null;
    public editMode: boolean = false;
    public showPassword: boolean = false;
    public changePassword: boolean = false;
    public confirmPassword: string = "";

    public lastRequest: UserRequest = null;
    public sortDirection: number = 1;
    public sortOption: string = "firstName";
    public sortingOptions: any[] = [
        {
            label: "First Name",
            key: "firstName"
        },
        {
            label: "Last Name",
            key: "lastName"
        },
        {
            label: "Email",
            key: "email"
        },
        {
            label: "Last Login",
            key: "lastLogin"
        }
    ];

    constructor(private us: UserService, private ms: BsModalService, private ns: NotificationService) { }

    public ngOnInit() {
        this.searchUsers();
    }

    public isSelectedSortOption(option: string) {
        return this.sortOption === option;
    }

    public setSortOption(option: string) {

        if (this.sortOption == option) {
            this.toggleSortDirection();
            return;
        }

        this.sortOption = option;

        if (option == "lastLogin") {
            this.sortUsers()
        } else {
            this.searchUsers(this.lastRequest);
        }

    }

    public sortUsers() {
        let guards = this.us.compareLastLogin(this.guards, this.sortDirection);
        this.guards = guards;
    }

    public toggleSortDirection() {
        this.sortDirection = this.sortDirection * -1;
        if (this.sortOption == "lastLogin") {
            this.sortUsers();
        } else {
            this.searchUsers(this.lastRequest);
        }
    }

    public formatDate(date: string) {
        return Utils.formatDate(new Date(date), true);
    }

    public toggleChangePassword() {
        this.changePassword = !this.changePassword;
    }

    public togglePassword() {
        this.showPassword = !this.showPassword;
    }

    public removeGuard(event: MouseEvent, guard: User) {

        let self = this;

        event.stopPropagation();

        let mes = "Are you sure you want to remove " + guard.firstName + " " + guard.lastName + "?";

        this.confModal.show("Remove Guard", mes).then(res => {
            if (res == true) {

                let user = new User();
                user.id = guard.id;

                self.us.removeUser(user).then(res => {
                    if (res == true) {
                        self.guards.splice(self.guards.indexOf(guard), 1);
                    }
                });
            }
        });
    }

    public createGuard() {

        let self = this;

        if (this.newGuard.password && this.newGuard.password !== this.confirmPassword) {
            this.ns.showSimpleNotification("Passwords do not match");
            return;
        }

        this.newGuard.type = "GUARD";
        this.us.modifyUser(this.newGuard, null).then((user: User) => {
            if (user !== null) {
                self.modalRef.hide();
                if (!self.editMode) {
                    self.guards.push(user);
                }
            }
        });
    }

    public autoSearch(event: KeyboardEvent) {
        if (this.searchParam.length > 2) {
            this.searchUsers();
        } else if (this.searchParam.length == 0) {
            this.searchUsers();
        }
    }

    searchUsers(req?: UserRequest) {

        let self = this;

        if (!req) {
            req = new UserRequest();
            req.showLastLogin = true;

            req.orFields = {
                firstName: [this.searchParam],
                lastName: [this.searchParam],
                email: [this.searchParam]
            }
            req.andFields = {
                type: ["GUARD"]
            }
            req.ors = ["firstName", "lastName", "email"];
            req.ands = ["type"];
            req.andClauses = true;
        }

        if (this.sortOption !== "lastLogin") {
            req.sorters = {};
            req.sorters[this.sortOption] = this.sortDirection;
        }

        this.lastRequest = req;

        this.us.searchUsers(req).then((users: User[]) => {
            if (self.sortOption == "lastLogin") {
                users = self.us.compareLastLogin(users, self.sortDirection);
            }
            self.guards = users;
        })
    }

    public hideCreateDialog() {
        this.modalRef.hide();
    }

    public showCreateDialog(guard?: User) {

        if (guard) {
            this.newGuard = guard;
            this.editMode = true;
            this.changePassword = false;
        } else {
            this.newGuard = new User();
            this.editMode = false;
            this.changePassword = true;
        }

        this.confirmPassword = "";

        this.modalRef = this.ms.show(this.createUserDialog);
    }

}
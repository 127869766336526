import { Component, OnInit } from '@angular/core';
import { UnitRequest } from '../../../models/request/UnitRequest';
import { Unit } from '../../../models/Unit';
import { User } from '../../../models/User';
import { DataService } from '../../../services/data.service';
import { UnitService } from '../../../services/unit.service';
import { NavigationService } from '../../../shared/navigation/navigation.service';
import { Constants } from '../../../utils/Constants';
import { Utils } from '../../../utils/Utils';

@Component({
  selector: 'app-print-missing-contacts',
  templateUrl: './print-missing-contacts.component.html'
})
export class PrintMissingContactsComponent implements OnInit {

  public units: Unit[];

  constructor(
    private unitService: UnitService,
    private navigationService: NavigationService,
    private dataService: DataService
  ) {

  }

  ngOnInit() {
    this.navigationService.setShowNavBar(false);
    this.searchUnits();
  }

  public printDocument() {
    let newWindow = window.open("", "PRINT");
    newWindow.document.write(this.getPrintedDocument());
    newWindow.document.close();
    newWindow.focus();
    // newWindow.print();
    // newWindow.close();
  }

  private getPrintedDocument() {
    return `<html>
				<head>
					<title>VisitorAxis - ${this.community.name}</title>
					<style>table{width:100%;}table,td,th{border:1px solid;font-size: 12px;}h1.unit-address{margin:0;font-size: 15px;}</style>
					<style>h1.title {text-align: center;}div.table-list {display: flex;flex-direction: row;color: inherit;font-size: 12px;}div.table-list ul {margin: 1px;padding: 0;list-style: none;}div.table-list ul li {border-top: 1px solid;border-right: 1px solid;border-left: 1px solid;padding: 2px;max-width: 300px;}div.table-list ul li:nth-last-child(1) {border-bottom: 1px solid;}div.table-list ul li.header {font-weight: bold;}div.table-list ul.large {width: 50%;}div.table-list ul.large li {max-width: 100%;}</style>
				</head>
				<body>
					${document.getElementsByTagName('app-print-missing-contacts')[0].innerHTML}
				</body>
			</html>`;
  }

  private searchUnits() {

    let req = new UnitRequest();
    req.orFields = {};

    for (let field of Constants.SIMPLE_SEARCH_FIELDS) {
      req.orFields[field] = [""];
    }

    req.ors = Constants.SIMPLE_SEARCH_FIELDS;
    req.sorters = { "streetAddress": 1 };
    req.includeMemos = true;

    this.unitService.searchUnits(req).then((units: Unit[]) => {
      this.units = units;
    });
  }

  private getLargestLength(unit: any) {
    return [unit.contacts.length, unit.residents.length, unit.visitors.length, unit.memos.length].reduce((accumulator, currentValue, currentIndex, array) => {
      return accumulator < currentValue ? currentValue : accumulator;
    }, 0);
  }

  public getVisitorString(visitors: User[]) {
    let array = visitors.reduce((accumulator, visitor: User) => {
      accumulator.push(`${visitor.firstName} ${visitor.lastName}`);
      return accumulator;
    }, []);
    return array.join(", ");
  }

  public formatPhones(phone: string) {
    return Utils.formatPhones(phone);
  }

  public get community() {
    return this.dataService.community;
  }



}

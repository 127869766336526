import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DragDropModule } from '@angular/cdk/drag-drop';

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from "ngx-bootstrap/modal";
import { ResidentsRouting } from "./residents-routing.module";
import { ResidentsComponent } from "./resident.component";
import { ResidentVisitorsComponent } from "./visitors/visitors.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ResidentsRouting,
        DragDropModule,
        SharedModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    declarations: [ResidentsComponent, ResidentVisitorsComponent],
    providers: [],
    exports: []
})
export class ResidentsModule { }

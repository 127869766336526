import { Component, ViewChild, TemplateRef, Input } from "@angular/core";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { Unit } from "../../../models/Unit";
import { UnitService } from "../../../services/unit.service";
import { NotificationService } from "../../../services/notifications.service";

@Component({
    selector: "unit-modal",
    templateUrl: "./unit-modal.component.html"
})
export class UnitModal {

    @ViewChild('modal') modal: TemplateRef<any>;

    public newUnit: Unit = new Unit();
    public modalRef: BsModalRef = null;
    public editMode: boolean = false;
    private response: any = null;
    private onClose: Subject<Unit> = new Subject<Unit>();
    private config: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false
    }

    constructor(
        private ms: BsModalService,
        private us: UnitService,
        private ns: NotificationService
    ) {
        let self = this;
        this.onClose.subscribe((result: any) => {
            self.response = result;
        });
    }

    public show(unit?: Unit) {

        let self = this;

        if (unit) {
            this.newUnit = unit;
            this.editMode = true;
        } else {
            this.newUnit = new Unit();
            this.editMode = false;
        }

        let promise = new Promise<any>((resolve, reject) => {
            self.modalRef = self.ms.show(self.modal, self.config);
            self.ms.onHide.subscribe(() => {
                resolve(self.response);
            });
        });

        return promise;

    }

    public reject() {
        this.onClose.next(null);
        this.modalRef.hide();
    }

    public modifyUnit() {

        let self = this;

        if(this.newUnit.streetAddress){
            this.newUnit.address1 = this.newUnit.streetAddress;
        }

        let valid = this.validateUnit(this.newUnit);
        if (valid == false) {
            return;
        }

        this.us.modifyUnit(this.newUnit).then((unit: Unit) => {
            self.onClose.next(unit);
            self.modalRef.hide();
        });
        
    }

    private validateUnit(unit: Unit) {

        if (!unit.address1) {
            this.ns.showSimpleNotification("Address is required");
            return false;
        }

        return true;
    }

}
import { Component } from '@angular/core';
import { Community } from '../../../../models/Community';
import { CommunityService } from '../../../../services/community.service';
import { UploadService } from '../../../../services/upload.service';
import { SpinnerService } from '../../../../shared/spinner/spinner.service';

@Component({
	selector: 'app-upload-logo',
	templateUrl: './upload-logo.component.html'
})
export class UploadLogoComponent {

	constructor(
		private communittyService: CommunityService,
		private uploadService: UploadService,
		private spinnerService: SpinnerService
	) {

	}

	public uploadFile(event: any) {

		let files: FileList = event.target.files;		

		this.spinnerService.showSpinner("mainSpinner");

		let file = files.item(0);
		let filename = `${this.community.url}.png`;

		this.uploadService.uploadFile(file, filename).then(res => {
			if (res !== null) {
				this.updateLogoUrl(res).then(res => {
					this.spinnerService.hideSpinner("mainSpinner");
				});
			} else {
				this.spinnerService.hideSpinner("mainSpinner");
			}
		});
	}

	private updateLogoUrl(url: string) {
		return new Promise<any>((resolve, reject) => {

			let tempCommunity = new Community();
			tempCommunity.id = this.community.id;
			tempCommunity.logoUrl = url;

			this.communittyService.modifyCommunity(tempCommunity).then((community: Community) => {
				if (community !== null) {
					this.communittyService.selectedCommunity.logoUrl = community.logoUrl
				}
				resolve(true);
			});
		});
	}

	public get community() {
		return this.communittyService.selectedCommunity;
	}

}

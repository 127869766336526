import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserRequest } from '../../../../models/request/UserRequest';
import { User } from '../../../../models/User';
import { AuthService } from '../../../../services/auth.service';
import { ModalService } from '../../../../services/modal.service';
import { NotificationService } from '../../../../services/notifications.service';
import { UserService } from '../../../../services/user.service';
import { CommunityUserModalComponent } from '../../../../shared/modals/community-user-modal/community-user-modal.component';
import { UserModal } from '../../../../shared/modals/user-modal/user-modal.component';

@Component({
	selector: 'app-user-admin',
	templateUrl: './user-admin.component.html'
})
export class UserAdminComponent implements OnInit {

	@Input() isCard: boolean;

	@ViewChild('userModal') userModal: UserModal;
	@ViewChild('addUserToCommunityModal') addUserToCommunityModal: CommunityUserModalComponent;

	public users: User[];

	constructor(
		private userService: UserService,
		private modalService: ModalService,
		private notificationService: NotificationService,
		private authService: AuthService
	) {
		this.users = [];
		this.isCard = false;
	}

	ngOnInit() {
		this.loadUsers();
	}	

	private loadUsers() {

		this.users = [];

		let req = new UserRequest();
		req.andFields = { type: "ADMIN" };
		req.ands = ["type"];

		this.userService.searchUsers(req).then((users: User[]) => {
			this.users = users;			
		});
	}	

	public showCreateUser(event: MouseEvent, user?: User) {

		event.stopPropagation();

		this.userModal.show(user).then((response: User) => {
			if (response && !user) {
				this.users.unshift(response);
			}
		});
	}

	public removeUser(event: MouseEvent, user: User) {

		let self = this;

		event.stopPropagation();

		let mes = "Are you sure you want to remove " + user.firstName + " " + user.lastName + "?";
		this.modalService.showConfirmationModal("Remove User", mes).then(response => {
			if (response == true) {
				let tempUser = new User();
				tempUser.id = user.id;
				self.userService.removeUser(tempUser).then(res => {
					if (res == true) {
						self.users.splice(self.users.indexOf(user), 1);
					}
				});
			}
		});
	}

	public resetPassword(user: User) {

		if (!user.email) {
			this.notificationService.showSimpleNotification("No registered email");
			return;
		}

		let tempUser = new User();
		tempUser.email = user.email;

		this.authService.sendResetPasswordEmail(tempUser).then(res => {
			if (res == true) {
				this.notificationService.showSimpleNotification(`Password reset email has been sent to ${user.email}`);
			}
		});

	}

	public showAddAdminToCommunity(user: User) {
		this.addUserToCommunityModal.show(user).then(res => {
			console.log(res);
		});
	}

}

import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../shared/navigation/navigation.service";
import { ConfirmationModal } from "../../shared/modals/confirmation-modal/confirmation-modal.component";
import { ModalService } from "../../services/modal.service";
import { SpinnerComponent } from "../../shared/spinner/spinner.component";
import { SpinnerService } from "../../shared/spinner/spinner.service";

@Component({
    selector: "system-admin",
    templateUrl: "./system-admin.component.html"
})
export class SystemAdminComponent implements OnInit, AfterViewInit {
    
    @ViewChild('confirmationModal') confirmationModal: ConfirmationModal;

    constructor(
        private nav: NavigationService,
        private ms: ModalService,
        private sps: SpinnerService
    ) { }

    public ngOnInit() {
        // this.loadTabs();    
    }

    private loadTabs() {
        let tabs: any[] = [
            {
                index: 0,
                label: "Communities"
            },
            {
                index: 1,
                label: "Admins"
            },
            {
                index: 2,
                label: "Guards"
            }
        ];
        this.nav.setTabs(tabs);
    }

    public ngAfterViewInit() {
        this.ms.setConfirmationModal(this.confirmationModal);
    }

    get activeTab() {
        return this.nav.activeTab;
    }
}

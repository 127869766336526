import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Community } from '../../models/Community';
import { Memo } from '../../models/Memo';
import { UnitRequest } from '../../models/request/UnitRequest';
import { UserRequest } from '../../models/request/UserRequest';
import { VisitationLogRequest } from '../../models/request/VisitationLogRequest';
import { Unit } from '../../models/Unit';
import { User } from '../../models/User';
import { VisitationLog } from '../../models/VisitationLog';
import { BackupService } from '../backup.service';
import { CommunityService } from '../community.service';
import { DataService } from '../data.service';
import { LogsService } from '../logs.service';
import { MemoService } from '../memo.service';
import { UnitService } from '../unit.service';
import { UserService } from '../user.service';

@Injectable({
	providedIn: 'root'
})
export class GuardResolverService implements Resolve<any> {

	constructor(
		private communityService: CommunityService,
		private unitService: UnitService,
		private memoService: MemoService,
		private logService: LogsService,
		private userService: UserService,
		private dataService: DataService,
		private backupService: BackupService
	) {

	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
		let self = this;
		let promise = new Promise<any>((resolve, reject) => {

			let communityUrl = route.paramMap.get("communityUrl");
			if (communityUrl) {
				self.getCommunity(communityUrl).then((community: Community) => {
					if (environment.isNative && navigator.onLine) {
						self.backupService.checkWorkingDirectory().then(res => {

							self.getAllUsers();
							self.getAllUnits();
							self.getAllMemos();
							self.getAllLogs();

							resolve(community);

						});
					} else {
						resolve(community);
					}
				});
			}
		});
		return promise;
	}

	private getCommunity(communityUrl: string) {
		return new Promise<any>((resolve, reject) => {
			let community: Community = null;
			if (communityUrl) {
				this.communityService.getCommunityFromUrl(communityUrl).then((communityResponse: Community) => {
					if (communityResponse !== null) {

						community = communityResponse;
						this.dataService.setCommunity(community);

						if (environment.isNative) {
							this.backupService.storeFile("community.json", JSON.stringify(community));
						}
					}
					resolve(community);
				});
			} else {
				resolve(community);
			}
		});
	}

	private getAllUsers() {

		let req = new UserRequest();
		req.fetchAll = true;
		req.ands = ["type"];
		req.andFields = {
			type: ["GUARD"]
		}

		this.userService.searchUsers(req).then((users: User[]) => {
			if (users.length > 0) {
				this.backupService.setUsers(users);
				this.backupService.storeFile("users.json", JSON.stringify(users));
			}
		});
	}

	private getAllUnits() {

		let req = new UnitRequest();
		req.fetchAll = true;

		this.unitService.searchUnits(req).then((units: Unit[]) => {
			if (units.length > 0) {
				this.backupService.setUnits(units);
				this.backupService.storeFile("units.json", JSON.stringify(units));
			}
		});
	}

	private getAllMemos() {
		this.memoService.getAllMemos().then((memos: Memo[]) => {
			if (memos.length > 0) {
				this.backupService.setMemos(memos);
				this.backupService.storeFile("memos.json", JSON.stringify(memos));
			}
		});
	}

	private getAllLogs() {

		let req = new VisitationLogRequest();
		req.fetchAll = true;

		this.logService.searchVisitLogs(req).then((logs: VisitationLog[]) => {
			if (logs.length > 0) {
				this.backupService.setLogs(logs);
				this.backupService.storeFile("logs.json", JSON.stringify(logs));
			}
		});

	}
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Memo } from '../models/Memo';
import { Unit } from '../models/Unit';
import { User } from '../models/User';
import { VisitationLog } from '../models/VisitationLog';

@Injectable({
	providedIn: 'root'
})
export class BackupService {

	public workingDirectory: string;
	public workingDirectoryChanged: Subject<string> = new Subject<string>();

	public units: Unit[];
	public unitsChanged: Subject<Unit[]> = new Subject<Unit[]>();

	public logs: VisitationLog[];
	public logsChanged: Subject<VisitationLog[]> = new Subject<VisitationLog[]>();

	public memos: Memo[];
	public memosChanged: Subject<Memo[]> = new Subject<Memo[]>();

	public users: User[];
	public usersChanged: Subject<User[]> = new Subject<User[]>();

	public modifyLogQueue: VisitationLog[];
	public modifyMemoQueue: Memo[];

	constructor() {

		this.workingDirectory = null;
		this.workingDirectoryChanged.subscribe((workingDirectory: string) => {
			this.workingDirectory = workingDirectory;
		});

		this.units = [];
		this.unitsChanged.subscribe((units: Unit[]) => {
			this.units = units;
		});

		this.logs = [];
		this.logsChanged.subscribe((logs: VisitationLog[]) => {
			this.logs = logs;
		});

		this.memos = [];
		this.memosChanged.subscribe((memos: Memo[]) => {
			this.memos = memos;
		});

		this.users = [];
		this.usersChanged.subscribe((users: User[]) => {
			this.users = users;
		});

		this.modifyLogQueue = [];
		this.modifyMemoQueue = [];
	}

	public setWorkingDirectory(workingDirectory: string) {
		this.workingDirectoryChanged.next(workingDirectory);
	}

	public setUnits(units: Unit[]) {
		this.unitsChanged.next(units);
	}

	public setLogs(logs: VisitationLog[]) {
		this.logsChanged.next(logs);
	}

	public setMemos(memos: Memo[]) {
		this.memosChanged.next(memos);
	}

	public setUsers(users: User[]) {
		this.usersChanged.next(users);
	}

	public getUsers(){
		return new Promise<any>((resolve, reject) => {
			if(this.users.length > 0){
				resolve(this.users);
			}else{
				this.getFile("users.json").then((res: any) => {
					let users = [];
					if(res !== null){
						users = res;
						this.setUsers(users);
					}				
					resolve(users);
				});
			}
		});		
	}

	public getUnits(){
		return new Promise<any>((resolve, reject) => {
			if(this.units.length > 0){
				resolve(this.units);
			}else{
				this.getFile("units.json").then((res: any) => {
					let units = [];
					if(res !== null){
						units = res;
						this.setUnits(units);
					}				
					resolve(units);
				});
			}
		});	
	}

	public getMemos(){
		return new Promise<any>((resolve, reject) => {
			if(this.memos.length > 0){
				resolve(this.memos);
			}else{
				this.getFile("memos.json").then((res: any) => {
					let memos = [];
					if(res !== null){
						memos = res;
						this.setMemos(memos);
					}				
					resolve(memos);
				});
			}
		});	
	}

	public getLogs(){
		return new Promise<any>((resolve, reject) => {
			if(this.logs.length > 0){
				resolve(this.logs);
			}else{
				this.getFile("logs.json").then((res: any) => {
					let logs = [];
					if(res !== null){
						logs = res;
						this.setLogs(logs);
					}				
					resolve(logs);
				});
			}
		});	
	}

	public getFile(filename: string) {
		return new Promise<any>((resolve, reject) => {
			let path = `${this.workingDirectory}/${filename}`;
			this.readFile(path).then(res => {
				let content = null;
				if (res !== false) {
					try {
						content = JSON.parse(res);
					} catch (e) {
						console.log(e);
						content = res;
					}
				}
				resolve(content);
			});
		});
	}

	public storeFile(filename: string, content: any) {
		return new Promise<any>((resolve, reject) => {
			let path = `${this.workingDirectory}/${filename}`;
			this.writeFile(path, content).then(res => {
				resolve(res);
			});
		});
	}

	public checkWorkingDirectory() {
		return new Promise<any>((resolve, reject) => {

			let os = window['require']('os');
			let home = os.homedir();

			let path = `${home}/VisitorAxis/`;
			this.setWorkingDirectory(path);

			this.checkExists(path, true).then(res => {
				resolve(res);
			});
		});
	}

	private readFile(path: string) {
		let promise = new Promise<any>((resolve, reject) => {
			let fs = window['require']('fs');
			fs.readFile(path, "utf8", (err: any, data: any) => {
				if (err) {
					resolve(false);
				} else {
					resolve(data);
				}
			});
		});
		return promise;
	}

	private writeFile(path: string, content: any) {
		let promise = new Promise<any>((resolve, reject) => {
			let fs = window['require']('fs');
			try {
				let stream = fs.createWriteStream(path);
				stream.write(content);
				stream.close();

				setTimeout(() => {
					resolve(true);
				}, 200);

			} catch (e) {
				console.log(e);
				resolve(false);
			}
		});
		return promise;
	}

	private checkExists(path: string, shouldMake: boolean) {
		let self = this;
		let promise = new Promise<any>((resolve, reject) => {
			try {
				let fs = window['require']('fs');
				fs.stat(path, (err: any, data: any) => {
					if (err) {
						if (shouldMake) {
							self.makeDirectory(path).then(res => {
								resolve(res);
							});
						} else {
							resolve(false);
						}
					} else {
						resolve(true);
					}
				});
			} catch (e) { }
		});
		return promise;
	}

	private makeDirectory(directory: string) {
		return new Promise<any>((resolve, reject) => {
			try {
				let fs = window['require']('fs');
				fs.mkdirSync(directory);
				resolve(true);
			} catch (e) {
				resolve(false);
			}
		});
	}

}

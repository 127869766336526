import { Component, OnInit } from "@angular/core";
import { NavigationService } from "../../shared/navigation/navigation.service";
import { DataService } from "../../services/data.service";

@Component({
    selector: "va-admin",
    templateUrl: "./admin.component.html"
})
export class AdminComponent implements OnInit {

    public adminTabs = [{ index: 0, label: "Homes" }, { index: 1, label: "Guards" }];

    constructor(private ns: NavigationService) { }

    public ngOnInit() {
        this.ns.setTabs(this.adminTabs);
    }

    get activeTab() {
        return this.ns.activeTab;
    }

}
import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { UserRequest } from "../models/request/UserRequest";
import { User } from "../models/User";
import { NotificationService } from "./notifications.service";
import { Unit } from "../models/Unit";
import { Community } from "../models/Community";
import { UserLocation } from "../models/UserLocation";

@Injectable()
export class UserService {

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService
    ) {

    }

    public compareLastLogin(users: User[], sortDirection: number) {
        return users.sort((a: User, b: User) => {

            let firstDate = null;
            if (a.lastLogin) {
                firstDate = new Date(a.lastLogin);
            }

            let secondDate = null;
            if (b.lastLogin) {
                secondDate = new Date(b.lastLogin);
            }

            if (firstDate == null && secondDate == null) {
                return 0;
            }

            if (firstDate == null && secondDate !== null) {
                return 1;
            }

            if (firstDate !== null && secondDate == null) {
                return -1;
            }

            if (firstDate !== null && secondDate !== null) {
                if (firstDate < secondDate) {
                    if (sortDirection == -1) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                if (firstDate > secondDate) {
                    if (sortDirection == -1) {
                        return 1
                    } else {
                        return -1;
                    }
                }
                return 0;
            }
        });

    }

    public getUsersById(userIds: string[]) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {

            let req = new UserRequest();
            req.orFields = {
                "id": userIds
            };
            req.ors = ["id"];

            self.searchUsers(req).then((users: User[]) => {
                resolve(users);
            });
        });
        return promise;
    }

    public removeUser(user: User) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {
            self._removeUserRequest(user).then(res => {
                if (res.status == 0 && res.data !== false) {
                    resolve(true);
                } else {
                    let mes = "There was an error removing user. " + res.data;
                    self.notificationService.showSimpleNotification(mes, "danger");
                    resolve(false);
                }
            })
        });
        return promise;
    }

    public batchCreateUser(sendEmail: boolean, templateId?: string) {
        return new Promise<any>((resolve, reject) => {
            this._batchCreateUsers(sendEmail, templateId).then(res => {
                let response = null;
                if (res.status == 0 && res.data !== false) {
                    response = res.data;
                }
                resolve(response);
            });
        });
    }

    public modifyUser(user: User, sendEmail?: boolean, templateId?: string) {

        let self = this;

        //TODO:Validate user

        let promise = new Promise<any>((resolve, reject) => {
            self.dataService.modifyUser(user, sendEmail, templateId).then(res => {
                let user: User = null;
                if (res.status == 0 && res.data !== false) {
                    user = res.data;
                } else {
                    let mes = "There was an error modifying user. Contact administrator!";
                    mes = res.data;
                    self.notificationService.showNotification({
                        message: mes,
                        color: "danger"
                    });
                }
                resolve(user);
            });
        });
        return promise;
    }

    public searchUsers(req: UserRequest) {

        let self = this;

        let promise = new Promise<any>((resolve, reject) => {

            let users: User[] = [];

            if (req == null) {
                req = new UserRequest();
            }

            self.dataService.getUsers(req).then(res => {
                if (res.status == 0 && res.data !== false) {
                    users = res.data.results;
                } else {
                    //TODO: notify of error
                }
                resolve(users);
            });
        });
        return promise;

    }

    public copyUser(user: User, community: Community) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {

            let tempUser = new User();
            tempUser.id = user.id;

            self._copyUserRequest(tempUser, community.id).then(res => {
                let respUser: User = null;
                if (res.status == 0 && res.data !== false) {
                    respUser = res.data;
                } else {
                    self.notificationService.showSimpleNotification("Could not add " + user.firstName + " " + user.lastName + " to " + community.name, "danger");
                }
                resolve(respUser);
            });
        });
        return promise;
    }

    public getUserLocation(user: User) {
        let self = this;
        let promise = new Promise<any>((resolve, reject) => {

            let tempUser = new User();
            tempUser.id = user.id;

            self._getUserLocations(tempUser).then(res => {
                let location: UserLocation = null;
                if (res.status == 0 && res.data !== false) {
                    location = res.data;
                } else {
                    let mes = "Could not find user locations. " + res.data;
                    self.notificationService.showSimpleNotification(mes, "danger");
                }
                resolve(location);
            });
        });
        return promise;
    }

    private _batchCreateUsers(sendEmail: boolean, templateId?: string) {
        let params = { sendEmail: sendEmail };
        if (templateId) {
            params["templateId"] = templateId;
        }
        return this.dataService.issueCommand("POST", "community/batch-users", params);
    }

    private _removeUserRequest(user: User) {
        let params = { user: JSON.stringify(user) };
        return this.dataService.issueCommand("DELETE", "user", params);
    }

    private _getUserLocations(user: User) {
        let params = { user: JSON.stringify(user) };
        return this.dataService.issueCommand("GET", "user-community", params);
    }

    private _copyUserRequest(user: User, communityId: string) {
        let params = { user: JSON.stringify(user), communityId: communityId };
        return this.dataService.issueCommand("POST", "user-community", params);
    }

    private _removeUserFromLocation(user: User, communityId: string) {
        let params = { user: JSON.stringify(user), communityId: communityId };
        return this.dataService.issueCommand("DELETE", "user-community", params);
    }

}